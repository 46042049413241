<template>
    <div>
        <div v-if="this.recents?.length > 0" class="w-full mt-6">
        <!--titre-->
            <div class="w-full flex">
                <p class="text-white font-bold text-xl ml-2  md:text-base xl:text-lg text-md">{{ $t('continuer') }}</p>
            </div>
            <!--Cards-->
            <div class="px-2  h-[15rem] py-2 relative">
                <swiper
                    :slidesPerView="'auto'"
                    :spaceBetween= 30
                    :navigation="true"
                    :modules="modules"
                    class="mySwiper overflow-hidden px-4 swiper_recent"
                >
                    <swiper-slide v-for="recent in recents" :key="recent.id" class="swiper-slide-recent ">
                        <button @click="openLecteurVideo(recent)" class="w-[17rem]  h-[13.5rem] relative transform hover:scale-110 motion-reduce:transform-none hover:z-auto transition duration-1000  ">
                            <div class=" w-full h-[75%] rounded overflow-hidden relative">
                                <div class=" w-full h-full absolute flex items-center">
                                    <div class="relative w-full h-full">
                                        <div class="w-full h-full absolute flex items-center z-10">
                                            <img class="w-14 h-14 mx-auto" src="/icons/play.png" alt="" srcset="">
                                        </div>
                                        <div class=" w-full h-full bg-black opacity-30"></div>
                                    </div>
                                </div>
                                <div class=" w-full h-full">
                                    <img class=" w-full h-full object-cover object-center" :src="recent[0].image"  alt="" srcset="">
                                </div>
                            </div>
                            <div class=" w-full h-1">
                                <progress class="w-full h-1 mb-3 rounded-full" :value="recent[1].duration_play" :max="recent[1].duration_full"></progress>
                            </div>
                            <div class="  h-[20%] mt-2 ">
                                <p class=" font-bold text-white text-left h-1/2">{{ recent[0].titre }}</p>
                                <p v-if="recent[0].rubrique == 'Film'" class="text-xs text-left text-white mt-1 ">{{ recent[0].rubrique }}</p>
                                <div v-if="recent[0].rubrique != 'Film'" class="flex items-center space-x-1 mt-1">
                                    <p class="text-xs text-white text-left">{{ recent[0].rubrique }}</p>
                                    <div class="h-full flex items-center">
                                        <div class="w-[0.5rem] h-[0.5rem] mt-[0.1rem]  bg-white rounded-full"></div>
                                    </div>
                                    <p class="text-xs text-white">{{ recent[1].namesaison }}</p>
                                    <p class="text-xs text-white">{{ recent[1].nameepisode }}</p>
                                </div>
                            </div>
                        </button>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import {Pagination, Navigation} from "swiper";
export default {
    watch: {
        getDataAccueil: {
            handler(newValue) {
                if(this.getDataRecent.length > 0){
                    this.recents= this.getDataRecent
                }else{
                    this.recents= newValue[6]
                }  
            },
            deep: true
        },
        getDataRecent: {
            handler(newValue) {
                this.recents= newValue
            },
            deep: true
        }
    },
    data(){
        return{
            recents:{},
            detailLecteur:{},
            detail:[]
        }
    },
    methods:{
        openLecteurVideo(data){
            if(data[0].rubrique != 'Film'){
                this.detail.push(data[0], data[1])
                localStorage.removeItem('detail')
                localStorage.setItem('detail', JSON.stringify(this.detail))
                this.$router.push('/lecteur');
            }else{
                localStorage.removeItem('detail')
                localStorage.setItem('detail', JSON.stringify(data))
                this.$router.push('/lecteur');
            } 
        },
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Navigation, Pagination],
        };
    },
    mounted(){
        if(this.getDataAccueil.length > 0){
            if(this.getDataRecent.length > 0){
              this.recents = this.getDataRecent  
            }else{
                this.recents = this.getDataAccueil[6]
            }
        }
    },
    computed:{
        getDataAccueil(){
            return this.$store.state.dataAccueil
        },
        setDataLecteurVideo(){
            return this.$store.dispatch('requestLecteurVideo', this.detailLecteur)
        },
        getDataRecent(){
            return this.$store.state.dataRecents
        },
    }
}
</script>

<style>
    /* For Firefox */
    progress::-moz-progress-bar {
        background: #FF0000;
    }

    /* For Chrome or Safari */
    progress::-webkit-progress-value {
        background: #FF0000;
    }


    .swiper {
    width: 100%;
    height: auto;
    }

    .swiper_recent {
    padding-left: 1rem !important;
    padding-top: 2%;
    padding-bottom: 2%;
    }

    
    .swiper-slide-recent{
        width: 17rem !important; 
        height: 13.5 !important;
    }
    
    .swiper-button-next::after{
        font-size: 15px !important;
        text-align: center;
        bottom: 0px;
    }
    .swiper-button-prev::after{
        font-size: 15px !important;
        text-align: center;
        bottom: 0px;
    }




    
    

</style>