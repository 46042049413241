<template>
  <div  class="w-full h-full">
        <div  v-if="loadnotification == false" class="w-full h-full space-y-2 overflow-hidden overflow-y-scroll scrollbar-hide rounded-lg">
            <div v-for="notification in notifications" :key="notification.id"  class="w-full h-[8rem] bg-[#20232b] px-2 py-2 rounded-lg" :class="notification[0].read_at ? null : 'bg-gray-600'">
                <div class="w-full h-[31%] ">
                    <div class="w-[2.3rem] h-[2.3rem] bg-white rounded-full overflow-hidden flex items-center">
                        <img class="object-contain object-center" src="/logo/logoretouche-removebg-preview.png" alt="" srcset="">
                    </div>
                </div>
                <div class="w-full h-[60%] flex items-center">
                    <div class="w-full">
                        <p class=" text-white text-xs">
                            {{ JSON.parse(notification[0].data).titre }}
                        </p>
                    </div>
                </div>
                <div class="w-full h-[9%] relative">
                    <div class="w-1/2 h-full absolute right-0 flex items-center">
                        <div class="mx-auto h[90%]  absolute right-0   overflow-hidden">
                            <p class="text-white text-center text-xs w-full h-full">{{ notification[1] }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full flex">
                <infinite-loading :class="fin ? false: 'invisible'" class="mx-auto text-white text-xs"  @infinite="infiniteHandler">
                    <template v-slot:spinner v-if="fin==true">
                        <p v-if="langue == 'fr' || langue == 'all'">Plus aucune notification !</p>
                        <p v-if="langue == 'en'">No more notification !</p>
                    </template>
                </infinite-loading>
            </div>
        </div>  
        <div v-if="loadnotification == true" class="w-full h-full space-y-2 overflow-hidden overflow-y-scroll scrollbar-hide rounded-lg">
            <div v-for="i in 10" :key="i.id" class="w-full h-28 bg-[#20232b] px-2 py-1 rounded-lg">
                <div class="w-full h-[31%] ">
                    <div class="w-[2.3rem] h-[2.3rem]  bg-[#161617] rounded-full overflow-hidden animate-pulse">
                    </div>
                </div>
                <div class="w-full h-[60%] flex items-center">
                    <div class="w-full space-y-2">
                        <div class="w-full h-1 rounded-3xl bg-[#161617]  overflow-hidden animate-pulse">
                        </div>
                        <div class="w-full h-1 rounded-3xl bg-[#161617]  overflow-hidden animate-pulse">
                        </div>
                        <div class="w-full h-1 rounded-3xl bg-[#161617]  overflow-hidden animate-pulse">
                        </div>
                        <div class="w-full h-1 rounded-3xl bg-[#161617]  overflow-hidden animate-pulse">
                        </div>
                        <div class="w-full h-1 rounded-3xl bg-[#161617]  overflow-hidden animate-pulse">
                        </div>
                    </div>
                </div>
                <div class="w-full h-[9%] relative">
                    <div class="w-9 h-full absolute right-0 flex items-center">
                        <div class="w-12 h-[60%] rounded-3xl mx-auto bg-[#161617] absolute right-0  overflow-hidden animate-pulse">
                        </div>
                    </div>
                </div>
            </div>
        </div>  
  </div>
</template>

<script>
import InfiniteLoading from "v3-infinite-loading/";
import "v3-infinite-loading/lib/style.css";
export default {
    beforeRouteLeave() {
        this.axios.get(this.requestHttp + 'readnotification' + localStorage.getItem('langue'),{
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        }).then(() => {
        })
        .catch()
    },
    components: {
        InfiniteLoading,
    },
    data(){
        return {
            notifications:{},
            loadnotification: true,
            page:2,
            fin: false,
            langue: localStorage.getItem('langue')
        }
    },
    mounted(){
        this.axios.get(this.requestHttp + 'notification/'+localStorage.getItem('langue')+'?page=1', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
        }).then(({data}) => {
            this.notifications = data 
            this.loadnotification = false    
        })
        .catch()
    },
    methods: {
        infiniteHandler($state){
            this.axios.get(this.requestHttp + 'notification/'+localStorage.getItem('langue')+'?page=' + this.page, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
            }).then(({data}) => {
                if(data.length > 0){
                    this.page += 1
                    this.notifications.push(...data)
                    $state.loaded() 
                }else{
                    this.fin = true
                }  
            })
            .catch()
        }
    },
    computed:{
        requestHttp(){
            return this.$store.state.api
        },
    }
}
</script>

<style>
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
</style>