import { createStore } from "vuex" 
import axios from 'axios'

const store = createStore({
   state:{
      page: 0,
      dataRecents:{},
      dataLecteurVideo:{},
      dataAvatar:{},
      dataSearch:[],
      dataFavoris:{},
      dataAccueil:{},
      api:'https://dashboard.safari-movies.com/api/'
   },
   mutations: {
      // INCREMENT_PAGE(state) {
      //   state.page += 1;
      // },
      REQUEST_LECTEUR_VIDEO(state, data) {
         state.dataLecteurVideo = data
      },
      REQUEST_RECENT(state) {
         axios.get(state.api + 'allrecents',{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
            }).then(({data}) => {
               if(data){
                  state.dataRecents = data
               }else{
                  state.dataRecents = 'aucun recent'
               }
          })
          .catch()
      },
      REQUEST_ACCUEIL(state, data) {
         state.dataAccueil = data
      },
      REQUEST_REFRESH_ACCUEIL(state) {
         axios.get(state.api + 'accueil/' + localStorage.getItem('langue'), {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
         }).then(({data}) => { 
            state.dataAccueil = data   
         })
         .catch()
      },
      REQUEST_FAVORIS(state,data) {
         if(data){
            state.dataFavoris = data  
         }else{
            axios.get(state.api + 'favoris', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
          }).then(({data}) => {
               state.dataFavoris = data        
            })
         .catch() 
         }
      },
      
      REQUEST_SEARCH(state, data) {
         if(data){
             state.dataSearch = data
         }else{
            axios.get(state.api + 'search/' + localStorage.getItem('langue') +'?page=' + 1, {
               headers: {
               'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
               },
            }).then(({data}) => {
                  state.dataSearch = data.data        
                  })
            .catch()
         }
      },
      REQUEST_AVATAR(state, data) {
         if(data){
            state.dataAvatar = data  
         }else{
            axios.get(state.api + 'user', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
          }).then(({data}) => {
               state.dataAvatar = data        
            })
         .catch() 
         }
      },
   },
   actions: {
       requestAccueil(context, data) {
         context.commit("REQUEST_ACCUEIL", data);
       },
       requestRefreshAccueil(context) {
         context.commit("REQUEST_REFRESH_ACCUEIL");
       },
       requestLecteurVideo(context, data) {
         context.commit("REQUEST_LECTEUR_VIDEO", data);
       },
       requestRecent(context) {
         context.commit("REQUEST_RECENT");
       },
       requestSearch(context, data) {
         context.commit("REQUEST_SEARCH", data);
       },
       requestFavoris(context, data) {
         if(data){
            context.commit("REQUEST_FAVORIS", data);
         }else{
            context.commit("REQUEST_FAVORIS");
         }
       },
       requestAvatar(context, data) {
         if(data){
            context.commit("REQUEST_AVATAR", data);
         }else{
            context.commit("REQUEST_AVATAR");
         }
       },
      // incrementCount(context, payload) {
      //   setTimeout(() => {
      //     context.commit("INCREMENT_COUNT", payload);
      //   }, 1000);
      // },
   },
})


export default store  
