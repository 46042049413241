<template>
    <div>
        <div v-if="this.webseries?.length > 0" class="w-full mt-6">
        <!--titre-->
            <div class="w-full flex">
                <p class="text-white font-bold text-xl ml-2  md:text-base xl:text-lg text-md">{{ $t('webserie') }}</p>
            </div>
            <!--Cards-->
            <div class="px-2  h-[20rem] py-2 relative">
                <swiper
                    :slidesPerView="'auto'"
                    :spaceBetween= 30
                    :navigation="true"
                    :modules="modules"
                    class="mySwiper overflow-hidden px-4 swiper_film"
                >
                    <swiper-slide v-for="webserie in webseries" :key="webserie.id" class="swiper-slide-film ">
                        <button @click="openDetailPage(webserie)" class="w-44 h-72 transform hover:scale-110 motion-reduce:transform-none hover:z-auto transition duration-1000">
                            <div class=" w-full h-[80%] rounded overflow-hidden relative">
                                <div v-if="webserie[0].status_periodique == 'nouveau'" class="animatenew w-full h-6  bg-[#FF0000] absolute transform rotate-45 translate-y-5 translate-x-14">
                                    <div class="w-full xl:hidden h-full bg-gradient-to-r from-transparent absolute via-gray-200 opacity-60 animate-[shimmer_1.5s_infinite]"></div>
                                    <p  class="text-white text-xs font-bold mt-[0.1rem]">{{ $t('messagestatusfilm') }}</p>
                                </div>
                                <img class=" w-full h-full object-center object-cover" :src="webserie[0].image" :alt="webserie[0].titre" srcset="">
                            </div>
                            <div class=" space-y-1 h-[20%] py-2">
                                <p v-if="webserie[0].titre.length <= 17" class="text-left font-bold text-white h-1/2">{{ webserie[0].titre}}</p>
                                <p v-if="webserie[0].titre.length > 17" class="text-left font-bold text-white h-1/2">{{ webserie[0].titre.substring(0,17) + '...' }}</p>
                                <div class="flex items-center h-1/2 space-x-1">
                                <p v-if="webserie[1].length > 0" class="text-left text-xs text-white">{{ webserie[1][0]?.nom }}</p>
                                <div  v-if="webserie[1].length > 1" class="h-full flex items-center">
                                    <div class="w-[0.3rem] h-[0.3rem] mt-[0.10rem] bg-white rounded-full"></div>
                                </div>
                                <p v-if="webserie[1].length > 1" class="text-xs text-white">{{ webserie[1][1]?.nom }}</p>
                                <div v-if="webserie[1].length > 2" class="h-full flex items-center">
                                    <div class="w-[0.3rem] h-[0.3rem]  mt-[0.10rem] bg-white rounded-full"></div>
                                </div>
                                <p v-if="webserie[1].length > 2" class="text-xs text-white">{{ webserie[1][2]?.nom }}</p>
                                </div>
                            </div>
                        </button>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import {Pagination, Navigation} from "swiper";
export default {
    watch: {
        getDataAccueil: {
            handler(newValue) {
                this.webseries= newValue[3]
            },
            deep: true
        }
    },
    data(){
        return{
            webseries:{}
        }
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Navigation, Pagination],
        };
    },
    mounted(){
        if(this.getDataAccueil.length > 0){
            this.webseries = this.getDataAccueil[3]
        }
    },
    methods:{
        openDetailPage(data){
            window.emitter.emit("EventDetailPage", data)
            localStorage.removeItem('detail')
        },
    },
    computed:{
        getDataAccueil(){
            return this.$store.state.dataAccueil
        },
    }
}
</script>

<style>
    
</style>