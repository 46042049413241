<template>
  <div id="app">
    <div class="w-full bg-[#181A20] relative">
      <div v-if="deferredPrompt" class="w-5/6 md:w-3/6 xl:w-1/3 h-auto  absolute mx-auto left-0 right-0 z-50 shadow-2xl  flex">
        <div class="w-5/6 md:w-3/6  xl:w-1/3 bg-[#272931] h-[10rem] fixed rounded-xl space-y-3">
          <p class="text-white text-center mt-3 h-1/2">{{ $t('downloadapp')  }}</p>
          <div class="w-full relative">
            <div class="absolute right-2 h-1/2 space-x-1">
              <button @click="dismiss" class="px-3 py-2 rounded-md text-white bg-[#FF0000]">{{ $t('ignorerbutton')  }}</button>
              <button @click="install" class="px-3 py-2 rounded-md text-white bg-green-500">{{ $t('telechargerbutton')  }}</button>
            </div>
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      deferredPrompt: null
    };
  },
  mounted(){
    if(localStorage.getItem('langue') == null || localStorage.getItem('langue') == '' || localStorage.getItem('langue') == false){
      localStorage.setItem('langue', 'all')
    }

    if(localStorage.getItem('langue') != 'en'){
        this.$i18n.locale = 'fr'
    }else{
        this.$i18n.locale = 'en'
    }
  },
  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      if (Cookies.get("add-to-home-screen") === undefined) {
        this.deferredPrompt = e;
      }
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      Cookies.set("add-to-home-screen", null, { expires: 7 });
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    }
  }
}
</script>

<style>
* {
  scrollbar-width: thin;
  scrollbar-color: #FF0000 #181A20;
}
*::-webkit-scrollbar{
    width: 10px;
}
*::-webkit-scrollbar-track{
    background-color: #181A20;
    border-radius: 100vh;
}
*::-webkit-scrollbar-thumb{
    background: linear-gradient(transparent, #FF0000);
    border-radius: 100vh;
}
*::-webkit-scrollbar-thumb:hover{
    background: linear-gradient(transparent, #FF0000);
}
</style>
