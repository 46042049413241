<template>
    <div class="w-full h-screen flex items-center ">
        <button @click="closeDetailPage" class="fixed top-0 z-50">
            <img class="w-8 h-8 md:w-9 md:h-9 flex-none md:ml-2 ml-1 mt-1 md:mt-2  md:relative transform hover:scale-110 motion-reduce:transform-none hover:z-20 transition duration-1000 " src="/icons/delete_sign_red.png" alt="" srcset="">
        </button>    
        <div class="w-[95%] md:w-[85%] h-[95%] fixed right-0 left-0 m-auto z-40">
            <div class="w-full h-full relative">
                <div v-if="loadDetailPage" class=" w-full h-full flex absolute">
                    <div class="w-full h-full rounded-xl mt-3 mx-auto z-10 relative">
                         <div class="w-full h-full absolute flex items-center">
                              <div class="mx-auto z-10 relative">
                                 <div class="w-16 h-16 absolute flex items-center">
                                     <img class="w-6 h-6 mx-auto" src="/icons/iconsafaripreview.png" alt="" srcset="">
                                 </div>
                                 <div class="rounded-lg text-white">
                                     <svg aria-hidden="true" class="inline w-16 h-16 text-white animate-spin fill-[#FF0000]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                     <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                     <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                     </svg>
                                 </div>
                              </div>   
                         </div>
                         <div class="w-full h-full bg-[#181A20] rounded-xl  opacity-80"></div>
                    </div> 
                 </div>
                 <div class=" w-full h-full flex">
                     <div class=" w-full h-full mx-auto mt-3  bg-[#181A20] border-[#292929] border-2 rounded-xl overflow-hidden overflow-y-auto scrollbar-hide">
                         <div class=" w-full h-full  z-0">
                             <div class="h-full w-full xl:flex landscape:flex  relative">
                                 <div class=" xl:w-[45%] landscape:w-[45%] w-full h-full relative">
                                    <div class="w-full h-full bg-gradient-to-t from-[#181A20] landscape:bg-gradient-to-l xl:bg-gradient-to-l  xl:from-[#181A20]  to-transparent  bottom-0 absolute ">
                        
                                    </div>
                                    <div class=" w-full h-full  overflow-hidden">
                                        <img :src="contenu?.image" class="  object-center object-cover w-full h-full" alt="" srcset="">
                                    </div>
                                 </div>
                                 <div class=" xl:w-[60%] landscape:w-[60%] landscape:relative w-full xl:relative absolute top-0 h-full xl:bg-[#181A20] rounded-xl">
                                    <div class="w-full h-[2.5%] flex bg-gradient-to-b from-[#181A20] to-transparent">
                                        <div class=" flex items-center md:w-[40%] landscape:w-[75%] w-[75%]  mt-3 space-x-2 md:ml-4 ml-6 relative">
                                            <p class="text-[#FF0000] font-semibold">{{ contenu?.date_de_sortie }}</p>
                                            <div class="w-6 h-6 rounded-md bg-white flex items-center justify-center">
                                                <p class="text-[#FF0000] text-sm font-semibold">{{ contenu?.langue }}</p>
                                            </div>
                                            <p class="text-[#FF0000] text-sm mt-[0.13rem] font-semibold">{{ contenu?.qualite }}</p>
                                            <div v-if="contenu?.rubrique == 'Web Serie'" class="flex absolute right-0 items-center space-x-4">
                                                <button type="button">
                                                    <a :href="contenu?.youtube" target="_blank">
                                                        <img class="h-[2.1rem]" src="/icons/youtube_play_480px.png" alt="" srcset="">
                                                    </a>
                                                </button>
                                                <button type="button">
                                                    <a :href="contenu?.facebook" target="_blank">
                                                        <img class="h-7" src="/icons/facebookk.png" alt="" srcset="">
                                                    </a>
                                                </button>
                                                <button type="button">
                                                    <a :href="contenu?.linktik" target="_blank">
                                                        <img class="h-7" src="/icons/tiktok_480px.png" alt="" srcset="">
                                                    </a>
                                                </button>
                                                <button type="button">
                                                    <a :href="'https://wa.me/' + contenu?.whatsapp" target="_blank">
                                                        <img class="h-7" src="/icons/whatsapp_480px.png" alt="" srcset="">
                                                    </a>
                                                </button>
                                            </div>
                                        </div>
                                        <div v-if="favoris">
                                            <button @click="addFavoris" v-if="favoris == 'faux' && loadfavoris == false" class="absolute right-1 transform hover:scale-110 motion-reduce:transform-none hover:z-10 transition duration-1000">
                                                <img class="h-6" src="/icons/hearts_96px.png" alt="" srcset="">
                                            </button>
                                            <button @click="deleteFavoris" v-if="favoris == 'vrai' && loadfavoris == false" class="absolute right-1 transform hover:scale-110 motion-reduce:transform-none hover:z-10 transition duration-1000">
                                                <img class="h-6" src="/icons/addfavoris.png" alt="" srcset="">
                                            </button>
                                            <div v-if="loadfavoris == true && favoris == 'faux'" class="
                                                absolute
                                                -right-[0.2rem]
                                                top-[0.1rem]
                                                z-20
                                                rounded-lg
                                                flex
                                                items-center
                                                justify-center
                                                text-[#FF0000]
                                                mx-auto
                                                text-center">
                                                <svg aria-hidden="true" class="inline w-5 h-5 mr-2 text-[#FF0000] animate-spin dark:text-white fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                </svg>
                                            </div>
                                            <div v-if="loadfavoris == true && favoris == 'vrai'" class="
                                                absolute
                                                -right-[0.2rem]
                                                top-[0.1rem]
                                                rounded-lg
                                                flex
                                                items-center
                                                justify-center
                                                mx-auto
                                                z-20
                                                text-center">
                                                <svg aria-hidden="true" class="inline w-5 h-5 mr-2 text-[#ffffff] animate-spin dark:text-white fill-[#FF0000]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-full h-[90%]  relative  flex items-center rounded-xl">
                                        <div class="space-y-12 absolute bottom-10 xl:relative landscape:relative">
                                            <div class="w-full px-4 landscape:space-y-4 space-y-6">
                                                <div class="w-full flex">
                                                    <p class=" mx-auto text-white landscape:text-[10px] md:landscape:text-sm text-md">{{ $t('choixfilm') }}</p>
                                                </div>
                                                <div class="w-full flex">
                                                    <p class=" mx-auto text-white font-bold landscape:text-xl md:landscape:text-2xl text-2xl text-center">{{ contenu?.titre }}</p>
                                                </div>
                                                <div class="w-full flex">
                                                    <p class=" mx-auto text-white landscape:text-[10px] md:landscape:text-sm text-sm text-center">{{ contenu?.description }}</p>
                                                </div>
                                                <div class=" w-full flex">
                                                    <div v-if="this.genres?.length > 0" class="flex space-x-3 items-center mx-auto">
                                                        <p v-if="this.genres?.length > 0" class="text-sm font-semibold text-white" >{{ genres[0]?.nom }}</p>
                                                        <img v-if="this.genres?.length > 1" class="w-4 h-4" src="/icons/sort_right_filled_100px.png" alt="" srcset="">
                                                        <p v-if="this.genres?.length > 1" class="text-sm font-semibold text-white">{{ genres[1]?.nom }}</p>
                                                        <img v-if="this.genres?.length > 2"  class="w-4 h-4" src="/icons/sort_right_filled_100px.png" alt="" srcset="">
                                                        <p v-if="this.genres?.length > 2" class="text-sm font-semibold text-white">{{ genres[2]?.nom }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-full h-[5%] relative">
                                        <div class=" w-full px-4 space-y-14 absolute bottom-2">
                                        <div class="w-full flex">
                                            <div v-if="contenu?.rubrique == 'Film'" class="flex items-center">
                                                <button @click="openLecteurVideo('non')" class=" w-12 h-12 rounded-full bg-[#FF0000]  flex items-center">
                                                    <img class="mx-auto h-6" src="/icons/play_208px.png" >
                                                </button>
                                                <span class=" text-white ml-2 font-semibold">{{ $t('jouermaintenant') }}</span>
                                            </div>
                                            <div v-if="contenu?.rubrique != 'Film'" class="flex items-center">
                                                <button @click="openLecteurVideo('non')" class=" w-12 h-12 rounded-full bg-[#FF0000]  flex items-center">
                                                    <img class="mx-auto h-6" src="/icons/play_208px.png" >
                                                </button>
                                                <span class=" text-white ml-2 font-semibold">Jouer maintenant</span>
                                            </div>
                                            <div class=" w-12 h-12 rounded-full bg-white flex items-center absolute right-3 bottom-0">
                                                <p class="text-xs mx-auto">+{{ contenu?.age }}</p>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                 </div>
                             </div>
                         </div>
                         <div v-if="acteurs.length > 0 || saisons.length > 0 || episodes.length > 0 || similaires.length > 0" class="w-full px-3 py-2">
                             <div v-if="acteurs.length > 0" class="w-full relative">
                                 <p class=" text-white font-semibold absolute xl:top-[0.6rem]">{{ $t('acteurs') }}</p>
                                 <div class="w-full mt-1">
                                     <!--listes-->
                                     <div class=" w-full flex overflow-hidden space-x-3 pt-6 relative">
                                         <!--card 1-->
                                         <swiper
                                             :slidesPerView="'auto'"
                                             :spaceBetween= 30
                                             :navigation="true"
                                             :modules="modules"
                                             class="mySwiper px-4 swiper_acteurs"
                                         >
                                             <swiper-slide v-for="acteur in acteurs" :key="acteur.id" class="w-44 h-64 rounded-md shadow-2xl swiper-slide-film bg-[#20232b]">
                                                 <div class=" w-full h-[80%] rounded overflow-hidden">
                                                 <img class=" w-full h-full object-center object-cover" :src="acteur.photo" alt="" srcset="">
                                                 </div>
                                                 <div class=" space-y-1 h-[20%] py-2 px-1">
                                                     <!--ordinateur-->
                                                     <p v-if="acteur.nom.length > 19" class=" font-semibold hidden lg:block text-white h-1/2">{{ acteur.nom.substring(0,18) + '...' }}</p>
                                                     <p v-if="acteur.nom.length <= 19" class=" font-semibold hidden lg:block text-white h-1/2">{{ acteur.nom }}</p>
                                                     <!--telephone-->
                                                     <p v-if="acteur.nom.length > 17" class=" font-semibold text-white lg:hidden h-1/2">{{ acteur.nom.substring(0,16) + '...' }}</p>
                                                     <p v-if="acteur.nom.length <= 17" class=" font-semibold text-white lg:hidden h-1/2">{{ acteur.nom }}</p>
                                                     <div class="flex items-center h-1/2 space-x-1">
                                                         <!--ordinateur-->
                                                         <p v-if="acteur.personnage.length > 20" class="text-xs text-gray-400 hidden lg:block">{{ acteur.personnage.substring(0,19) + '...' }}</p>
                                                         <p v-if="acteur.personnage.length <= 20" class="text-xs text-gray-400 hidden lg:block">{{ acteur.personnage }}</p>
                                                         <!--telephone-->
                                                         <p v-if="acteur.personnage.length > 18" class="text-xs text-gray-400 lg:hidden">{{ acteur.personnage.substring(0,17) + '...' }}</p>
                                                         <p v-if="acteur.personnage.length <= 18" class="text-xs text-gray-400 lg:hidden">{{ acteur.personnage }}</p>
                                                     </div>
                                                 </div>
                                             </swiper-slide>
                                         </swiper>                                
                                     </div>
                                 </div>
                             </div>
                             <div v-if="saisons.length > 0 || similaires.length > 0" class=" w-full mt-4 space-y-3">
                                 <div @change="findEpisode()">
                                     <select v-if="saisons.length > 0" v-model="valueSaison" class="bg-[#181A20] font-semibold py-2.5 px-0 text-sm  border-0 border-b-2 border-gray-200  text-white dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 ">
                                         <option  class="w-full h-full" v-for="saison in saisons" :key="saison.id" :value="saison">
                                             {{ saison?.nom }}
                                         </option>
                                     </select>
                                 </div>
                                 
                                 <div class=" w-full">
                                     <div class="w-full relative">
                                         <div v-if="loadEpisode" class="w-full flex h-[20rem] absolute">
                                             <div class=" w-full h-full flex absolute">
                                                 <div class="w-full h-full rounded-xl mx-auto z-10 relative">
                                                         <div class="w-full h-full absolute flex items-center">
                                                             <div class="mx-auto z-10 relative">
                                                                 <div class="w-16 h-16 absolute flex items-center">
                                                                     <img class="w-6 h-6 mx-auto" src="/icons/iconsafaripreview.png" alt="" srcset="">
                                                                 </div>
                                                                 <div class="rounded-lg text-white">
                                                                     <svg aria-hidden="true" class="inline w-16 h-16 text-white animate-spin fill-[#FF0000]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                     <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                                     <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                                     </svg>
                                                                 </div>
                                                             </div>   
                                                         </div>
                                                         <div class="w-full h-full bg-[#181A20] opacity-80"></div>
                                                 </div> 
                                             </div>
                                         </div>
                                         <div v-if="episodes.length > 0" class="w-full overflow-hidden flex flex-wrap justify-center h-[20rem] overflow-y-auto scrollbar-hide py-3">
                                             <!--card 1-->
                                             <button type="button" v-for="episode in episodes" :key="episode.id" @click="openLecteurVideo(episode)" class="w-[17rem]  h-[13.5rem] relative ml-3 rounded-md shadow-2xl bg-[#20232b] mt-4">
                                                 <div class=" w-full h-[75%] rounded overflow-hidden relative">
                                                     <div class=" w-full h-full absolute flex items-center">
                                                         <div class="relative w-full h-full">
                                                         <div class="w-full h-full absolute flex items-center z-10">
                                                             <img class="w-14 h-14 mx-auto" src="/icons/play.png" alt="" srcset="">
                                                         </div>
                                                         <div class=" w-full h-full bg-black opacity-30"></div>
                                                         </div>
                                                     </div>
                                                     <div class=" w-full h-full ">
                                                         <img class=" w-full h-full object-cover object-center " :src="contenu.image" alt="" srcset="">
                                                     </div>
                                                 </div>
                                                 <div class="  h-[20%] mt-2 px-1 ">
                                                    <div class="hidden xl:block">
                                                        <p v-if="episode.titre.length <= 17" class=" text-white h-1/2 text-left">{{ episode.nom }}: {{ episode.titre }}</p>
                                                        <p v-if="episode.titre.length > 17" class=" text-white h-1/2 text-left">{{ episode.nom }}: {{ episode.titre.substring(0,17) + '...' }}</p>
                                                        <div class="w-full flex justify-between">
                                                            <p class="text-xs text-white mt-1">Duree: <span class="text-[#FF0000]">{{ episode.duree }}</span></p>
                                                            <p class=" text-white text-xs font-bold">{{ episode.qualite }}</p>
                                                        </div>
                                                    </div>
                                                    <div class=" xl:hidden">
                                                        <p v-if="episode.titre.length <= 14" class=" text-white h-1/2 text-left">{{ episode.nom }}: {{ episode.titre }}</p>
                                                        <p v-if="episode.titre.length > 14" class=" text-white h-1/2 text-left">{{ episode.nom }}: {{ episode.titre.substring(0,14) + '...' }}</p>
                                                        <div class="w-full flex justify-between">
                                                            <p class="text-xs text-white mt-1">Duree: <span class="text-[#FF0000]">{{ episode.duree }}</span></p>
                                                            <p class=" text-white text-xs font-bold">{{ episode.qualite }}</p>
                                                        </div>
                                                    </div>
                                                 </div>
                                             </button>
                                         </div>
                                     </div>
                                     
                                     <div v-if="similaires.length > 0" class="w-full relative">
                                         <div class="w-full">
                                             <p class=" text-white font-semibold mt-2 absolute top-2">{{ $t('similaires') }}</p>
                                         </div>
                                         <div class=" w-full h-auto">
                                             <swiper 
                                                 :effect="'coverflow'"
                                                 :grabCursor="true"
                                                 :centeredSlides="true"
                                                 :slidesPerView="'auto'"
                                                 :navigation="true"
                                                 :initialSlide="Math.trunc((this.similaires.length)/2) "
                                                 :coverflowEffect="{
                                                 rotate: 50,
                                                 stretch: 0,
                                                 depth: 100,
                                                 modifier: 1,
                                                 slideShadows: true,
                                                 }"
                                                 :modules="modules"
                                                 class="mySwiper swiper_similaire h-56"
                                             >
                                                 <swiper-slide v-for="similaire in similaires" :key="similaire.id" class="swiper-slide_similaire">
                                                     <a class="porto-button" @click="$refs.something.scrollIntoView()">
                                                         <img @click="openDetailPage(similaire)" :src="similaire?.image" />
                                                     </a>
                                                 </swiper-slide>
                                             </swiper>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div> 
                 </div> 
            </div>    
        </div>  
        <div @click="closeDetailPage" class="w-full h-screen backdrop-blur-sm  z-30 flex fixed"></div>    
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

//import required modules
import { EffectCoverflow, Pagination,Navigation } from "swiper";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [EffectCoverflow, Pagination, Navigation],
        };
    },
    props: ['filmDetail'],
    data(){
        return{
            activeGenre:false,
            valueSaison:'',
            id:'',
            oldmovie:[],
            detail:[],
            milieu:'',
            varSaison: {},
            film: this.filmDetail,
            contenu:{},
            acteurs:{},
            genres:[],
            saisons:{},
            saisonEpisodes:{},
            similaires:[],
            videos:{},
            episodes:{},
            favoris:'',
            loadfavoris:false,
            loadDetailPage: true,
            loadEpisode: false
        }
    },
    mounted(){
        this.oldmovie = JSON.parse(localStorage.getItem('detail'))
        localStorage.removeItem('detail')
        if(this.oldmovie && this.oldmovie.length > 0){
            this.film = this.oldmovie[0]
        }
        this.contenu = this.film
        this.id = this.film.id
        this.detail.push(this.film)
        if(this.film.length > 1){
            this.contenu = this.film[0]
            this.detail[0] = this.film[0]
            this.detail.push({})
            this.genres = this.film[1]
            this.id = this.film[0].id
        }else{
          this.detail.push({})  
        }
        this.contenu.langue = this.contenu.langue.toUpperCase()
        this.axios.get(this.requestHttp + 'detailpageweb/' + this.id + '/' + localStorage.getItem('langue') , {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
          }).then(({data}) => {
            if(this.activeGenre == false){
              this.genres = data[2]  
            }
            this.detail.push(data[5])
            this.detail.push(data[3])
            this.acteurs = data[1]           
            this.saisons = data[3]
            this.valueSaison =  data[3][0]
            this.similaires = data[4]
            this.videos = data[5]
            this.favoris = data[6]
            if(data[3].length > 0){
                this.detail.push(data[3][0])
                this.axios.get(this.requestHttp + 'allepisodes/' + data[3][0].id, {
                headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                },
                }).then(({data}) => {
                    this.detail.push(data[0])
                    this.episodes = data[0]
                    this.detail[2] = data[0][0]
                    this.saisonEpisodes = data[1][0]
                })
                .catch() 
            }
            this.loadDetailPage = false
            localStorage.removeItem('detail')
         })
      .catch() 
    },
    methods:{
        closeDetailPage(){
            window.emitter.emit("EventDetailPage", false)
        },
        findEpisode(){
            this.loadEpisode = true
            this.detail[4]= this.valueSaison
            this.axios.get(this.requestHttp + 'allepisodes/' + this.valueSaison.id, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
            }).then(({data}) => {
                this.episodes = data[0]
                this.saisonEpisodes = data[1][0]
                this.loadEpisode = false
                this.detail[5] = data[0]
            })
            .catch() 
        },
        openDetailPage(value){
            this.loadDetailPage = true
            this.acteurs = []
            this.saisons = []
            this.similaires = []
            this.episodes = []
            this.contenu = value
            this.id = value.id
            this.detail.push(value)
            if(value.length > 1){
                this.contenu = value[0]
                this.detail[0] = value[0]
                this.detail.push({})
                this.genres = value[1]
                this.id = value[0].id
            }
            this.contenu.langue = this.contenu.langue.toUpperCase()
            this.axios.get(this.requestHttp + 'detailpageweb/' + this.id + '/' + localStorage.getItem('langue') , {
                headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                },
            }).then(({data}) => {
                if(this.activeGenre == false){
                this.genres = data[2]  
                }
                this.detail.push(data[5])
                this.detail.push(data[3])
                this.acteurs = data[1]           
                this.saisons = data[3] 
                this.valueSaison =  data[3][0]
                this.similaires = data[4] 
                this.videos = data[5]
                this.favoris = data[6]
                if(data[3].length > 0){
                    this.detail.push(data[3][0])
                    this.axios.get(this.requestHttp + 'allepisodes/' + data[3][0].id, {
                    headers: {
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                    },
                    }).then(({data}) => {
                        this.detail.push(data[0])
                        this.episodes = data[0]
                        this.saisonEpisodes = data[1][0]
                    })
                    .catch() 
                }
                this.loadDetailPage = false
                
            })
            .catch()
        },

        addFavoris(){
            this.loadfavoris = true
            this.axios.post(this.requestHttp + 'addfavoris/' + this.id,'',{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
            }).then(() => {
                this.setDataFavoris
                this.loadfavoris = false
                this.favoris = 'vrai'
            })
            .catch()
        },
        deleteFavoris(){
            this.loadfavoris = true
            this.axios.delete(this.requestHttp + 'deletefavoris/' + this.id, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
            }).then(() => {
                this.setDataFavoris
                this.loadfavoris = false
                this.favoris = 'faux'
            })
            .catch()
        },
        openLecteurVideo(data){
            setTimeout(() => {
                this.axios.post(this.requestHttp + 'addvus/' + this.contenu.id,'',{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                },
                }).then(() => {
                })
                .catch()
            }, 3000);
            if(data != 'non'){
               this.detail[2] = data 
            }
            localStorage.setItem('detail', JSON.stringify(this.detail))
            localStorage.setItem('oldpage', 'detail')
            this.$router.push('/lecteur');
        },
        
    },
    computed:{
        requestHttp(){
            return this.$store.state.api
        },
        setDataFavoris(){
            return this.$store.dispatch('requestFavoris')
        },
    }
}
</script>

<style>
    .scrollbar-hide::-webkit-scrollbar {
    display: none;
    }

    /* For IE, Edge and Firefox */
    .scrollbar-hide {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .swiper_similaire {
        width: 100%;
        height: 100%;
        padding-top: 50px;
        padding-bottom: 70px;
        display: inherit;
    }

    .swiper-wrapper{
        height: 100%;
        width: 100%;
    }

    .swiper-slide_similaire {
        background-position: center;
        background-size: cover;
        width: 260px;
    }

    .swiper_similaire .swiper-button-prev{
        top:6% !important;
    }
    .swiper_similaire .swiper-button-next{
        top:6% !important;
    }
    .swiper_acteurs .swiper-button-prev{
        top:7% !important;
    }
    .swiper_acteurs .swiper-button-next{
        top:7% !important;
    }

    .swiper_acteurs {
    padding-left: 1rem !important;
    padding-top: 2% !important;
    }
</style>