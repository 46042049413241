import { createI18n } from 'vue-i18n'

const i18n = createI18n({
    locale: "fr",
    fallbackLocale: "fr",
    messages: {
        fr: {
            disponible: 'Maintenant Disponible',
            continuer:'Continuer',
            film:'Films',
            serie:'Séries',
            webserie:'Web Séries',
            novelas:'Novelas',
            accueil:'Acceuil',
            favoris:'Favoris',
            recherche:'Recherche',
            creersouscompte:'Créer un sous-compte',
            messouscomptes:'Mes sous-comptes',
            changermotdepasse:'Changer le mot de passe',
            nouscontacter:'Nous contacter',
            deconnexion:'Déconnexion',
            recherchez:'Recherchez',
            aucunresultat:'Aucun résultat !',
            messagerecherche:'Essayez de raccourcir ou de reformuler votre recherche',
            aucunfavoris:'Aucun favoris !',
            erreurauth:'Nous avons détecté que votre compte est utilisé sur un autre appareil !. Veuillez vous connecter à nouveau et changez votre mot de passe si se n\'était pas vous.',
            returnconnexion:'Allez vers la page de connexion',
            messagestatusfilm:'Nouveauté',
            motdepasseincorrect:'Mot de passe incorrect',
            emailincorrect:'Cet utilisateur n\'existe pas',
            connexion:'Connexion',
            motdepasseoublie:'Mot de passe oublié ?',
            messagecreationcompte:'Vous n\'avez pas de compte ?',
            cliquecreationcompte:'Cliquez ici',
            buttonconnexion:'Connexion',
            email:'E-mail...',
            motdepasse:'Mot de passe...',
            confirmermotdepasse:'Confirmer le mot de passe',
            inscription:'Inscription',
            name:'Exemple de nom: eken franky',
            buttoninscription:'S\'inscrire',
            messagecompteexistant:'Vous avez un compte ?',
            emailexistdeja:'Cet e-mail existe déjà',
            mdpdifferentdemdpc:'Le mot de passe et sa confirmation sont différents',
            mdpdinferieurahuit:'Votre mot de passe doit être supérieur ou égal a 8 caractères',
            mdpmajuscule:'Votre mot de passe doit commencer par une lettre majuscule',
            messagecode:'Saisissez ici le code envoyé par e-mail...',
            codeverification:'Code de vérification',
            errorcode:'Code incorrect',
            buttonemailcode:'Envoyer',
            emailcode:'Veuillez saisir l\'adresse e-mail de votre compte',
            nouveaumotdepasse:'Nouveau mot de passe',
            votremotdepasseachange:'Votre mot de passe a été modifié avec succes',
            envoyeunnouveaucode:'Cliquez ici, pour envoyer un nouveau code',
            validecodeenvoye:'Un nouveau code a été envoyé',
            validecreercompte:'Votre sous-compte a été crée avec succès',
            buttoncreationsouscompte:'Créer un sous-compte',
            erreuremailegalausouscompte:'L\'adresse e-mail du sous-compte doit être différent de votre adresse e-mail',
            statussouscompte:'Sous-compte',
            aucunsouscompte:'Aucun sous-compte !',
            limitesouscompte:'Nombre limite de sous-compte atteint !',
            nbsouscompte:'Vous êtes sur le point de créer un sous-compte qui sera rattaché au frais d\'abonnement de votre compte principal. Ce sous-compte ne pourra plus être le sous compte d\'un autre compte principal tant qu\'il est rattaché à votre compte principal. À tout moment un sous compte peut passer à un compte principal, en payant un abonnement individuel. Le nombre maximal de sous-comptes est de 02.',
            suitenbsouscompte:'NB: Un sous-compte ne peut créer d\'autres sous-compte.',
            neplusafficher:'Ne plus afficher',
            ancienmdp:'Mot de passe actuel...',
            nouveaumdp:'Le nouveau mot de passe...',
            buttonchanger:'Changer',
            votremdpactuelestincorrect:'Votre mot de passe actuel est incorrect',
            messagepaiement:'choisissez votre mode de paiement',
            acheterunabonnementmensuel:'achetez un abonnement mensuel à seulement 1500 XAF, et profitez du meilleur cinéma africain en un clic.',
            valideabonnementpaypal:'abonnement acheté avec succès',
            abonnementtermine:'Quel dommage, votre abonnement mensuel touche à sa fin. Pour plus de distraction, veuillez souscrire une nouvelle fois à un abonnement.',
            bonjour:'Bonjour',
            buttonabonnement:'Achetez un abonnement',
            abonnementterminesecond:'Quel dommage, l\'abonnement mensuel du compte principal auquel vous êtes attaché touche à sa fin. Pour plus de distraction, Veuillez passer en compte principal en achetant un abonnement.',
            downloadapp:'Obtenez notre application gratuitement et accédez à plateforme en un clic. !',
            telechargerbutton:'Télécharger',
            ignorerbutton:'ignorer',
            choixfilm:'Vous avez choisi',
            jouermaintenant:'Jouer maintenant',
            acteurs:'Acteurs',
            similaires:'Similaires',
            passergenerique:'Passer le générique',
            messagebonus:'Merci de vous être inscrit. Pour vous accueillir nous vous offrons une journée gratuite, pour profiter de notre plateforme.',
        },
        en: {
            disponible: 'Now Available',
            continuer:'Continue',
            film:'Movies',
            serie:'Series',
            webserie:'Web Series',
            novelas:'Novelas',
            accueil:'Home',
            favoris:'Favorites',
            recherche:'Search',
            creersouscompte:'Create a sub-account',
            messouscomptes:'My sub-accounts',
            changermotdepasse:'Change password',
            nouscontacter:'Contact us',
            deconnexion:'Disconnection',
            recherchez:'Search',
            aucunresultat:'No results!',
            messagerecherche:'Try to shorten or rephrase your search',
            aucunfavoris:'No favourites !',
            erreurauth:'We have detected that your account is being used on another device. Please log in again and change your password if it wasn\'t you.',
            returnconnexion:'Go to the login page',
            messagestatusfilm:'New',
            motdepasseincorrect:'Incorrect password',
            emailincorrect:'User does not exist',
            connexion:'Login',
            motdepasseoublie:'Forgot your password ?',
            messagecreationcompte:'Don\'t have an account ?',
            cliquecreationcompte:'Click here',
            buttonconnexion:'Login',
            email:'E-mail...',
            motdepasse:'Password...',
            confirmermotdepasse:'Confirm password',
            inscription:'Register',
            name:'Name example: eken franky',
            buttoninscription:'Sign up',
            messagecompteexistant:'You have an account ?',
            emailexistdeja:'This e-mail already exists',
            mdpdifferentdemdpc:'The password and its confirmation are different',
            mdpdinferieurahuit:'Your password must be at least 8 characters long',
            mdpmajuscule:'Your password must start with a capital letter',
            messagecode:'Enter the code sent by e-mail here...',
            codeverification:'Verification code',
            errorcode:'Invalid code',
            buttonemailcode:'Send',
            emailcode:'Please enter the e-mail address of your account',
            nouveaumotdepasse:'New password',
            votremotdepasseachange:'Votre mot de passe a été modifié avec succes',
            envoyeunnouveaucode:'Click here to send a new code',
            validecodeenvoye:'A new code has been sent',
            validecreercompte:'Your sub-account has been successfully created',
            buttoncreationsouscompte:'Create a sub-account',
            erreuremailegalausouscompte:'The e-mail address of the sub-account must be different from your e-mail address',
            statussouscompte:'Sub-account',
            aucunsouscompte:'No sub-accounts !',
            limitesouscompte:'Sub-account limit reached !',
            nbsouscompte:'You are about to create a sub-account that will be attached to the subscription fee of your main account. This sub-account cannot be a sub-account of another main account as long as it is attached to your main account. At any time a sub-account can be upgraded to a main account, by paying an individual subscription fee.The maximum number of sub-accounts is 02.',
            suitenbsouscompte:'NB: A sub-account cannot create other sub-accounts.',
            neplusafficher:'No longer display',
            ancienmdp:'Current password...',
            nouveaumdp:'The new password...',
            buttonchanger:'Change',
            votremdpactuelestincorrect:'Your current password is incorrect',
            messagepaiement:'choose your payment method',
            acheterunabonnementmensuel:'buy a monthly subscription for only XAF 1500, and enjoy the best of African cinema at the click of a button.',
            valideabonnementpaypal:'successfully purchased subscription',
            abonnementtermine:'what a shame, your monthly subscription is coming to an end. For more entertainment, please subscribe again.',
            bonjour:'Hello',
            buttonabonnement:'Buy a subscription',
            abonnementterminesecond:'What a shame, the monthly subscription of the main account you are attached to is coming to an end. For more entertainment, please switch to the main account by purchasing a subscription.',
            downloadapp:'Get our free application and access the platform in one click.',
            telechargerbutton:'Download',
            ignorerbutton:'skip',
            choixfilm:'You have chosen',
            jouermaintenant:'Play now',
            acteurs:'Actors',
            similaires:'Similar',
            passergenerique:'Skip the credits',
            messagebonus:'Thank you for registering. To welcome you we offer you a free day to use our platform.',
        }, 
    },
})


export default i18n