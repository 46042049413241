import { createApp } from 'vue'
import App from './App.vue'
import './style.css'
import store from "./store/store"
import router from './MesRoutes/routes'
import axios from 'axios'
import VueAxios from 'vue-axios'
import emitter from './emitter/useEmitter';
import Vue3TouchEvents from "vue3-touch-events";
import i18n from './locales/i18n'
import VueScrollTo  from 'vue-scrollto'
import './registerServiceWorker'



window.emitter = emitter;
createApp(App).config.globalProperties.$mitt = emitter;
createApp(App).use(store).use(router).use(emitter).use(VueScrollTo).use(Vue3TouchEvents).use(i18n).use(VueAxios, axios).mount('#app')
