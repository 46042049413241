<template>
  <div>
    <div id="payButton" class="w-full h-[3rem] relative">
        <div class="w-1/3 h-full absolute left-0 rounded-l-md overflow-hidden">
            <button class="w-full h-full" type="button">
                <img class="w-full h-full object-cover object-center" src="/images/OMMOMO.webp" alt="" srcset="">
            </button>
            
        </div>
        <div class="w-2/3 h-full absolute right-0">
            <button type="button"  class="w-full text-white h-full items-center text-center rounded-r-md  text-xl bg-[#FF0000] ">
                <div class="w-1/2 flex items-center justify-center mx-auto space-x-1">
                    <img class="w-8" src="/icons/coins.png" alt="" srcset="">
                    <p class="text-center">Payer</p>
                </div>
            </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    mounted(){
        window.campay.options({
            payButtonId: "payButton", //Required
            description: "achetez un abonnement mensuel, et profitez du meilleur cinéma africain en un clic.", //Required
            amount: "1500", //Optional
            currency: "XAF", //Required
            externalReference: "", //Optional
            redirectUrl: "", //Optional
        });
                                                                        
        window.campay.onSuccess = async () => { 
            // console.log('paye avec succes')
            // console.log(this.form)
            //console.log(data)
            //this.Register();
            let modal = window.document.querySelector("#myCamPayModal .campay-modal-close")
            modal.click()
            this.SaveAbonnement()
        }
                                                                        
        window.campay.onFail = async (data) =>{ 
            //alert('Status: '+data.status+'\n reference: '+data.reference) 
            alert('Echec de la transaction, verifiez que votre solde est suffisant' + '\n Status: '+data.status + '\n reference: '+data.reference) 
        }
    },
    methods:{
        SaveAbonnement(){
            this.axios.post(this.requestHttp + 'abonnement', '',{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                },
            })
            .then(() => {
                localStorage.removeItem('hidpaiement')
                this.$router.push('/accueil')
            })
            .catch()
        }
    },
    computed:{
        requestHttp(){
            return this.$store.state.api
        }
    }
}
</script>

<style>

</style>