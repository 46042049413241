<template>
  <div class=" w-full h-screen relative bg-[#181A20] scrollbar-hide delay-500 duration-300 overflow-hidden overflow-y-scroll">
    <div v-if="show" class="h-screen bg-[#181A20] w-full ">
      <div class="w-2/3 h-40 mt-12 relative mx-auto">
        <div v-if="hidLoadAvatar == true" class="w-full h-full absolute flex">
          <div class="w-[10rem] h-[10rem] bg-[#20232b] mx-auto rounded-full flex items-center">
              <div class="
                rounded-lg
                flex
                items-center
                justify-center
                mx-auto
                z-20
                text-center">
                <svg aria-hidden="true" class="inline w-5 h-5 mr-2 text-[#ffffff] animate-spin dark:text-white fill-[#FF0000]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
              </div>
          </div>
        </div>     
        <div v-if="hidLoadAvatar == false" class="w-[10rem] h-[10rem] relative rounded-full overflow-hidden  mx-auto items-center justify-center ">
          <img v-if="profil.avatar" :src="picture" @click="selectImage" alt=""  class="rounded-full w-full h-full object-cover  object-center">
          <img v-if="profil.avatar == null" :src="picture" @click="selectImage" alt=""  class="rounded-full w-full h-full object-contain  object-center bg-[#161617]">
        </div>
        <div v-if="hidLoadAvatar == false" class="w-full  transform md:-translate-y-8 -translate-y-10  lg:-translate-y-8 lg:-translate-x-6 xl:-translate-y-8 xl:-translate-x-6 translate-x-1 md:-translate-x-7 flex justify-end items-center  ">
          <div class="absolute">
            <input @change="avatar"  type="file" name="avatar[]" id="avatar"
              class="w-8  h-8 mx-auto bg-cover bg-no-repeat " style=" display : none" ref="fileInput" @input="pickFile"
              accept="image/*">
            <label ref="file" for="avatar" id="uploadBtn"
              class="w-10 h-10 rounded-full flex items-center  mx-auto  justify-center  bg-[#181A20] cursor-pointer text-white font-semibold duration-150 delay-150">
              <img class="w-5 h-5" src="/icons/camera_480px.png" alt="" srcset="">
            </label>
          </div>
        </div>
      </div>
      <div>
        <div class="text-center text-[#8c8b96] mt-2 font-bold flex justify-center items-center w-full">
          <div class="flex w-full justify-center mx-auto">
              <div v-if="showEditName == false && loadEditName == false" class="w-full relative">
                <div class="absolute md:right-14 right-3 top-1">
                  <button @click="showEditName = true" type="button" class="items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                      class="w-[1.4rem] pt-1 pl-1 text-white h-[1.2rem]">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                    </svg>
                  </button>
                </div>
                <div>
                  <span class="text-center text-lg font-semibold">{{ profil.name }}</span>
                </div>
              </div>
              <div v-if="showEditName == true && loadEditName == false"  class="w-full relative mb-1">
                <div class="absolute right-0  flex">
                  <button @click="updateEditeName" type="button" class="items-center justify-center">
                    <svg class="w-[1.5rem] pt-1 pl-1 text-green-500 h-[1.5rem]" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 208 208" version="1.1">
                      <g id="surface1">
                        <path style=" stroke:none;fill-rule:nonzero;fill:#008000;fill-opacity:1;" d="M 177.796875 37.269531 L 163.667969 27.667969 C 159.761719 25.019531 154.40625 26.035156 151.789063 29.914063 L 82.542969 132.03125 L 50.71875 100.207031 C 47.394531 96.882813 41.980469 96.882813 38.65625 100.207031 L 26.558594 112.304688 C 23.234375 115.628906 23.234375 121.042969 26.558594 124.398438 L 75.496094 173.332031 C 78.234375 176.074219 82.542969 178.164063 86.421875 178.164063 C 90.296875 178.164063 94.207031 175.734375 96.730469 172.070313 L 180.074219 49.121094 C 182.71875 45.242188 181.703125 39.917969 177.796875 37.269531 Z "/>
                      </g>
                    </svg>
                  </button>
                  <button @click="showEditName = false" type="button" class="items-center justify-center">
                    <svg class="w-[1.7rem] pt-1 pl-1 text-green-500 h-[1.7rem]" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" version="1.1">
                      <g id="surface1">
                      <path style=" stroke:none;fill-rule:nonzero;fill:#FF0000;fill-opacity:1;" d="M 139.5 116.5 L 116.5 139.5 L 233 256 L 116.5 372.5 L 139.5 395.5 L 256 279 L 372.5 395.5 L 395.5 372.5 L 279 256 L 395.5 139.5 L 372.5 116.5 L 256 233 Z "/>
                      </g>
                    </svg>
                  </button>
                </div>
                <div>
                  <input maxlength="16" v-model="form.name" class=" w-[50%] bg-[#181A20] border-b-2 border-white placeholder-gray-500 focus:outline-none peer focus:border-[#FF0000] rounded-none text-xs" type="text" :placeholder="$t('name') +'...'" name="" id="">
                </div>
              </div>
              <div class="ml-2">
                  <div v-if="loadEditName == true" class="
                      z-20
                      rounded-lg
                      flex
                      items-center
                      justify-center
                      text-[#FF0000]
                      mx-auto
                      text-center">
                      <svg aria-hidden="true" class="inline w-5 h-5 mr-2 text-[#FF0000] animate-spin dark:text-white fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                      </svg>
                  </div>                  
              </div>
          </div>
        </div>
        <div class="text-center font-semibold text-sm mb-4  text-[#8c8b96] ">
          <p>{{ profil.email }}</p>
        </div>

        <button type="button" class="flex justify-between h-9 my-2 min-w-full  ">
          <div class="w-1/6 h-full">
            <div v-if="hover == false" class="bg-[#FF0000] w-2 h-full">

            </div>
          </div>
          <div class="w-4/6 h-full justify-center flex text-center items-center text-xs md:text-base font-semibold " :class="hover? 'text-[#8c8b96]':'text-white'">
            {{ $t('accueil') }}</div>
          <div  class="w-1/6 h-full flex justify-end">
            <div v-if="hover == false" class="bg-[#FF0000] w-2 h-full">

            </div>
          </div>
        </button>

        <button @click="openFavoris" @mouseover="hover= true" @mouseleave="hover = false " type="button" class="group flex justify-between h-9 min-w-full  ">
            <div class="w-1/6 h-full">
              <div class="bg-[#181A20] group-hover:bg-[#FF0000] w-2 h-full"></div>
            </div>
            <div class="w-4/6 h-full group-hover:text-white justify-center flex text-center items-center text-xs md:text-base font-semibold text-[#8c8b96]">
              {{ $t('favoris') }}</div>
            <div class="w-1/6 h-full flex justify-end">
              <div class="bg-[#181A20] group-hover:bg-[#FF0000] w-2 h-full"></div>
            </div>
        </button>

        <button  @click="openRecherche" @mouseover="hover= true" @mouseleave="hover = false "  type="button" class="group flex justify-between h-9  min-w-full  ">
            <div class="w-1/6 h-full">
              <div class="bg-[#181A20] group-hover:bg-[#FF0000]  w-2 h-full"></div>
            </div>
            <div class="w-4/6 h-full group-hover:text-white justify-center flex text-center items-center text-xs md:text-base font-semibold text-[#8c8b96]">
              {{ $t('recherche') }}</div>
            <div class="w-1/6 h-full flex justify-end">
              <div class="bg-[#181A20] group-hover:bg-[#FF0000]  w-2 h-full"></div>
            </div>
        </button>

        <button v-if="this.profil.user_id_secondary == false" @mouseover="hover= true" @mouseleave="hover = false " type="button"  class="flex group justify-between h-9  min-w-full  ">
          <div class="w-1/6 h-full">
            <div class="bg-[#181A20]  group-hover:bg-[#FF0000] w-2 h-full">
            </div>
          </div>
          <div @click="openSubAccount" class="w-4/6 h-full relative">
            <div class=" h-full absolute right-0 flex items-center">
              <div class="w-5 h-5 md:w-6 md:h-6 ml-3 rounded-full bg-[#FF0000] flex items-center">
                <p class="text-white font-serif text-xs mx-auto">{{ souscompte?.length }}/2</p>
              </div>
              
            </div>
            <div class="w-full h-full group-hover:text-white  justify-center flex text-center items-center text-xs md:text-base font-semibold text-[#8c8b96]">
            {{ $t('messouscomptes') }}
            </div>
          </div>
          
          <div class="w-1/6 h-full flex justify-end">
            <div class="bg-[#181A20] group-hover:bg-[#FF0000] w-2 h-full">
            </div>
          </div>
        </button>


        <button v-if="this.profil.user_id_secondary == false && souscompte?.length == 2" @mouseover="hover= true" @mouseleave="hover = false " type="button" class="flex justify-between group h-9  min-w-full bg-gray-600 relative">
          <div class="w-1/6 h-full">
            <div class="bg-[#181A20] w-2 h-full group-hover:bg-[#FF0000] ">
            </div>
          </div>
          <div v-if="limite == false" @click="limiteComptes" class="w-4/6 h-full  justify-center flex text-center items-center text-xs md:text-base font-semibold text-[#8c8b96]">
            {{ $t('creersouscompte') }}
          </div>
          <div v-if="limite == true" class="w-4/6 h-full justify-center flex text-xs md:text-md items-center font-semibold text-[#FF0000]">
            {{ $t('limitesouscompte') }}
          </div>
          <div class="w-1/6 h-full flex justify-end">
            <div class="bg-[#181A20] w-2 h-full group-hover:bg-[#FF0000] ">
            </div>
          </div>
        </button>

        <button @click="openCreateSubAccount" v-if="this.profil.user_id_secondary == false && souscompte?.length < 2" @mouseover="hover= true" @mouseleave="hover = false " type="button" class="flex justify-between group h-9  min-w-full  ">
          <div class="w-1/6 h-full">
            <div class="bg-[#181A20] w-2 h-full group-hover:bg-[#FF0000] ">
            </div>
          </div>
          <div class="w-4/6 h-full group-hover:text-white  justify-center flex text-center items-center text-xs md:text-base font-semibold text-[#8c8b96]">
            {{ $t('creersouscompte') }}</div>
          <div class="w-1/6 h-full flex justify-end">
            <div class="bg-[#181A20] w-2 h-full group-hover:bg-[#FF0000] ">
            </div>
          </div>
        </button>

        <button @click="openChangePassword" @mouseover="hover= true" @mouseleave="hover = false " type="button" class="flex justify-between  group h-9  min-w-full  ">
          <div class="w-1/6 h-full">
            <div class="bg-[#181A20] group-hover:bg-[#FF0000]  w-2 h-full">
            </div>
          </div>
          <div class="w-4/6 h-full group-hover:text-white justify-center flex text-center items-center text-xs md:text-base font-semibold text-[#8c8b96]">
            {{ $t('changermotdepasse') }}</div>
          <div class="w-1/6 h-full flex justify-end">
            <div class="bg-[#181A20] group-hover:bg-[#FF0000]  w-2 h-full">

            </div>
          </div>
        </button>

        <button @click="contactService" @mouseover="hover= true" @mouseleave="hover = false " type="button" class="flex justify-between h-9  min-w-full group  ">
          <div class="w-1/6 h-full">
            <div class="bg-[#181A20] group-hover:bg-[#FF0000]  w-2 h-full">

            </div>
          </div>
          <div class="w-4/6 h-full justify-center group-hover:text-white  flex text-center items-center text-xs md:text-base font-semibold text-[#8c8b96]">
            {{ $t('nouscontacter') }}</div>
          <div class="w-1/6 h-full flex justify-end">
            <div class="bg-[#181A20] group-hover:bg-[#FF0000]  w-2 h-full">

            </div>
          </div>
        </button>
        <div @click="logOut" @mouseover="hover= true" @mouseleave="hover = false " class="w-full md:h-20  flex items-center group">
          <button type="button" class="flex justify-between h-9  min-w-full group  ">
            <div class="w-1/6 h-9">
              <div class="bg-[#181A20] group-hover:bg-[#FF0000]  w-2 h-full">

              </div>
            </div>
            <div class="w-4/6 h-full justify-center  flex text-center items-center text-md md:text-xl font-semibold ">
              <p v-if="logOutLoading == false" class="group-hover:text-white text-[#FF0000]">{{ $t('deconnexion') }}</p>
              <div v-if="logOutLoading == true" class="
                        rounded-lg
                        flex
                        items-center
                        justify-center
                        text-white
                        mx-auto
                        text-center">
                <svg aria-hidden="true" class="inline w-6 h-6 mr-2 text-white animate-spin dark:text-white fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
              </div>
            </div>
            <div class="w-1/6 h-9 flex justify-end">
              <div class="bg-[#181A20] group-hover:bg-[#FF0000]  w-2 h-full">

              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: true,
      picture: '/logo/safarilogowebfondnoir-removebg-preview.png',
      profil:{},
      hover: false,
      logOutLoading:false,
      souscompte:{},
      limite:false,
      number:'',
      hidLoadAvatar:false,
      showEditName: false,
      form:{
        name:''
      },
      loadEditName: false,
      sendAvatar:false,
    }
  },
  watch: {
    getDataAvatar: {
      handler(newValue) {
          this.profil = newValue[0]
        }
      },
    picture: {
      handler(newValue) {
        if(this.picture[0].size){
          this.hidLoadAvatar = true
          let user = new FormData();
          user.append('avatar', newValue[0]);
          console.log('icic')
          this.axios.post(this.requestHttp + 'updateuser', user, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
          }).then(() => {
            this.$store.dispatch('requestAvatar')
            setTimeout(() => {
                this.sendAvatar = false
                this.hidLoadAvatar = false
            }, 5000);
          })
          .catch()
        }
      },
      deep: true
    },
  },
  mounted(){
    if( this.getDataAvatar[0]){
      this.profil = this.getDataAvatar[0]
      this.souscompte = this.getDataAvatar[1]
      if(this.profil.avatar){
        this.picture = this.profil.avatar
      }
    }
    
  },
  methods: {
    async logOut(){ 
      localStorage.removeItem('jwtToken')
      this.$router.push('/');
    },
    selectImage() {
      this.$refs.fileInput.click
    },
    pickFile() {
      let input = this.$refs.fileInput
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.picture = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0]);
        this.sendAvatar = true
      }
    },
    avatar(image) {
      this.picture = image.target.files

    },
    openRecherche(){
      window.emitter.emit("menuEventRecherche", true) 
    },
    openFavoris(){
      window.emitter.emit("menuEventFavoris", true) 
    },
    openCreateSubAccount(){
      window.emitter.emit("menuEventCreateSubAccount", true) 
    },
    openSubAccount(){
      window.emitter.emit("menuEventOpenSubAccount", true) 
    },
    openChangePassword(){
      window.emitter.emit("menuEventOpenChangePassword", true)
    },
    limiteComptes(){
      this.limite = true
      setTimeout(() => {
        this.limite = false
      }, 2000);
    },
    contactService(){
      this.number = Math.round(Math.random()*(this.getDataAvatar[2].length - 1))
      if(localStorage.getItem('langue') != 'en'){
        window.open('https://wa.me/'+this.getDataAvatar[2][this.number].numero+'?text=Bonjour%20SafariMovies,%20j\'aimerais%20avoir%20une%20assistance.')
      }else{
        window.open('https://wa.me/'+this.getDataAvatar[2][this.number].numero+'?text=Hello%20SafariMovies,%20I%20would%20like%20some%20help.')
      } 
    },
    updateEditeName(){
      this.loadEditName = true
      this.axios.put(this.requestHttp + 'updatename/' + this.profil.id, this.form, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
      }).then(() => {
        this.form.name = ''
        this.$store.dispatch('requestAvatar')
        setTimeout(() => {
          this.loadEditName = false
          this.showEditName = false
        }, 1500);
      })
      .catch()
    },
    updateAvatar(){
      let user = new FormData();
      user.append('avatar', this.picture);
      this.axios.post(this.requestHttp + 'updateuser', user, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
      }).then(() => {
      })
      .catch()
    }
  },

  computed:{
    requestHttp(){
      return this.$store.state.api
    },
    setDataAvatar(){
        return this.$store.dispatch('requestAvatar')
    },
    getDataAvatar(){
        return this.$store.state.dataAvatar
    },
  }
}
</script>
<style scoped>
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
</style>


