<template>
  <div class="bg-[#181A20] w-full h-screen flex">
    <button @click="closeChangeMdp" class="fixed top-0 z-50">
        <img class="w-8 h-8 md:w-9 md:h-9 flex-none md:ml-2 ml-4 mt-4 md:mt-2  md:relative transform hover:scale-110 motion-reduce:transform-none hover:z-10 transition duration-1000 " src="/icons/delete_sign_red.png" alt="" srcset="">
    </button>
    <div class="xl:w-[30%] w-[98%] md:w-[50%] lg:w-[40%] mx-auto rounded-xl fixed right-0 left-0 m-auto z-40">
        <!--Formulaire-->
        <div class="w-full h-full relative">
           <div class="w-full h-full absolute rounded-xl z-10">
                            <!--Titre-->
                <div class="w-full flex h-16 items-center">
                    <p class=" text-white text-xl font-semibold mx-auto">{{ $t('changermotdepasse') }}</p>
                </div>
                            <!--Email/password-->
                <form action="" class="space-y-8">
                    <div class="w-full space-y-8 mt-3 px-3">
                        <!--oldpassword-->
                        <div>
                            <p v-if="this.erreurs.message == 'password incorrect'" class="text-[#FF0000] w-full text-xs">{{ $t('votremdpactuelestincorrect') }}</p>
                            <p v-if="this.erreurs == 'old password no 8'" class="text-[#FF0000] w-full text-xs">{{ $t('votremdpactuelestincorrect') }}</p>
                            <div class="relative w-full ">
                                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-[1.20rem] h-[1.20rem] text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 208 208" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:nonzero;fill:#959595;fill-opacity:1;" d="M 102.425781 0 C 76.234375 0 55.152344 21.082031 55.152344 47.273438 L 55.152344 78.789063 C 37.824219 78.789063 23.636719 92.976563 23.636719 110.304688 L 23.636719 173.332031 C 23.636719 190.660156 37.824219 204.847656 55.152344 204.847656 L 149.695313 204.847656 C 167.023438 204.847656 181.210938 190.660156 181.210938 173.332031 L 181.210938 110.304688 C 181.210938 92.976563 167.023438 78.789063 149.695313 78.789063 L 149.695313 47.273438 C 149.695313 21.082031 128.613281 0 102.425781 0 Z M 102.425781 15.757813 C 120.367188 15.757813 133.9375 29.328125 133.9375 47.273438 L 133.9375 78.789063 L 70.910156 78.789063 L 70.910156 47.273438 C 70.910156 29.328125 84.480469 15.757813 102.425781 15.757813 Z M 102.425781 118.183594 C 111.101563 118.183594 118.183594 125.261719 118.183594 133.9375 C 118.183594 139.449219 115.042969 144.863281 110.304688 147.234375 L 110.304688 165.453125 C 110.304688 170.195313 107.164063 173.332031 102.425781 173.332031 C 97.683594 173.332031 94.546875 170.195313 94.546875 165.453125 L 94.546875 147.234375 C 89.804688 144.863281 86.667969 139.449219 86.667969 133.9375 C 86.667969 125.261719 93.746094 118.183594 102.425781 118.183594 Z "/></g></svg>
                                </div>
                                <input v-model="form.oldpassword" type="password" id="input-group-1" class="bg-[#181A20] border border-white text-gray-400 text-sm rounded-lg focus:ring-[#FF0000] focus:border-[#FF0000] block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#FF0000] dark:focus:border-[#FF0000] focus:outline-none" :placeholder="$t('ancienmdp')">
                            </div>
                        </div>                                           
                                        <!--newpassword-->
                        <div>
                            <p v-if="errorpassword == 'password no match'" class="text-[#FF0000] w-full text-xs">{{ $t('mdpdifferentdemdpc') }}</p>
                            <p v-if="errorpassword == 'password no 8'" class="text-[#FF0000] w-full text-xs">{{ $t('mdpdinferieurahuit') }}</p>
                            <p v-if="errorpassword == 'uppercase password'" class="text-[#FF0000] w-full text-xs">{{ $t('mdpmajuscule') }}</p>
                            <div class="relative w-full ">
                                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-[1.20rem] h-[1.20rem] text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 208 208" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:nonzero;fill:#959595;fill-opacity:1;" d="M 102.425781 0 C 76.234375 0 55.152344 21.082031 55.152344 47.273438 L 55.152344 78.789063 C 37.824219 78.789063 23.636719 92.976563 23.636719 110.304688 L 23.636719 173.332031 C 23.636719 190.660156 37.824219 204.847656 55.152344 204.847656 L 149.695313 204.847656 C 167.023438 204.847656 181.210938 190.660156 181.210938 173.332031 L 181.210938 110.304688 C 181.210938 92.976563 167.023438 78.789063 149.695313 78.789063 L 149.695313 47.273438 C 149.695313 21.082031 128.613281 0 102.425781 0 Z M 102.425781 15.757813 C 120.367188 15.757813 133.9375 29.328125 133.9375 47.273438 L 133.9375 78.789063 L 70.910156 78.789063 L 70.910156 47.273438 C 70.910156 29.328125 84.480469 15.757813 102.425781 15.757813 Z M 102.425781 118.183594 C 111.101563 118.183594 118.183594 125.261719 118.183594 133.9375 C 118.183594 139.449219 115.042969 144.863281 110.304688 147.234375 L 110.304688 165.453125 C 110.304688 170.195313 107.164063 173.332031 102.425781 173.332031 C 97.683594 173.332031 94.546875 170.195313 94.546875 165.453125 L 94.546875 147.234375 C 89.804688 144.863281 86.667969 139.449219 86.667969 133.9375 C 86.667969 125.261719 93.746094 118.183594 102.425781 118.183594 Z "/></g></svg>
                                </div>
                                <input v-model="form.newpassword" type="password" id="input-group-1" class="bg-[#181A20] border border-white text-gray-400 text-sm rounded-lg focus:ring-[#FF0000] focus:border-[#FF0000] block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#FF0000] dark:focus:border-[#FF0000] focus:outline-none" :placeholder="$t('nouveaumdp')">
                            </div>
                        </div>
                        <!--confirm-->
                        <div class="relative w-full ">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg class="w-[1.20rem] h-[1.20rem] text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 208 208" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:nonzero;fill:#959595;fill-opacity:1;" d="M 102.425781 0 C 76.234375 0 55.152344 21.082031 55.152344 47.273438 L 55.152344 78.789063 C 37.824219 78.789063 23.636719 92.976563 23.636719 110.304688 L 23.636719 173.332031 C 23.636719 190.660156 37.824219 204.847656 55.152344 204.847656 L 149.695313 204.847656 C 167.023438 204.847656 181.210938 190.660156 181.210938 173.332031 L 181.210938 110.304688 C 181.210938 92.976563 167.023438 78.789063 149.695313 78.789063 L 149.695313 47.273438 C 149.695313 21.082031 128.613281 0 102.425781 0 Z M 102.425781 15.757813 C 120.367188 15.757813 133.9375 29.328125 133.9375 47.273438 L 133.9375 78.789063 L 70.910156 78.789063 L 70.910156 47.273438 C 70.910156 29.328125 84.480469 15.757813 102.425781 15.757813 Z M 102.425781 118.183594 C 111.101563 118.183594 118.183594 125.261719 118.183594 133.9375 C 118.183594 139.449219 115.042969 144.863281 110.304688 147.234375 L 110.304688 165.453125 C 110.304688 170.195313 107.164063 173.332031 102.425781 173.332031 C 97.683594 173.332031 94.546875 170.195313 94.546875 165.453125 L 94.546875 147.234375 C 89.804688 144.863281 86.667969 139.449219 86.667969 133.9375 C 86.667969 125.261719 93.746094 118.183594 102.425781 118.183594 Z "/></g></svg>
                            </div>
                            <input v-model="form.password_confirmation" type="password" id="input-group-1" class="bg-[#181A20] border border-white text-gray-400 text-sm rounded-lg focus:ring-[#FF0000] focus:border-[#FF0000] block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#FF0000] dark:focus:border-[#FF0000] focus:outline-none" :placeholder="$t('confirmermotdepasse')">
                        </div>
                    </div>
                    <div class="w-full">
                        <div class=" w-full flex">
                            <button @click="updatePassword" v-if="loading == false && changePwdValide == false" type="button"  class=" bg-[#FF0000] rounded-lg text-lg hover:border-2 hover:border-[#FF0000] hover:bg-transparent text-white w-40 mx-auto h-10 text-center">{{ $t('buttonchanger') }}</button>
                            <div v-if="loading == true && changePwdValide == false" class="
                                rounded-lg
                                flex
                                items-center
                                justify-center
                                border-[#FF0000]
                                border-2
                                text-white
                                w-40
                                mx-auto
                                h-10
                                text-center">
                            <svg aria-hidden="true" class="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-white fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            </div>
                            <div v-if="changePwdValide == true" class="w-full flex">
                                <p  class="mx-auto text-xs text-green-500 ">{{ $t('votremotdepasseachange') }}</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="w-full h-[23rem] bg-black  opacity-80 rounded-xl"></div> 
        </div>
    </div>
    <div @click="closeChangeMdp"  class="w-full h-screen backdrop-blur-sm  z-30 flex fixed"></div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            form: {
                oldpassword: "",
                newpassword: "",
                password_confirmation:"",
            },
            loading: false,
            errorpassword:'',
            id:'',
            erreurs:'',
            changePwdValide:''
        }
    },

    watch: {
        getDataAvatar: {
            handler(newValue) {
                this.id = newValue[0].id
            },
            deep: true
        },
    },
    mounted(){
        this.id = this.getDataAvatar[0].id
    },
    methods: {
    async updatePassword() {
        if(this.form.newpassword != this.form.password_confirmation){
            this.errorpassword = 'password no match'
            this.erreurs = ''
        }else{
            if(this.form.newpassword.length < 8){
               this.errorpassword = 'password no 8'
               this.erreurs = ''
            }else{
                if(this.form.newpassword != (this.form.newpassword[0].toUpperCase() +  this.form.newpassword.slice(1))){
                    this.errorpassword = 'uppercase password'
                    this.erreurs = ''
                }else{
                    if(this.form.oldpassword.length < 8){
                       this.erreurs = 'old password no 8' 
                       this.errorpassword = ''
                    }else{
                        this.loading = true;
                        await this.axios.put(this.requestHttp + 'updatepassword/' + this.id , this.form,{
                            headers: {
                            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                            },
                        }).then(() => {
                            this.loading = false;
                            this.errorpassword = false,
                            this.erreurs = '',
                            this.changePwdValide = true
                            setTimeout(() => {
                                window.emitter.emit("menuEventOpenChangePassword", false)
                                this.changePwdValide = false
                            }, 5000);
                        })
                        .catch((error) => {
                            this.erreurs = error.response.data;
                            this.loading = false;
                        });
                    }
                }
            }
        }
        
    },
 
    closeChangeMdp(){
        window.emitter.emit("menuEventOpenChangePassword", false) 
    },
  },
  computed:{
    requestHttp(){
      return this.$store.state.api
    },
    getDataAvatar(){
        return this.$store.state.dataAvatar
    },
  }
}
</script>

<style>

</style>