<template>
  <div class="bg-[#181A20]">
    <div class="w-full h-screen relative">
       <!-- formulaire code -->
        <div v-if="hidcode == true" class="w-full h-screen backdrop-blur-sm absolute z-20">
            <div class="w-full h-screen relative">
                <div class=" absolute z-50 ml-2 mt-1">
                    <button type="button"><img @click="hidcode = false" class="h-8 " src="/icons/delete_sign_filled_100px.png" alt="" srcset=""></button>
                </div>
                <div class="w-full h-full flex">
                    <div class=" w-full h-full relative">
                        <div class="w-full h-full flex absolute">
                            <div class="  xl:w-[25%]   lg:w-[45%] md:w-[50%] w-[90%]    mx-auto relative rounded-xl">
                        <!--Formulaire-->
                                <div class="w-full h-full absolute rounded-xl z-10">
                            <!--Titre-->
                                    <div class="w-full flex h-16 items-center">
                                        <p class=" text-white text-xl font-semibold mx-auto">{{ $t('codeverification') }}</p>
                                    </div>
                                    <!--Email/code-->
                                    <form @submit.prevent="sendMail" action="">
                                        <div class="w-full mt-1 px-3">
                                            <!--code-->
                                            <div>
                                                <p v-if="errorcode == 'incorrect'" class="text-[#FF0000] w-full text-xs">{{ $t('errorcode') }}</p>
                                                <div class="relative w-full ">
                                                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 208 208" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:nonzero;fill:#959595;fill-opacity:1;" d="M 102.425781 0 C 76.234375 0 55.152344 21.082031 55.152344 47.273438 L 55.152344 78.789063 C 37.824219 78.789063 23.636719 92.976563 23.636719 110.304688 L 23.636719 173.332031 C 23.636719 190.660156 37.824219 204.847656 55.152344 204.847656 L 149.695313 204.847656 C 167.023438 204.847656 181.210938 190.660156 181.210938 173.332031 L 181.210938 110.304688 C 181.210938 92.976563 167.023438 78.789063 149.695313 78.789063 L 70.910156 78.789063 L 70.910156 47.273438 C 70.910156 29.328125 84.480469 15.757813 102.425781 15.757813 C 120.367188 15.757813 133.9375 29.328125 133.9375 47.273438 L 133.9375 55.152344 L 149.695313 55.152344 L 149.695313 47.273438 C 149.695313 21.082031 128.613281 0 102.425781 0 Z M 102.425781 118.183594 C 111.101563 118.183594 118.183594 125.261719 118.183594 133.9375 C 118.183594 139.449219 115.042969 144.863281 110.304688 147.234375 L 110.304688 165.453125 C 110.304688 170.195313 107.164063 173.332031 102.425781 173.332031 C 97.683594 173.332031 94.546875 170.195313 94.546875 165.453125 L 94.546875 147.234375 C 89.804688 144.863281 86.667969 139.449219 86.667969 133.9375 C 86.667969 125.261719 93.746094 118.183594 102.425781 118.183594 Z "/></g></svg>
                                                    </div>
                                                    <input v-model="code" type="text" id="input-group-1" class="bg-[#181A20] border border-white text-gray-400 text-sm rounded-lg focus:ring-[#FF0000] focus:border-[#FF0000] block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#FF0000] dark:focus:border-[#FF0000] focus:outline-none" :placeholder="$t('messagecode')">
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div v-if="loadingnewcode == false && valideSend == false && compteCreate == false" class="w-full mt-2 flex">
                                            <button @click="newCode" class="mx-auto underline text-xs text-[#FF0000]">{{ $t('envoyeunnouveaucode') }}</button>
                                        </div>
                                        <div v-if="valideSend == true" class="w-full mt-2 flex">
                                            <p  class="mx-auto text-xs text-green-500 mt-2">{{ $t('validecodeenvoye') }}</p>
                                        </div>
                                        <div v-if="compteCreate == true" class="w-full mt-2 flex">
                                            <p  class="mx-auto text-xs text-green-500 mt-2">{{ $t('validecreercompte') }}</p>
                                        </div>
                                        <div v-if="loadingnewcode == true && valideSend == false && compteCreate == false" class="
                                            rounded-lg
                                            flex
                                            items-center
                                            justify-center
                                             text-white
                                             w-40
                                            mx-auto
                                            h-10
                                            text-center">
                                            <svg aria-hidden="true" class="inline w-6 h-6 mr-2 text-[#FF0000] animate-spin dark:text-white fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                            </svg>
                                        </div>
                                    </form>
                                </div>
                                <div class="w-full h-[10rem] bg-black  opacity-80 rounded-xl"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="background-image:url('/logo/safarilogowebfondnoir-removebg-preview.png');" class=" w-full h-screen  bg-center  bg-contain md:bg-auto bg-no-repeat">
            <div class=" w-full h-full relative">
                <div class="w-full h-full flex items-center absolute">
                    <div class="  xl:w-[35%]   lg:w-[45%] md:w-[50%] w-[90%] mx-auto relative rounded-xl">
                        <!--Formulaire-->
                        <div class="w-full h-full absolute rounded-xl z-10">
                            <!--Titre-->
                            <div class="w-full flex h-16 items-center">
                                <p class=" text-white text-xl font-semibold mx-auto">{{ $t('inscription') }}</p>
                            </div>
                            <!--Email/password-->
                            <form @submit.prevent="sendMail" action="" class="space-y-8">
                                <div class="w-full space-y-8 mt-3 px-3">
                                    <!--name-->
                                    <div class="relative w-full ">
                                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 500 500" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:nonzero;fill:#959595;fill-opacity:1;" d="M 306.882813 322.691406 C 305.410156 306.6875 305.988281 295.527344 305.988281 280.917969 C 313.234375 277.117188 326.21875 252.898438 328.386719 232.4375 C 334.085938 231.972656 343.074219 226.429688 345.710938 204.496094 C 347.105469 192.714844 341.488281 186.089844 338.039063 183.996094 C 347.300781 156.132813 366.558594 69.910156 302.464844 60.996094 C 295.835938 49.410156 278.941406 43.558594 257.007813 43.558594 C 169.15625 45.183594 158.574219 109.902344 177.835938 183.996094 C 174.386719 186.089844 168.765625 192.714844 170.164063 204.496094 C 172.835938 226.429688 181.789063 231.972656 187.484375 232.4375 C 189.65625 252.898438 203.140625 277.117188 210.425781 280.917969 C 210.425781 295.527344 210.96875 306.6875 209.496094 322.691406 C 192.136719 369.425781 74.871094 356.289063 69.445313 446.429688 L 446.429688 446.429688 C 441.003906 356.289063 324.242188 369.425781 306.882813 322.691406 Z "/></g></svg>
                                        </div>
                                        <input v-model="form.name" type="text" maxlength="14" id="input-group-1" class="bg-[#181A20] border border-white text-gray-400 text-sm rounded-lg focus:ring-[#FF0000] focus:border-[#FF0000] block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#FF0000] dark:focus:border-[#FF0000] focus:outline-none" :placeholder="$t('name')">
                                    </div>
                                    <!--email-->
                                    <div>
                                        <p v-if="erreurs == 'The email has already been taken.'" class="text-[#FF0000] w-full text-xs">{{ $t('emailexistdeja') }}</p>
                                        <div class="relative w-full ">
                                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                                            </div>
                                            <input v-model="form.email" type="email" id="input-group-1" class="bg-[#181A20] border border-white text-gray-400 text-sm rounded-lg focus:ring-[#FF0000] focus:border-[#FF0000] block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#FF0000] dark:focus:border-[#FF0000] focus:outline-none" :placeholder="$t('email')">
                                        </div>
                                    </div>
                                    
                                    <!--password-->
                                    <div>
                                        <p v-if="errorpassword == 'password no match'" class="text-[#FF0000] w-full text-xs">{{ $t('mdpdifferentdemdpc') }}</p>
                                        <p v-if="errorpassword == 'password no 8'" class="text-[#FF0000] w-full text-xs">{{ $t('mdpdinferieurahuit') }}</p>
                                        <p v-if="errorpassword == 'uppercase password'" class="text-[#FF0000] w-full text-xs">{{ $t('mdpmajuscule') }}</p>
                                        <div class="relative w-full ">
                                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <svg class="w-[1.20rem] h-[1.20rem] text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 208 208" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:nonzero;fill:#959595;fill-opacity:1;" d="M 102.425781 0 C 76.234375 0 55.152344 21.082031 55.152344 47.273438 L 55.152344 78.789063 C 37.824219 78.789063 23.636719 92.976563 23.636719 110.304688 L 23.636719 173.332031 C 23.636719 190.660156 37.824219 204.847656 55.152344 204.847656 L 149.695313 204.847656 C 167.023438 204.847656 181.210938 190.660156 181.210938 173.332031 L 181.210938 110.304688 C 181.210938 92.976563 167.023438 78.789063 149.695313 78.789063 L 149.695313 47.273438 C 149.695313 21.082031 128.613281 0 102.425781 0 Z M 102.425781 15.757813 C 120.367188 15.757813 133.9375 29.328125 133.9375 47.273438 L 133.9375 78.789063 L 70.910156 78.789063 L 70.910156 47.273438 C 70.910156 29.328125 84.480469 15.757813 102.425781 15.757813 Z M 102.425781 118.183594 C 111.101563 118.183594 118.183594 125.261719 118.183594 133.9375 C 118.183594 139.449219 115.042969 144.863281 110.304688 147.234375 L 110.304688 165.453125 C 110.304688 170.195313 107.164063 173.332031 102.425781 173.332031 C 97.683594 173.332031 94.546875 170.195313 94.546875 165.453125 L 94.546875 147.234375 C 89.804688 144.863281 86.667969 139.449219 86.667969 133.9375 C 86.667969 125.261719 93.746094 118.183594 102.425781 118.183594 Z "/></g></svg>
                                            </div>
                                            <input v-model="form.password" type="password" id="input-group-1" class="bg-[#181A20] border border-white text-gray-400 text-sm rounded-lg focus:ring-[#FF0000] focus:border-[#FF0000] block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#FF0000] dark:focus:border-[#FF0000] focus:outline-none" :placeholder="$t('motdepasse')">
                                        </div>
                                    </div>
                                    <!--confirm-->
                                    <div class="relative w-full ">
                                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg class="w-[1.20rem] h-[1.20rem] text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 208 208" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:nonzero;fill:#959595;fill-opacity:1;" d="M 102.425781 0 C 76.234375 0 55.152344 21.082031 55.152344 47.273438 L 55.152344 78.789063 C 37.824219 78.789063 23.636719 92.976563 23.636719 110.304688 L 23.636719 173.332031 C 23.636719 190.660156 37.824219 204.847656 55.152344 204.847656 L 149.695313 204.847656 C 167.023438 204.847656 181.210938 190.660156 181.210938 173.332031 L 181.210938 110.304688 C 181.210938 92.976563 167.023438 78.789063 149.695313 78.789063 L 149.695313 47.273438 C 149.695313 21.082031 128.613281 0 102.425781 0 Z M 102.425781 15.757813 C 120.367188 15.757813 133.9375 29.328125 133.9375 47.273438 L 133.9375 78.789063 L 70.910156 78.789063 L 70.910156 47.273438 C 70.910156 29.328125 84.480469 15.757813 102.425781 15.757813 Z M 102.425781 118.183594 C 111.101563 118.183594 118.183594 125.261719 118.183594 133.9375 C 118.183594 139.449219 115.042969 144.863281 110.304688 147.234375 L 110.304688 165.453125 C 110.304688 170.195313 107.164063 173.332031 102.425781 173.332031 C 97.683594 173.332031 94.546875 170.195313 94.546875 165.453125 L 94.546875 147.234375 C 89.804688 144.863281 86.667969 139.449219 86.667969 133.9375 C 86.667969 125.261719 93.746094 118.183594 102.425781 118.183594 Z "/></g></svg>
                                        </div>
                                        <input v-model="form.password_confirmation" type="password" id="input-group-1" class="bg-[#181A20] border border-white text-gray-400 text-sm rounded-lg focus:ring-[#FF0000] focus:border-[#FF0000] block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#FF0000] dark:focus:border-[#FF0000] focus:outline-none" :placeholder="$t('confirmermotdepasse')">
                                    </div>
                                </div>
                                <div class="w-full">
                                    <div class=" w-full flex">
                                        <button v-if="loading == false"  class=" bg-[#FF0000] rounded-lg text-lg hover:border-2 hover:border-[#FF0000] hover:bg-transparent text-white w-40 mx-auto h-10 text-center">{{ $t('buttoninscription') }}</button>
                                        <div v-if="loading == true" class="
                                            rounded-lg
                                            flex
                                            items-center
                                            justify-center
                                            border-[#FF0000]
                                            border-2
                                            text-white
                                            w-40
                                            mx-auto
                                            h-10
                                            text-center">
                                        <svg aria-hidden="true" class="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-white fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                        </svg>
                                        </div>
                                    </div>
                                    <div class="w-full flex justify-center mt-6">
                                        <p class="text-gray-500 text-xs md:text-base">
                                        {{ $t('messagecompteexistant') }}
                                        <router-link to="/"><span class="text-[#FF0000] underline">{{ $t('cliquecreationcompte') }}</span></router-link>
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="w-full h-[30rem] bg-black  opacity-80 rounded-xl"></div>
                    </div>
                </div>
                <div class=" bg-[#181A20] opacity-30 w-full h-full"></div>
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
//import stripeComponent from '@/components/StripeComponent.vue';
export default {
    data(){
        return{
            form: { 
                name:"",
                email: "",
                password: "",
                password_confirmation:"",
                code: '',
            },
            loading: false,
            loadingcode: false,
            code: '',
            erreurs:'',
            errorpassword:'',
            hidcode:false,
            loadingnewcode: false,
            valideSend:'',
            compteCreate: false,
            errorcode:'',
            hidpaiement:false,
        }
    },

    watch: {
        code: {
        handler(newValue) {
            if(newValue.length > 0){
                this.loadingcode = true
                if(newValue == this.form.code){
                    this.axios.post(this.requestHttp + 'register', this.form).then(({ data }) => {
                        localStorage.setItem('jwtToken', data.access_token.token) 
                        localStorage.setItem('userid', data.user.id) 
                        localStorage.setItem('cadeau', 'bonus')
                        this.hidcode = false
                        this.$router.push('/allpaiement')
                    })
                    .catch()
                }else{
                    this.errorcode = 'incorrect'
                }
            }else{
                this.loadingcode = false
                this.errorcode = ''
            } 
        },
        deep: true
        }
    },

    mounted(){
        if(localStorage.getItem('hidpaiement')){
            this.hidpaiement = true
        }
        if(localStorage.getItem('langue') == null || localStorage.getItem('langue') == '' || localStorage.getItem('langue') == false){
            localStorage.setItem('langue', 'all')
        }

        if(localStorage.getItem('langue') != 'en'){
            this.$i18n.locale = 'fr'
        }else{
            this.$i18n.locale = 'en'
        }
    },
    methods: {
    closePaiement(){
        this.hidpaiement = false
        localStorage.removeItem('hidpaiement')
        this.form.code = ''
    },
    async sendMail() {
        if(this.form.password != this.form.password_confirmation){
            this.errorpassword = 'password no match'
            this.erreurs = ''
        }else{
            if(this.form.password.length < 8){
               this.errorpassword = 'password no 8'
               this.erreurs = ''
            }else{
                if(this.form.password != (this.form.password[0].toUpperCase() +  this.form.password.slice(1))){
                    this.errorpassword = 'uppercase password'
                    this.erreurs = ''
                }else{
                    this.loading = true;
                    this.form.code =Math.floor(Math.random()*889799) + 110200
                    await this.axios.post(this.requestHttp + 'send-email', this.form).then(() => {
                        this.loading = false;
                        this.hidcode = true
                    })
                    .catch((error) => {
                        this.errorpassword = ''
                        this.erreurs = error.response.data;
                        this.loading = false;
                        if(error.response.data.errors[0]){
                        this.erreurs = error.response.data.errors[0]
                        }
                    });
                }
            }
        }
        
    },
    // confirmeRegister(){
    //     if(this.code.length > 0){
    //         this.loadingcode = true
    //         if(this.code == this.form.code){
    //            this.axios.post(this.requestHttp + 'register', this.form).then(({ data }) => {
    //                 localStorage.setItem('jwtToken', data.access_token.token)
    //                 this.$router.push('/accueil')
    //                 this.hidcode = true
    //             })
    //             .catch()
    //         }else{
    //             this.errorcode = 'incorrect'
    //         }
    //     }else{
    //         this.loadingcode = false
    //         this.errorcode = ''
    //     } 
    // },
    newCode(){
      this.loadingnewcode = true
      this.form.code = Math.floor(Math.random()*889799) + 110200
      this.axios.post(this.requestHttp + 'send-email', this.form).then(response=>{ 
        this.userId = response.data.id
        this.valideSend = true
        setTimeout(() => {
              this.valideSend = false
              this.loadingnewcode = false
        }, 5000);
      }).catch()
    }
  },
  computed:{
    requestHttp(){
      return this.$store.state.api
    }
  }
}
</script>

<style>

</style>