<template>
  <div>
    <div class="px-4 h-screen flex items-center bg-center  bg-cover bg-no-repeat" style="background-image:url('/images/v1030-070.jpg');">
      <div
        class="lg:w-2/3 md:w-1/2 w-full h-auto mx-auto bg-white flex items-center xl:px-12 px-4 md:py-12 rounded-md relative first:bg-center  bg-cover bg-no-repeat" style="background-image:url('/images/3312919.jpg');"
      >
        <div>
            <button @click="goAccueil">
                <svg
                    class="cursor-pointer absolute right-0 top-0  md:right-4 md:top-6 z-10"
                    width="36"
                    height="36"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.28033 6.21967C6.98744 5.92678 6.51256 5.92678 6.21967 6.21967C5.92678 6.51256 5.92678 6.98744 6.21967 7.28033L10.9393 12L6.21967 16.7197C5.92678 17.0126 5.92678 17.4874 6.21967 17.7803C6.51256 18.0732 6.98744 18.0732 7.28033 17.7803L12 13.0607L16.7197 17.7803C17.0126 18.0732 17.4874 18.0732 17.7803 17.7803C18.0732 17.4874 18.0732 17.0126 17.7803 16.7197L13.0607 12L17.7803 7.28033C18.0732 6.98744 18.0732 6.51256 17.7803 6.21967C17.4874 5.92678 17.0126 5.92678 16.7197 6.21967L12 10.9393L7.28033 6.21967Z"
                    fill="red"
                    />
                </svg>
            </button>
          
          <div
            class="flex lg:flex-row md:flex-col-reverse  flex-col-reverse justify-center items-center gap-8"
          >
            <div class="w-full h-full flex items-center">
              <img class="object-cover object-center mt-3 mx-auto" src="/images/1500.png" alt="">
            </div>
            <div class="w-full">
              <p v-if="bonus == 'bonus'" class=" text-xs md:text-base text-center font-semibold text-green-600">{{ $t('messagebonus') }}.</p>
              <p class="text-2xl font-semibold text-gray-800">{{ $t('messagepaiement') }}.</p>
              <p class="text-base text-gray-600 mt-4">
                {{ $t('acheterunabonnementmensuel') }}
              </p>
              <div class="mt-10">
                <div>
                    <stripe-component></stripe-component>
                    <paypal-component></paypal-component>
                    <campay-component></campay-component>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import paypalComponent from '@/components/PayPalComponent.vue';
import campayComponent from '@/components/CampayComponent.vue';
import stripeComponent from '@/components/StripeComponent.vue';
export default {
    components: {
        paypalComponent,
        campayComponent,
        stripeComponent
    },
    data(){
        return{
            bonus:'',
        }
    },
    mounted(){
      if(localStorage.getItem('jwtToken') == null || localStorage.getItem('jwtToken') == '' || localStorage.getItem('jwtToken') == false){
        this.$router.push('/');
      }
      this.bonus = localStorage.getItem('cadeau')
      localStorage.removeItem('cadeau')
    },
    methods:{
        goAccueil(){
            this.$router.push('/accueil')
        }
    }
}
</script>

<style>

</style>