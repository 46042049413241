<template>
  <div class="bg-[#181A20] ">
    <div v-if="this.load == true" class="w-full h-screen bg-[#161617] overflow-hidden relative">
      <div class="w-full h-full bg-gradient-to-r from-transparent absolute via-gray-600 opacity-60 animate-[shimmer_1.5s_infinite] "></div>
    </div>
    <div v-if="this.errors != 'aucune' && this.errors.message == 'Unauthenticated.'">
      <div class="w-full h-screen bg-[#181A20] flex items-center">
        <div class="md:w-4/6 w-full mx-auto space-y-5">
          <img v-if="langauth != 'en'" class="mx-auto" src="/images/authfrench.png" alt="" srcset="">
          <img v-if="langauth == 'en'" class="mx-auto" src="/images/authenglish.png" alt="" srcset="">
          <p class="text-white text-center">
            {{ $t('erreurauth') }}
          </p>
          <div class="w-full h-12 flex">
            <button type="button" @click="returnLogin" class="text-white bg-[#FF0000] px-4 py-3 rounded-lg mx-auto hover:bg-transparent hover:border-[1px] hover:border-[#FF0000] hover:text-white text-xs md:text-base">{{ $t('returnconnexion') }}</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.errors != 'aucune' && this.errors.message == 'abonnement termine'">
      <div class="w-full h-screen bg-[#181A20] flex items-center">
        <div class="md:w-4/6 w-full mx-auto space-y-2">
          <img v-if="langauth != 'en'" class="mx-auto" src="/images/ArtboardFrench.png" alt="" srcset="">
          <img v-if="langauth == 'en'" class="mx-auto" src="/images/ArtboardEnglish.png" alt="" srcset="">
          <div>
            <p class="text-white text-center">
              {{ $t('bonjour') }} {{ this.errors.name }}.
            </p>
            <p v-if="this.errors.secondaire == 0" class="text-white text-center">
              {{ $t('abonnementtermine') }}
            </p>
            <p v-if="this.errors.secondaire == 1" class="text-white text-center">
              {{ $t('abonnementterminesecond') }}
            </p>
          </div>
          
          <div class="w-full h-12 flex justify-center">
            <div class=" space-x-2 mx-auto flex justify-center">
                <button type="button" @click="returnLogin" class="text-white bg-[#FF0000] px-4 py-3 rounded-lg mx-auto hover:bg-transparent hover:border-[1px] hover:border-[#FF0000] hover:text-white text-xs md:text-base">{{ $t('returnconnexion') }}</button>
                <button v-if="this.errors.secondaire == 0" type="button" @click="allPaiement" class="text-white bg-green-500 px-4 py-3 rounded-lg mx-auto hover:bg-transparent hover:border-[1px] hover:border-green-500 hover:text-white text-xs md:text-base">{{ $t('buttonabonnement') }}</button>
                <button v-if="this.errors.secondaire == 1" type="button" @click="returnRegister" class="text-white bg-green-500 px-4 py-3 rounded-lg mx-auto hover:bg-transparent hover:border-[1px] hover:border-green-500 hover:text-white text-xs md:text-base">{{ $t('buttonabonnement') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.errors == 'aucune' && this.load == false" class="relative ">
        <!--Search component-->
        <div class="absolute" v-if="hidsearch">
          <search-component/>
        </div>
        <!--Favoris component-->
        <div class="absolute" v-if="hidfavoris">
          <favoris-component></favoris-component>
        </div>
        <!--Create sub account component-->
        <div v-if="hidcreatesubaccount == true" class="absolute" >
          <create-sub-account-component></create-sub-account-component>
        </div>
        <!--Open change password component-->
        <div v-if="hidchangepassword == true" class="absolute" >
          <changer-mdp-component></changer-mdp-component>
        </div>
        <!--Open sub account component-->
        <div v-if="hidsubaccount == true" class="absolute" >
          <mes-sous-comptes-component></mes-sous-comptes-component>
        </div>
        <!--Detail component-->
        <div class="absolute" v-if="hiddetail"> 
          <detail-page-component :film-detail="film"></detail-page-component>
        </div>
        <div class=" relative w-full">
            <div class="absolute w-full h-[3rem] z-20 flex">
                <div class="w-full fixed">
                    <div class="w-full relative">
                        <nav  class="w-full h-[3rem] header bg-gradient-to-b from-[#181A20] to-transparent absolute z-10 flex justify-between">
                            <div class="h-full flex items-center space-x-4 ">
                                <transition name="bounce" mode="out-in">
                                  <button v-if="hidmenu == false"  type="button"><img @click="showHidMenu" class="h-[1.5rem]" src="/icons/menu_filled_100px.png" alt="" srcset=""></button>
                                  <button v-else-if="hidmenu == true" type="button"><img @click="showHidMenu" class="h-[1.5rem]" src="/icons/left_480px.png" alt="" srcset=""></button>
                                </transition>
                                <router-link to="/accueil"><img class="h-[1.7rem]" src="/logo/safarilogowebfondnoir-removebg-preview.png" ></router-link>
                            </div>
                            <div class=" w-[11rem] relative h-full ">
                                <div class=" h-full absolute flex right-0  md:space-x-4 space-x-2">
                                    <div class="h-full w-6">
                                      <button @click="openRecherche" type="button" class="w-full h-full">
                                          <img class=" w-5 h-5 mx-auto" src="/icons/search_64px.png" alt="" srcset="">
                                      </button>
                                    </div>
                                    <div @click="showHidLangue" class="h-full">
                                        <button  v-if="langue=='all'" type="button" class=" flex items-center h-full space-x-1">
                                            <img class="w-4 h-4 " src="/icons/alllanguage.png" alt="" srcset="">
                                            <p class=" text-white font-semibold text-sm ">F/A</p>
                                            <img class="w-4 h-4" src="/icons/sort_down_48px.png" alt="" srcset="">
                                        </button>
                                        <button  v-if="langue=='fr'" type="button" class=" flex items-center h-full space-x-1">
                                            <img class="w-5 h-5 " src="/icons/france_480px.png" alt="" srcset="">
                                            <p class=" text-white font-semibold text-sm ">FR</p>
                                            <img class="w-4 h-4" src="/icons/sort_down_48px.png" alt="" srcset="">
                                        </button>
                                        <button  v-if="langue=='en'" type="button" class=" flex items-center h-full space-x-1">
                                            <img class="w-5 h-5 " src="/icons/england_480px.png" alt="" srcset="">
                                            <p class=" text-white font-semibold text-sm ">EN</p>
                                            <img class="w-4 h-4" src="/icons/sort_down_48px.png" alt="" srcset="">
                                        </button>
                                    </div>
                                        <div v-if="notificationnonlus" class="h-full w-7 mt-[0.1rem]">
                                            <div @click="showHidNotification" class="h-full flex items-center relative">
                                                <div v-if="notificationnonlus > 0" class=" absolute right-0">
                                                    <div class="w-4 h-4 rounded-full bg-[#FF0000] flex mt-2 justify-center items-center text-center">
                                                        <p v-if="notificationnonlus <= 99" class=" h-5/6 text-white text-[9px] ">{{ notificationnonlus }}</p>
                                                        <p v-if="notificationnonlus > 99" class=" h-5/6 text-white text-[7px] ">+99</p>
                                                    </div>
                                                </div>
                                                <button>
                                                    <img class="h-6" src="/icons/bell.png" alt="" srcset="">
                                                </button>
                                            </div>
                                        </div>
                                        <div v-if="notificationnonlus == ''" class="h-full mt-[0.1rem]">
                                            <div @click="showHidNotification" class="h-full flex items-center relative">
                                                <button>
                                                    <img class="h-6" src="/icons/bell.png" alt="" srcset="">
                                                </button>
                                            </div>
                                        </div>
                                        <div class="h-full flex items-center">
                                            <div v-if="avatar" class="w-8 h-8 rounded-full bg-black  overflow-hidden">
                                                <img v-if="avatar.avatar == null || avatar.avatar == ''"  class="w-full h-full object-contain object-center" src="/logo/safarilogowebfondnoir-removebg-preview.png" alt="" srcset="">
                                                <img v-if="avatar.avatar" class="w-full h-full object-cover object-center" :src="avatar.avatar" alt="" srcset="">
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </nav>
                        <transition name="slide-fade">
                          <div v-if="hidmenu || hidlangue || hidnotification" class="w-full relative h-screen">
                              <!--menu-->
                              <div v-if="hidmenu"  class="w-full h-full relative">
                                  <div @click="showHidMenu" class="w-full h-full absolute backdrop-blur-sm "></div>
                                  <div class="bg-[#20232b] absolute w-[15rem] flex md:w-[20rem] h-full">
                                    <menu-component></menu-component>
                                  </div>
                              </div>
                              <div class="w-full relative">
                                  <div @click=" hidlangue = false" v-if="hidnotification == false" class="w-full h-screen absolute"></div>
                                  <div @click="showHidNotification" v-if="hidlangue == false" class="w-full h-screen absolute"></div>
                                  <div class="w-full flex justify-end">
                                    <div class="relative w-64 ">
                                        <!--changer langue-->
                                        <div  v-if="hidlangue == true"  class=" absolute left-[4rem] shadow-2xl top-11 bg-[#20232b]">
                                          <button v-if="langue!='all'" @click='langue = "all"; changeLangue(); notificationNonLu()' class="w-full flex px-3 py-1 justify-center hover:bg-[#181A20] space-x-2 items-center">
                                              <img class="w-[0.8rem] h-[0.8rem] mt-1" src="/icons/alllanguage.png" alt="" srcset="">
                                              <p class="text-white">All/Tous</p>
                                          </button>
                                          <button v-if="langue!='fr'" @click='this.langue = "fr"; changeLangue(); notificationNonLu()' class="w-full flex px-3 py-1 justify-center hover:bg-[#181A20] space-x-2 items-center">
                                              <img class="w-4 h-4 mt-1" src="/icons/france_480px.png" alt="" srcset="">
                                              <p class="text-white">Français</p>
                                          </button>
                                          <button v-if="langue!='en'" @click='langue = "en"; changeLangue(); notificationNonLu()' class="w-full flex px-3 py-1 justify-center hover:bg-[#181A20] space-x-3 items-center">
                                              <img class="w-4 h-4 mt-1" src="/icons/england_480px.png" alt="" srcset="">
                                              <p class="text-white">Anglais</p>
                                          </button>
                                        </div>
                                        <!--notification-->
                                        <div v-if="hidnotification == true" class="w-full h-[20rem] bg-[#181A20] mt-11 rounded-lg">
                                          <notification-component></notification-component>
                                        </div>
                                    </div>
                                  </div>
                                  
                              </div>
                          </div>
                        </transition>
                    </div>
                </div>
            </div>
            <!--publicite-->
            <router-view name="two"></router-view>
        </div>
    </div> 
  </div>
</template>

<script>
window.addEventListener('scroll', function(){
    let header = document.querySelector('.header');
    let windowPosition = window.scrollY>0;
    if(header){
      header.classList.toggle('scrolling-active', windowPosition);
    }
    
})
import notificationComponent from '@/components/NotificationComponent.vue';
import menuComponent from '@/components/MenuComponent.vue';
import searchComponent from '@/components/SearchComponent.vue';
import favorisComponent from '@/components/FavorisComponent.vue';
import detailPageComponent from '@/components/DetailPageComponent.vue';
import createSubAccountComponent from '@/components/CreateSubAccountComponent.vue';
import mesSousComptesComponent from '@/components/MesSousComptesComponent.vue';
import changerMdpComponent from '@/components/ChangerMdpComponent.vue';

export default {
  components: {
    notificationComponent,
    menuComponent,
    searchComponent,
    favorisComponent,
    detailPageComponent,
    createSubAccountComponent,
    mesSousComptesComponent,
    changerMdpComponent
  },
    data() {
    return {
      film:'',
      langue:'',
      errors:'aucune',
      load:true,
      background: false,
      langauth: localStorage.getItem('langue'),
      hidlangue: false,
      hidnotification: false,
      hidmenu: false,
      hidsearch:false,
      hidfavoris:false,
      hiddetail:false,
      hidcreatesubaccount:false,
      hidsubaccount:false,
      hidchangepassword:false,
      data:{},
      avatar:{},
      notificationnonlus:{},
      setIntervalNotification:'',
    };
  },
  watch: {
    getDataAvatar: {
      handler(newValue) {
        this.avatar = newValue[0]
      },
      deep: true
    },

    notificationnonlus: {
      handler(newValue) {
        if(parseInt(localStorage.getItem('notificationnonlus')) < newValue){
          localStorage.removeItem('notificationnonlus')  
          localStorage.setItem('notificationnonlus', newValue) 
          this.$store.dispatch('requestRefreshAccueil')
          this.$store.dispatch('requestFavoris')
          this.$store.dispatch('requestSearch')
        }
      },
      deep: true
    },
  },
  created () {
    //this.setIntervalAvatar = setInterval(this.refresh, 500);
    this.setIntervalNotification = setInterval(this.notificationNonLu, 60000);

    window.emitter.on("menuEventRecherche", VarGetDataAccueil => {
        this.hidsearch = VarGetDataAccueil
        this.hidfavoris = false
        this.hidmenu = false
        this.hidcreatesubaccount = false
        this.hidsubaccount = false
        this.hidchangepassword = false
    })

    window.emitter.on("menuEventFavoris", VarGetDataAccueil => {
        this.hidcreatesubaccount = false
        this.hidfavoris = VarGetDataAccueil
        this.hidsearch = false
        this.hidmenu = false
        this.hidsubaccount = false
        this.hidchangepassword = false
    })

    window.emitter.on("menuEventCreateSubAccount", VarGetDataAccueil => {
        this.hidcreatesubaccount = VarGetDataAccueil
        this.hidfavoris = false
        this.hidsearch = false
        this.hidmenu = false
        this.hidsubaccount = false
        this.hidchangepassword = false
    })

    window.emitter.on("menuEventOpenSubAccount", VarGetDataAccueil => {
        this.hidsubaccount = VarGetDataAccueil
        this.hidfavoris = false
        this.hidsearch = false
        this.hidmenu = false
        this.hidcreatesubaccount = false
        this.hidchangepassword = false
    })

    window.emitter.on("menuEventOpenChangePassword", VarGetDataAccueil => {
        this.hidchangepassword = VarGetDataAccueil
        this.hidsubaccount = false
        this.hidfavoris = false
        this.hidsearch = false
        this.hidmenu = false
        this.hidcreatesubaccount = false
    })

    window.emitter.on("menuEventLogout", VarGetDataAccueil => {
        if(VarGetDataAccueil == true){
          clearInterval(this.setIntervalNotification);
        }
    })

    window.emitter.on("EventDetailPage", VarGetDataDetailPage => {
        this.film = VarGetDataDetailPage
        this.hidsearch = false
        this.hidfavoris = false
        this.hidmenu = false
        this.hiddetail = false
        if(VarGetDataDetailPage != false){
          this.hiddetail = true
        }else{
          this.hiddetail = false
        }
        
    })
  },
  async mounted(){
    this.connection()
    if(localStorage.getItem('openpage') == 'detail'){
      this.hiddetail = true
      localStorage.removeItem('openpage')
      localStorage.removeItem('oldpage')
    }
    this.background = true
    if(localStorage.getItem('jwtToken') == null || localStorage.getItem('jwtToken') == '' || localStorage.getItem('jwtToken') == false){
      this.$router.push('/');
    }
    if(localStorage.getItem('langue') == null || localStorage.getItem('langue') == '' || localStorage.getItem('langue') == false){
      localStorage.setItem('langue', 'all')
    }

    if(localStorage.getItem('langue') != 'en'){
        this.$i18n.locale = 'fr'
    }else{
      this.$i18n.locale = 'en'
    }
    this.setDataAvatar
    if(localStorage.getItem('langue')){
      this.langue = localStorage.getItem('langue');
    } 
    await this.axios.get(this.requestHttp + 'notificationnonlu/' + localStorage.getItem('langue'), {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
      }).then(({data}) => {
        this.background = false
        this.errors = 'aucune'
        this.load = false
        this.notificationnonlus = data   
        localStorage.setItem('notificationnonlus', data)  
      })
    .catch(error=> {
        this.background = false
        this.errors= error.response.data
        console.log(this.errors.message)
        this.load = false
    }) 
  },
 
  methods:{
    async connection(){
      var fileURL = "https://eu2.contabostorage.com/598488fd1b1545ac9c256e21ccd1a4cd:safarimovies/Group 1.png";
      
      var request = new XMLHttpRequest();
      var avoidCache = "?avoidcache=" + (new Date()).getTime();
      request.open('GET', fileURL + avoidCache, true);
      request.responseType = "application/*";
      var startTime = (new Date()).getTime();
      var endTime = startTime;
      request.onreadystatechange = function () {
          if (request.readyState == 2)
          {
              //ready state 2 is when the request is sent
              startTime = (new Date().getTime());
          }
          if (request.readyState == 4)
          {
              endTime = (new Date()).getTime();
              var downloadSize = request.responseText.length;
              var time = (endTime - startTime) / 1000;
              var sizeInBits = downloadSize * 8;
              var speed = ((sizeInBits / time) / (1024 * 1024)).toFixed(2);
              console.log(downloadSize, time, speed);
              console.log(speed);
              if(speed <= 3){
                localStorage.setItem('defaultqualite','240p')
              }else{
                if(speed >= 3 && speed < 4){
                  localStorage.setItem('defaultqualite','480p')
                }else{
                  if(speed >= 4 && speed < 5){
                    localStorage.setItem('defaultqualite','720p')
                  }else{
                    localStorage.setItem('defaultqualite','1080p')
                  }
                }
              }
          }
      }

      request.send();

    },
    allPaiement(){ 
      clearInterval(this.setIntervalNotification);
      this.$router.push('/allpaiement');
    },
    returnRegister(){ 
      clearInterval(this.setIntervalNotification);
      localStorage.removeItem('jwtToken')
      this.$router.push('/register');
    }, 
    returnLogin(){ 
      clearInterval(this.setIntervalNotification);
      localStorage.removeItem('jwtToken')
      this.$router.push('/');
    },  
    // refresh(){
    //   if(this.getDataAvatar.length > 0){
    //     this.avatar = this.getDataAvatar[0]
    //     clearInterval(this.setIntervalAvatar);
    //   }
    // },
    async notificationNonLu(){
      await this.axios.get(this.requestHttp + 'notificationnonlu/' + this.langue, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
          }).then(({data}) => {
            this.notificationnonlus = data
            this.errors = 'aucune'
         })
      .catch(error=> {
        if(error){
          this.errors= error.response.data
        }
    }) 
    },
    showHidNotification(){
      if(this.hidnotification == true){
        this.hidnotification = false

        this.axios.get(this.requestHttp + 'readnotification/' + localStorage.getItem('langue'),{
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        }).then(() => {
            this.notificationnonlus = 0
            localStorage.removeItem('notificationnonlus')  
            localStorage.setItem('notificationnonlus', 0)  
        })
        .catch()
      }else{
        this.hidnotification = true
        this.hidlangue= false
      }
    },
    showHidMenu(){
      if(this.hidmenu == true){
        this.hidmenu = false
      }else{
        if(this.avatar != null){
          this.hidmenu = true
          this.hidlangue= false
          this.hidnotification = false
        }
        
      }
    },

    showHidLangue(){
      if(this.hidlangue == true){
        this.hidlangue = false
      }else{
        this.hidlangue = true
        this.hidnotification = false
      }
    },

    changeLangue(){
      this.hidlangue = false
      localStorage.setItem('langue', this.langue)
      this.axios.get(this.requestHttp + 'accueil/' + this.langue, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
        }).then(({data}) => {
          if(localStorage.getItem('langue') != 'en'){
            this.$i18n.locale = 'fr'
          }else{
            this.$i18n.locale = 'en'
          }
          this.data = data  
          this.setDataAccueil
          // window.emitter.emit("accueilEvent")      
        })
      .catch()
      this.$store.dispatch('requestSearch')
    },
    openRecherche(){
      window.emitter.emit("menuEventRecherche", true) 
    },
  },

  computed:{
    requestHttp(){
      return this.$store.state.api
    },
    setDataAccueil(){
        return this.$store.dispatch('requestAccueil', this.data)
    },
    setRefreshDataAccueil(){
        return this.$store.dispatch('requestRefreshAccueil')
    },
    setDataAvatar(){
        return this.$store.dispatch('requestAvatar')
    },
    getDataAvatar(){
        return this.$store.state.dataAvatar
    },
    getDataAccueil(){
        return this.$store.state.dataAccueil
    },
    setDataFavoris(){
        return this.$store.dispatch('requestFavoris')
    },
    setDataSearch(){
        return this.$store.dispatch('requestSearch')
    },
  }
}
</script>

<style>
   .scrolling-active{
      background-color: #181A20;
    }

    .slide-fade-enter-active {
      transition: all 0.8s ease-out;
    }
    
    .slide-fade-leave-active {
      transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }
    
    .slide-fade-enter-from,
    .slide-fade-leave-to {
      transform: translateX(-20px);
      opacity: 0;
    }

    .bounce-enter-active {
      animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
      animation: bounce-in 0.5s reverse;
    }
    @keyframes bounce-in {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1.25);
      }
      100% {
        transform: scale(1);
      }
    }
     
</style>