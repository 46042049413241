<template>
    <div class="w-full h-screen flex items-center ">
        <button @click="closeRecherche" class="fixed top-0 z-50">
            <img class="w-8 h-8 md:w-9 md:h-9 flex-none md:ml-2 ml-4 mt-4 md:mt-2  md:relative transform hover:scale-110 motion-reduce:transform-none hover:z-10 transition duration-1000 " src="/icons/delete_sign_red.png" alt="" srcset="">
        </button>
        <div class=" w-[95%] md:w-[73%] h-[97%] mt-3 bg-[#181A20] border-[#292929] border-2 rounded-md fixed right-0 left-0 m-auto z-40">
            <!--Bar de recherche-->
            <div class="w-full h-[8%] text-white  flex items-center">
                <form @submit.prevent="searchFilm" class="w-[80%] h-10 flex mx-auto group">
                    <input @keyup="refershData"  v-model="mot" class=" w-[90%] bg-[#181A20] border-b-2 border-white placeholder-gray-500 px-3 focus:outline-none peer focus:border-[#FF0000] rounded-none" type="text" :placeholder="$t('recherchez') +'...'" name="" id="">
                    <div class="w-[10%] h-auto bg-[#181A20] border-b-2 border-white peer-focus:border-[#FF0000] flex items-center">
                        <button @click="searchFilm" type="button" class="w-full h-full">
                            <img class=" w-6 h-6 mx-auto" src="/icons/search_64px.png" alt="" srcset="">
                        </button>
                    </div>
                </form>
            </div>
            <!--listes-->
            <div class="w-full h-[92%] 2xl:h-[98%]  flex">
                <div class="w-full h-full relative">
                    <div v-if="loadSearch" class=" w-full h-full flex absolute">
                        <div class="w-full h-full rounded-xl mx-auto z-10 relative">
                                <div class="w-full h-full absolute flex items-center">
                                    <div class="mx-auto z-10 relative">
                                        <div class="w-16 h-16 absolute flex items-center">
                                            <img class="w-6 h-6 mx-auto" src="/icons/iconsafaripreview.png" alt="" srcset="">
                                        </div>
                                        <div class="rounded-lg text-white">
                                            <svg aria-hidden="true" class="inline w-16 h-16 text-white animate-spin fill-[#FF0000]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                            </svg>
                                        </div>
                                    </div>   
                                </div>
                                <div class="w-full h-full bg-[#181A20] rounded-xl opacity-80"></div>
                        </div> 
                    </div>
                    <div class="w-full h-[90%] overflow-hidden overflow-y-auto scrollbar-hide flex">
                        <div v-if="datas.length > 0" class=" w-full md:w-[92%] mx-auto h-auto flex flex-wrap md:px-2 justify-center overflow-hidden overflow-y-auto scrollbar-hide ">
                            <div id="app" v-for="data in datas" :key="data.id" class="w-40 h-56  bg-[#292929]  ml-2 mt-2 overflow-hidden transform hover:scale-110 motion-reduce:transform-none hover:z-10 transition duration-1000">
                                <button @click="openDetailPage(data)" class="w-full h-full" type="button">
                                    <img :src="data.image" class="w-full h-full object-center" alt="" srcset="">
                                </button>
                            </div>
                            <infinite-loading v-if="hidScrool == false" :class="fin ? ['absolute','bottom-6','2xl:bottom-11']: 'invisible'"  spinner="spinner" @infinite="infiniteHandler"  class=" text-white text-base  h-3">
                                <template v-slot:spinner v-if="fin==true">
                                    <p v-if="langue == 'fr' || langue == 'all'">Plus aucun éléments !</p>
                                    <p v-if="langue == 'en'">No more elements !</p>
                                </template>
                            </infinite-loading>
                        </div>
                        <!--aucun resultat-->
                        <div v-if="datas.length == 0" class="w-full h-full flex items-center">
                            <div class="mx-auto text-center">
                                <img class="h-[11rem] mx-auto" src="/icons/boring_480px.png" alt="" srcset="">
                                <p class="text-white">{{ $t('aucunresultat') }}</p>
                                <p class="text-gray-500 mt-2">{{ $t('messagerecherche') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div @click="closeRecherche" class="w-full h-screen backdrop-blur-sm  z-30 flex fixed"></div>
    </div>
</template>

<script>
import InfiniteLoading from "v3-infinite-loading/";
import "v3-infinite-loading/lib/style.css";
export default {
    components: {
        InfiniteLoading,
    },
    data(){
        return{
            datas: [],
            page:'',
            fin: false,
            langue: localStorage.getItem('langue'),
            mot:'',
            loadSearch:false,
            hidScrool:false,
            formMot:{
                nom:''
            }
        }
    },

    mounted(){
        this.datas = this.getDataRecherche
        this.page = Math.ceil(this.getDataRecherche.length/30 + 1)
    },
    methods:{
        closeRecherche(){
            window.emitter.emit("menuEventRecherche", false) 
        },
        openDetailPage(data){
            window.emitter.emit("EventDetailPage", data)
            localStorage.removeItem('detail')
        },
        infiniteHandler($state){
            if(this.page == 1){
                console.log(this.page)
            }else{
                this.axios.get(this.requestHttp + 'search/' + localStorage.getItem('langue') +'?page=' + this.page, {
               headers: {
               'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
               },
            }).then(({data}) => {
                if(data.data.length > 0){
                    this.page += 1
                    this.datas.push(...data.data)
                    this.setDataSearch
                    console.log($state)
                    $state.loading()
                }else{
                    this.fin = true
                }        
            })
            .catch() 
            }
            
        },
        refershData(){
            if(this.mot.length == 0){
                this.datas = this.getDataRecherche
                this.hidScrool = false
            }
        },
        searchFilm(){
            if(this.mot.length > 0){
                this.loadSearch = true
                this.axios.get(this.requestHttp + 'search/' + localStorage.getItem('langue') +'/' + this.mot, {
                headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                },
                }).then(({data}) => {
                    this.hidScrool = true
                    this.datas = data 
                    this.loadSearch = false
                    console.log(data) 
                    if(data.length == 0){
                        this.formMot.nom = this.mot
                        this.axios.post(this.requestHttp + 'savesearch',this.formMot,{
                            headers: {
                            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                            },
                        }).then(() => {})
                        .catch()
                    }        
                })
                .catch()
            }  
        },
    },
    computed:{
        requestHttp(){
            return this.$store.state.api
        },
        getDataRecherche(){
            return this.$store.state.dataSearch
        },
        setDataSearch(){
            return this.$store.dispatch('requestSearch', this.datas)
        },
    }
}
</script>

<style>
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
</style>