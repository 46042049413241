<template>
    <div>
        <div v-if="this.films?.length > 0" class="w-full mt-12">
        <!--titre-->
            <div class="w-full flex">
                <p class="text-white font-bold text-xl ml-2  md:text-base xl:text-lg text-md">{{ $t('film') }}</p>
            </div>
            <!--Cards-->
            <div  class="px-2  h-[20rem] py-2 relative">
                <swiper
                    :slidesPerView="'auto'"
                    :spaceBetween= 30
                    :navigation="true"
                    :modules="modules"
                    class="mySwiper px-4 swiper_film"
                >
                    <swiper-slide v-for="film in films" :key="film.id" class="swiper-slide-film ">
                        <button @click="openDetailPage(film)"  type="button" class="w-44 h-72 transform hover:scale-110 motion-reduce:transform-none hover:z-auto transition duration-1000">
                            <div class=" w-full h-[80%] rounded overflow-hidden relative">
                                <div v-if="film[0].status_periodique == 'nouveau'" class="animatenew bg-[#FF0000] w-full h-6 absolute transform rotate-45 translate-y-5 translate-x-14 ">
                                    <div class="w-full xl:hidden h-full bg-gradient-to-r from-transparent absolute via-gray-200 opacity-60 animate-[shimmer_1.5s_infinite] "></div>
                                    <p  class="text-white text-xs font-bold mt-[0.1rem]">{{ $t('messagestatusfilm') }}</p>
                                </div>
                                <img class=" w-full h-full object-center object-cover" :src="film[0].image" :alt="film[0].titre" srcset="">
                            </div>
                            <div class=" space-y-1 h-[20%] py-2">
                                <p v-if="film[0].titre.length <= 17" class=" font-bold text-white text-left w-full h-1/2">{{ film[0].titre}}</p>
                                <p v-if="film[0].titre.length > 17" class=" font-bold text-white text-left h-1/2">{{ film[0].titre.substring(0,17) + '...' }}</p>
                                <div class="flex items-center h-1/2 space-x-1">
                                <p class="text-xs text-white text-left">{{ film[1][0]?.nom }}</p>
                                <div v-if="film[1].length > 1" class="h-full flex items-center">
                                    <div class="w-[0.3rem] h-[0.3rem] mt-[0.10rem] bg-white rounded-full"></div>
                                </div>
                                <p class="text-xs text-white">{{ film[1][1]?.nom }}</p>
                                <div v-if="film[1].length > 2" class="h-full flex items-center">
                                    <div class="w-[0.3rem] h-[0.3rem]  mt-[0.10rem] bg-white rounded-full"></div>
                                </div>
                                <p v-if="film[1].length > 2" class="text-xs text-white">{{ film[1][2]?.nom }}</p>
                                </div>
                            </div>
                        </button>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import {Pagination, Navigation} from "swiper";
export default {
    data(){
        return{
            films:{},
        }
    },
    watch: {
        getDataAccueil: {
            handler(newValue) {
                this.films= newValue[1]
            },
            deep: true
        },
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Navigation, Pagination],
        };
    },
    mounted(){
        if(this.getDataAccueil.length > 0){
            this.films = this.getDataAccueil[1]
        }
    },
    methods:{
        openDetailPage(data){
            window.emitter.emit("EventDetailPage", data)
            localStorage.removeItem('detail')
        },
    },
    computed:{
        getDataAccueil(){
            return this.$store.state.dataAccueil
        },
    }
}
</script>

<style>
    /* For Firefox */
    progress::-moz-progress-bar {
        background: #FF0000;
    }

    /* For Chrome or Safari */
    progress::-webkit-progress-value {
        background: #FF0000;
    }


    .swiper {
    width: 100%;
    height: auto;
    position: initial;
    }

    .swiper_film {
    padding-left: 1rem !important;
    padding-top: 2% !important;
    }

    
    .swiper-slide-film{
        width: 11rem !important; 
        height: 19rem !important;
    }
    
    .swiper-button-next{
        top:-2%;
        height: 2.5rem;
        border-radius: 9999px;
        width: 2.5rem;
        text-align: center;
        right: 5px;
        color: #ffffff;
        background: #ff00007a;
        display: none;
        align-items: center;
    }
    .swiper-button-prev{
        top:-2%;
        height: 2.5rem;
        border-radius: 9999px;
        width: 2.5rem;
        right:55px !important;
        left: inherit !important;
        color: #ffffff;
        background: #ff00007a;
        display: none;
    }
    .swiper-button-next::after{
        font-size: 15px !important;
        text-align: center;
        bottom: 0px;
    }
    .swiper-button-prev::after{
        font-size: 15px !important;
        text-align: center;
        bottom: 0px;
    }

    .swiper-button-next.swiper-button-disabled{
        display:none;
    }
    .swiper-button-prev.swiper-button-disabled{
        display:none;
    }


    @media (min-width: 1280px) {
    .swiper-button-next {
            display: flex;
        }
    .swiper-button-prev {
            display: flex;
        }

    @keyframes shine { 
        to { 
            background-position-x : -200% ; 
        } 
    }
    .animatenew{
        background: linear-gradient(110deg, #f26161 1%, #FF0000 10%, #FF0000 33%);
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
    }
    }
</style>