<template>
  <div class="bg-[#181A20]">
    <div class="w-full h-screen relative">
      <div v-if="hidForget == true" class="w-full h-screen backdrop-blur-sm absolute z-20">
            <div class="w-full h-screen relative">
                <div class=" absolute z-50 ml-2 mt-1">
                    <button type="button"><img @click="hidForget = false" class="h-8 " src="/icons/delete_sign_filled_100px.png" alt="" srcset=""></button>
                </div>
                <!--Email code forget-->
                <div v-if="hidEnterEmailCode == true" class="w-full h-full flex">
                    <div class=" w-full h-full relative">
                        <div class="w-full h-full flex absolute">
                            <div class="  xl:w-[25%]   lg:w-[45%] md:w-[50%] w-[90%]    mx-auto relative rounded-xl">
                        <!--Formulaire-->
                                <div class="w-full h-full absolute rounded-xl z-10">
                            <!--Titre-->
                                    <div class="w-full flex h-16 items-center">
                                        <p class=" text-white font-semibold mx-auto text-xs">{{ $t('emailcode') }}</p>
                                    </div>
                                    <!--Email/code-->
                                    <form @submit.prevent="sendMailForget" action="">
                                        <div class="w-full mt-1 px-3">
                                            <!--email code-->
                                            <div>
                                                <p v-if="emailNotFind == 'Email no exist'" class="text-[#FF0000] w-full text-xs">{{ $t('emailincorrect') }}</p>
                                                <div class="relative w-full ">
                                                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                        <svg
                                                        aria-hidden="true"
                                                        class="w-5 h-5 text-gray-500 dark:text-gray-400"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                                                        ></path>
                                                        <path
                                                          d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                                                        ></path>
                                                      </svg>
                                                    </div>
                                                    <input v-model="formForget.email" type="email"  id="input-group-1" class="bg-[#181A20] border border-white text-gray-400 text-sm rounded-lg focus:ring-[#FF0000] focus:border-[#FF0000] block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#FF0000] dark:focus:border-[#FF0000] focus:outline-none" :placeholder="$t('email')">
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="w-full flex mt-4">
                                          <button
                                            v-if="loadingSendMail == false"
                                            class="
                                              bg-[#FF0000]
                                              rounded-lg
                                              text-lg
                                              hover:border-2
                                              hover:border-[#FF0000]
                                              hover:bg-transparent
                                              text-white
                                              w-40
                                              mx-auto
                                              h-10
                                              text-center
                                            "
                                          >
                                            {{ $t('buttonemailcode') }}
                                          </button>
                                          <div v-if="loadingSendMail == true" class="
                                              rounded-lg
                                              flex
                                              items-center
                                              justify-center
                                              border-[#FF0000]
                                              border-2
                                              text-white
                                              w-40
                                              mx-auto
                                              h-10
                                              text-center">
                                            <svg aria-hidden="true" class="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-white fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                            </svg>
                                          </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="w-full h-[12rem] bg-black  opacity-80 rounded-xl"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--code forget-->
                <div v-if="hidEnterCode == true" class="w-full h-full flex">
                    <div class=" w-full h-full relative">
                        <div class="w-full h-full flex absolute">
                            <div class="  xl:w-[25%]   lg:w-[45%] md:w-[50%] w-[90%]    mx-auto relative rounded-xl">
                        <!--Formulaire-->
                                <div class="w-full h-full absolute rounded-xl z-10">
                            <!--Titre-->
                                    <div class="w-full flex h-16 items-center">
                                        <p class=" text-white text-xl font-semibold mx-auto">{{ $t('codeverification') }}</p>
                                    </div>
                                    <!--Email/code-->
                                    <form action="">
                                        <div class="w-full mt-1 px-3">
                                            <!--code-->
                                            <div>
                                                <p v-if="errorcode == 'incorrect'" class="text-[#FF0000] w-full text-xs">{{ $t('errorcode') }}</p>
                                                <div class="relative w-full ">
                                                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 208 208" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:nonzero;fill:#959595;fill-opacity:1;" d="M 102.425781 0 C 76.234375 0 55.152344 21.082031 55.152344 47.273438 L 55.152344 78.789063 C 37.824219 78.789063 23.636719 92.976563 23.636719 110.304688 L 23.636719 173.332031 C 23.636719 190.660156 37.824219 204.847656 55.152344 204.847656 L 149.695313 204.847656 C 167.023438 204.847656 181.210938 190.660156 181.210938 173.332031 L 181.210938 110.304688 C 181.210938 92.976563 167.023438 78.789063 149.695313 78.789063 L 70.910156 78.789063 L 70.910156 47.273438 C 70.910156 29.328125 84.480469 15.757813 102.425781 15.757813 C 120.367188 15.757813 133.9375 29.328125 133.9375 47.273438 L 133.9375 55.152344 L 149.695313 55.152344 L 149.695313 47.273438 C 149.695313 21.082031 128.613281 0 102.425781 0 Z M 102.425781 118.183594 C 111.101563 118.183594 118.183594 125.261719 118.183594 133.9375 C 118.183594 139.449219 115.042969 144.863281 110.304688 147.234375 L 110.304688 165.453125 C 110.304688 170.195313 107.164063 173.332031 102.425781 173.332031 C 97.683594 173.332031 94.546875 170.195313 94.546875 165.453125 L 94.546875 147.234375 C 89.804688 144.863281 86.667969 139.449219 86.667969 133.9375 C 86.667969 125.261719 93.746094 118.183594 102.425781 118.183594 Z "/></g></svg>
                                                    </div>
                                                    <input v-model="code" type="text" id="input-group-1" class="bg-[#181A20] border border-white text-gray-400 text-sm rounded-lg focus:ring-[#FF0000] focus:border-[#FF0000] block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#FF0000] dark:focus:border-[#FF0000] focus:outline-none" :placeholder="$t('messagecode')">
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div v-if="loadingnewcode == false && valideSend == false" class="w-full mt-2 flex">
                                          <button @click="newCode" class="mx-auto underline text-xs text-[#FF0000]">{{ $t('envoyeunnouveaucode') }}</button>
                                        </div>
                                        <div v-if="valideSend == true" class="w-full mt-2 flex">
                                          <p  class="mx-auto text-xs text-green-500 mt-2">{{ $t('validecodeenvoye') }}</p>
                                        </div>
                                        <div v-if="loadingnewcode == true && valideSend == false" class="
                                            rounded-lg
                                            flex
                                            items-center
                                            justify-center
                                            text-white
                                            w-40
                                            mx-auto
                                            h-10
                                            text-center">
                                            <svg aria-hidden="true" class="inline w-6 h-6 mr-2 text-[#FF0000] animate-spin dark:text-white fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                            </svg>
                                        </div>
                                    </form>
                                </div>
                                <div class="w-full h-[10rem] bg-black  opacity-80 rounded-xl"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Change password-->
                <div v-if="HidEnterChangePassword == true" class="w-full h-full flex">
                    <div class=" w-full h-full relative">
                        <div class="w-full h-full flex absolute">
                            <div class="  xl:w-[25%]   lg:w-[45%] md:w-[50%] w-[90%]    mx-auto relative rounded-xl">
                        <!--Formulaire-->
                                <div class="w-full h-full absolute rounded-xl z-10">
                            <!--Titre-->
                                    <div class="w-full flex h-16 items-center">
                                        <p class=" text-white text-xl font-semibold mx-auto">{{ $t('nouveaumotdepasse') }}</p>
                                    </div>
                                    <form action="">
                                        <div class="w-full mt-1 px-3">
                                            <!--password-->
                                            <div>
                                                <p v-if="errorpassword == 'password no match'" class="text-[#FF0000] w-full text-xs">{{ $t('mdpdifferentdemdpc') }}</p>
                                                <p v-if="errorpassword == 'password no 8'" class="text-[#FF0000] w-full text-xs">{{ $t('mdpdinferieurahuit') }}</p>
                                                <p v-if="errorpassword == 'uppercase password'" class="text-[#FF0000] w-full text-xs">{{ $t('mdpmajuscule') }}</p>
                                                <div class="relative w-full ">
                                                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                    <svg class="w-[1.20rem] h-[1.20rem] text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 208 208" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:nonzero;fill:#959595;fill-opacity:1;" d="M 102.425781 0 C 76.234375 0 55.152344 21.082031 55.152344 47.273438 L 55.152344 78.789063 C 37.824219 78.789063 23.636719 92.976563 23.636719 110.304688 L 23.636719 173.332031 C 23.636719 190.660156 37.824219 204.847656 55.152344 204.847656 L 149.695313 204.847656 C 167.023438 204.847656 181.210938 190.660156 181.210938 173.332031 L 181.210938 110.304688 C 181.210938 92.976563 167.023438 78.789063 149.695313 78.789063 L 149.695313 47.273438 C 149.695313 21.082031 128.613281 0 102.425781 0 Z M 102.425781 15.757813 C 120.367188 15.757813 133.9375 29.328125 133.9375 47.273438 L 133.9375 78.789063 L 70.910156 78.789063 L 70.910156 47.273438 C 70.910156 29.328125 84.480469 15.757813 102.425781 15.757813 Z M 102.425781 118.183594 C 111.101563 118.183594 118.183594 125.261719 118.183594 133.9375 C 118.183594 139.449219 115.042969 144.863281 110.304688 147.234375 L 110.304688 165.453125 C 110.304688 170.195313 107.164063 173.332031 102.425781 173.332031 C 97.683594 173.332031 94.546875 170.195313 94.546875 165.453125 L 94.546875 147.234375 C 89.804688 144.863281 86.667969 139.449219 86.667969 133.9375 C 86.667969 125.261719 93.746094 118.183594 102.425781 118.183594 Z "/></g></svg>
                                                    </div>
                                                    <input v-model="formNewPassword.password" type="password" id="input-group-1" class="bg-[#181A20] border border-white text-gray-400 text-sm rounded-lg focus:ring-[#FF0000] focus:border-[#FF0000] block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#FF0000] dark:focus:border-[#FF0000] focus:outline-none" :placeholder="$t('motdepasse')">
                                                </div>
                                            </div>
                                            <!--confirm-->
                                            <div class="relative w-full mt-4 ">
                                                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                <svg class="w-[1.20rem] h-[1.20rem] text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 208 208" version="1.1"><g id="surface1"><path style=" stroke:none;fill-rule:nonzero;fill:#959595;fill-opacity:1;" d="M 102.425781 0 C 76.234375 0 55.152344 21.082031 55.152344 47.273438 L 55.152344 78.789063 C 37.824219 78.789063 23.636719 92.976563 23.636719 110.304688 L 23.636719 173.332031 C 23.636719 190.660156 37.824219 204.847656 55.152344 204.847656 L 149.695313 204.847656 C 167.023438 204.847656 181.210938 190.660156 181.210938 173.332031 L 181.210938 110.304688 C 181.210938 92.976563 167.023438 78.789063 149.695313 78.789063 L 149.695313 47.273438 C 149.695313 21.082031 128.613281 0 102.425781 0 Z M 102.425781 15.757813 C 120.367188 15.757813 133.9375 29.328125 133.9375 47.273438 L 133.9375 78.789063 L 70.910156 78.789063 L 70.910156 47.273438 C 70.910156 29.328125 84.480469 15.757813 102.425781 15.757813 Z M 102.425781 118.183594 C 111.101563 118.183594 118.183594 125.261719 118.183594 133.9375 C 118.183594 139.449219 115.042969 144.863281 110.304688 147.234375 L 110.304688 165.453125 C 110.304688 170.195313 107.164063 173.332031 102.425781 173.332031 C 97.683594 173.332031 94.546875 170.195313 94.546875 165.453125 L 94.546875 147.234375 C 89.804688 144.863281 86.667969 139.449219 86.667969 133.9375 C 86.667969 125.261719 93.746094 118.183594 102.425781 118.183594 Z "/></g></svg>
                                                </div>
                                                <input v-model="formNewPassword.password_confirmation" type="password" id="input-group-1" class="bg-[#181A20] border border-white text-gray-400 text-sm rounded-lg focus:ring-[#FF0000] focus:border-[#FF0000] block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#FF0000] dark:focus:border-[#FF0000] focus:outline-none" :placeholder="$t('confirmermotdepasse')">
                                            </div>
                                            
                                        </div>
                                        <div class="w-full flex mt-4">
                                          <button
                                            @click="changePassword"
                                            v-if="loadingChangePassword == false"
                                            type="button"
                                            class="
                                              bg-[#FF0000]
                                              rounded-lg
                                              text-lg
                                              hover:border-2
                                              hover:border-[#FF0000]
                                              hover:bg-transparent
                                              text-white
                                              w-40
                                              mx-auto
                                              h-10
                                              text-center
                                            "
                                          >
                                            {{ $t('buttonemailcode') }}
                                          </button>
                                          <div v-if="loadingChangePassword == true" class="
                                              rounded-lg
                                              flex
                                              items-center
                                              justify-center
                                              border-[#FF0000]
                                              border-2
                                              text-white
                                              w-40
                                              mx-auto
                                              h-10
                                              text-center">
                                            <svg aria-hidden="true" class="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-white fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                            </svg>
                                          </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="w-full h-[16rem] bg-black  opacity-80 rounded-xl"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>    
      <div style="background-image: url('/logo/safarilogowebfondnoir-removebg-preview.png');" class="
          w-full
          h-screen
          bg-center bg-contain
          md:bg-auto
          bg-no-repeat
        ">
        <div class="w-full h-full relative">
          <div class="w-full h-full flex items-center absolute">
            <div
              class="
                xl:w-[35%]
                lg:w-[45%]
                md:w-[50%]
                w-[90%]
                mx-auto
                relative
                rounded-xl
              "
            >
              <!--Formulaire-->
              <div class="w-full h-full absolute rounded-xl z-10">
                <!--Titre-->
                <div class="w-full flex h-12 items-center">
                  <div class="mx-auto">
                    <p class="text-white text-center text-xl font-semibold mx-auto">
                      {{ $t('connexion') }}
                    </p>
                    <p v-if="messageMdpChange == true" class="text-green-500 text-xs text-center mx-auto">
                    {{ $t('votremotdepasseachange') }}
                  </p>
                  </div>
                </div>
                <!--Email/password-->
                <form @submit.prevent="login" class="space-y-10">
                  <div class="w-full  px-3">
                    <!--email-->
                    <div class="mt-8 w-full">
                      <p v-if="erreurs.message == 'User does not exist'" class="text-[#FF0000]  text-xs">{{ $t('emailincorrect') }}</p>
                      <div class="relative w-full">
                        <div
                          class="
                            absolute
                            inset-y-0
                            left-0
                            flex
                            items-center
                            pl-3
                            pointer-events-none
                          "
                        >
                          <svg
                            aria-hidden="true"
                            class="w-5 h-5 text-gray-500 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                            ></path>
                            <path
                              d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                            ></path>
                          </svg>
                        </div>
                        <input
                          v-model="form.email"
                          required
                          type="email"
                          id="input-group-1"
                          class="
                            bg-[#181A20]
                            border border-white
                            text-gray-400 text-sm
                            rounded-lg
                            focus:ring-[#FF0000] focus:border-[#FF0000]
                            block
                            w-full
                            pl-10
                            p-2.5
                            dark:bg-gray-700
                            dark:border-gray-600
                            dark:placeholder-gray-400
                            dark:text-white
                            dark:focus:ring-[#FF0000]
                            dark:focus:border-[#FF0000]
                            focus:outline-none
                          "
                          :placeholder="$t('email')"
                        />
                      </div>
                    </div>
                    <!--password-->
                    <div class="mt-8 w-full">
                      <p v-if="erreurs?.message == 'Password mismatch' || erreurs == 'The password must be at least 8 characters.'" class="text-[#FF0000] w-full text-xs">{{ $t('motdepasseincorrect') }}</p>
                      <div class="relative w-full">
                        <div
                          class="
                            absolute
                            inset-y-0
                            left-0
                            flex
                            items-center
                            pl-3
                            pointer-events-none
                          "
                        >
                          <svg
                            class="
                              w-[1.20rem]
                              h-[1.20rem]
                              text-gray-500
                              dark:text-gray-400
                            "
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 208 208"
                            version="1.1"
                          >
                            <g id="surface1">
                              <path
                                style="
                                  stroke: none;
                                  fill-rule: nonzero;
                                  fill: #959595;
                                  fill-opacity: 1;
                                "
                                d="M 102.425781 0 C 76.234375 0 55.152344 21.082031 55.152344 47.273438 L 55.152344 78.789063 C 37.824219 78.789063 23.636719 92.976563 23.636719 110.304688 L 23.636719 173.332031 C 23.636719 190.660156 37.824219 204.847656 55.152344 204.847656 L 149.695313 204.847656 C 167.023438 204.847656 181.210938 190.660156 181.210938 173.332031 L 181.210938 110.304688 C 181.210938 92.976563 167.023438 78.789063 149.695313 78.789063 L 149.695313 47.273438 C 149.695313 21.082031 128.613281 0 102.425781 0 Z M 102.425781 15.757813 C 120.367188 15.757813 133.9375 29.328125 133.9375 47.273438 L 133.9375 78.789063 L 70.910156 78.789063 L 70.910156 47.273438 C 70.910156 29.328125 84.480469 15.757813 102.425781 15.757813 Z M 102.425781 118.183594 C 111.101563 118.183594 118.183594 125.261719 118.183594 133.9375 C 118.183594 139.449219 115.042969 144.863281 110.304688 147.234375 L 110.304688 165.453125 C 110.304688 170.195313 107.164063 173.332031 102.425781 173.332031 C 97.683594 173.332031 94.546875 170.195313 94.546875 165.453125 L 94.546875 147.234375 C 89.804688 144.863281 86.667969 139.449219 86.667969 133.9375 C 86.667969 125.261719 93.746094 118.183594 102.425781 118.183594 Z "
                              />
                            </g>
                          </svg>
                        </div>
                        <!--icone mot de passe masquer-->
                        <button
                          type="button"
                          @click="showHidPassword"
                          class="absolute inset-y-0 right-1 flex items-center pl-3"
                        >
                          <svg
                          v-if="hidpassword == false"
                            class="
                              w-[1.20rem]
                              h-[1.20rem]
                              text-gray-500
                              dark:text-gray-400
                            "
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 512 512"
                            version="1.1"
                          >
                            <g id="surface1">
                              <path
                                style="
                                  stroke: none;
                                  fill-rule: nonzero;
                                  fill: #959595;
                                  fill-opacity: 1;
                                "
                                d="M 59.5 36.5 L 36.5 59.5 L 136 158.5 L 313.5 336 L 344 367 L 452.5 475.5 L 475.5 452.5 L 376 353 C 443.75 319.4375 489.1875 269.625 492 266.5 L 501.5 256 L 492 245.5 C 487.6875 240.6875 385.375 128 256 128 C 224.6875 128 195.125 134.875 168 145 Z M 256 160 C 290.4375 160 323 169.6875 352 183 C 362.3125 200.25 368 219.75 368 240 C 368 269.0625 356.75 295.625 338.5 315.5 L 293 270 C 299.6875 261.8125 304 251.4375 304 240 C 304 213.5 282.5 192 256 192 C 244.5625 192 234.1875 196.3125 226 203 L 193.5 170.5 C 213.375 164.375 234.1875 160 256 160 Z M 107 174.5 C 55.6875 206.0625 22.375 242.8125 20 245.5 L 10.5 256 L 20 266.5 C 24.125 271.125 118.875 374.8125 241 383 C 245.9375 383.5 250.9375 384 256 384 C 261.0625 384 266.0625 383.5 271 383 C 284.1875 382.125 297.0625 380.375 309.5 377.5 L 281 349 C 272.875 350.875 264.625 352 256 352 C 194.25 352 144 301.75 144 240 C 144 231.5 145.125 223.1875 147 215 Z M 116 207 C 113.4375 217.8125 112 228.8125 112 240 C 112 267.8125 119.8125 293.4375 133.5 315.5 C 96.875 294.5 68.875 269.875 54.5 256 C 66.4375 244.4375 87.9375 225.25 116 207 Z M 396 207 C 424.0625 225.25 445.5 244.4375 457.5 256 C 443.125 269.875 414.6875 295 378 316 C 391.75 293.9375 400 267.8125 400 240 C 400 228.8125 398.5625 217.75 396 207 Z "
                              />
                            </g>
                          </svg>
                          <svg v-if="hidpassword == true"
                            class="
                              w-[1.20rem]
                              h-[1.20rem]
                              text-gray-500
                              dark:text-gray-400
                            " 
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="512pt" height="512pt" viewBox="0 0 512 512" version="1.1">
                            <g id="surface1">
                            <path style=" stroke:none;fill-rule:nonzero;fill:#959595;fill-opacity:1;" d="M 256 128 C 122.625 128 20 245.5 20 245.5 L 10.5 256 L 20 266.5 C 20 266.5 113.5625 373.1875 238 383 C 243.9375 383.75 249.875 384 256 384 C 262.125 384 268.0625 383.75 274 383 C 398.4375 373.1875 492 266.5 492 266.5 L 501.5 256 L 492 245.5 C 492 245.5 389.375 128 256 128 Z M 256 160 C 291.25 160 323.75 169.625 352 182.5 C 362.1875 199.375 368 218.8125 368 240 C 368 297.8125 324.625 345.3125 268.5 351.5 C 268.1875 351.5625 267.8125 351.4375 267.5 351.5 C 263.6875 351.6875 259.875 352 256 352 C 251.75 352 247.625 351.75 243.5 351.5 C 187.375 345.3125 144 297.8125 144 240 C 144 219.125 149.625 199.6875 159.5 183 L 159 183 C 187.5 169.875 220.375 160 256 160 Z M 256 192 C 229.5 192 208 213.5 208 240 C 208 266.5 229.5 288 256 288 C 282.5 288 304 266.5 304 240 C 304 213.5 282.5 192 256 192 Z M 116 207 C 113.5 217.75 112 228.5625 112 240 C 112 268.0625 120 294.3125 134 316.5 C 93.6875 293.1875 65.6875 265.375 56.5 256 C 64.1875 248.125 85.625 227.25 116 207 Z M 396 207 C 426.375 227.25 447.8125 248.125 455.5 256 C 446.3125 265.375 418.3125 293.1875 378 316.5 C 392 294.3125 400 268.0625 400 240 C 400 228.5625 398.5 217.625 396 207 Z "/>
                            </g>
                          </svg>
                        </button>
                        <input
                          v-model="form.password"
                          required
                          :type="hidpassword ? 'text' : 'password'"
                          id="input-group-1"
                          class="
                            px-8
                            bg-[#181A20]
                            border border-white
                            text-gray-400 text-sm
                            rounded-lg
                            focus:ring-[#FF0000] focus:border-[#FF0000]
                            block
                            w-full
                            pl-10
                            p-2.5
                            dark:bg-gray-700
                            dark:border-gray-600
                            dark:placeholder-gray-400
                            dark:text-white
                            dark:focus:ring-[#FF0000]
                            dark:focus:border-[#FF0000]
                            focus:outline-none
                          "
                          :placeholder="$t('motdepasse')"
                        />
                        <div class="w-full relative mt-1">
                          <button
                          @click="hidForget = true"
                          type="button"
                            class="
                              text-[#FF0000]
                              underline
                              absolute
                              right-0
                              text-xs
                              md:text-base
                            "
                          >
                            {{ $t('motdepasseoublie') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full">
                    <div class="w-full flex">
                      <button
                        v-if="loading == false"
                        class="
                          bg-[#FF0000]
                          rounded-lg
                          text-lg
                          hover:border-2
                          hover:border-[#FF0000]
                          hover:bg-transparent
                          text-white
                          w-40
                          mx-auto
                          h-10
                          text-center
                        "
                      >
                        {{ $t('buttonconnexion') }}
                      </button>
                      <div v-if="loading == true" class="
                          rounded-lg
                          flex
                          items-center
                          justify-center
                          border-[#FF0000]
                          border-2
                          text-white
                          w-40
                          mx-auto
                          h-10
                          text-center">
                        <svg aria-hidden="true" class="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-white fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                      </div>
                    </div>
                    <div class="w-full flex justify-center mt-6">
                      <p class="text-gray-500 text-xs md:text-base">
                        {{ $t('messagecreationcompte') }}
                        <router-link to="/register"><span class="text-[#FF0000] underline">{{ $t('cliquecreationcompte') }}</span></router-link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              <div class="w-full h-[23rem] bg-black opacity-80 rounded-xl"></div>
            </div>
          </div>
          <div class="bg-[#181A20] opacity-30 w-full h-full"></div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      formForget: {
        email:'',
        code: '',
      },
      formNewPassword: {
        password: "",
        password_confirmation:""
      },
      erreurs: [],
      loading: false,
      user: {},
      hidpassword: false,
      hidForget:false,
      hidEnterEmailCode:true,
      hidEnterCode:false,
      code:'',
      errorEmail:'',
      errorcode:'',
      emailNotFind:'',
      loadingSendMail:false,
      loadingcode:false,
      HidEnterChangePassword:'',
      errorpassword:'',
      loadingChangePassword:'',
      userId:'',
      messageMdpChange:false,
      loadingnewcode: false,
      valideSend:false,
    };
  },
  watch: {
    code: {
    handler(newValue) {
        if(newValue.length > 0){
            this.loadingcode = true
            console.log(this.formForget.code)
            if(newValue == this.formForget.code){
                this.hidEnterCode = false
                this.HidEnterChangePassword = true
            }else{
                this.errorcode = 'incorrect'
            }
        }else{
            this.loadingcode = false
            this.errorcode = ''
        } 
    },
    deep: true
    }
  },

  mounted(){
    if(localStorage.getItem('jwtToken')){
      this.$router.push('/accueil');
    }

    if(localStorage.getItem('langue') == null || localStorage.getItem('langue') == '' || localStorage.getItem('langue') == false){
      localStorage.setItem('langue', 'all')
    }

    if(localStorage.getItem('langue') != 'en'){
        this.$i18n.locale = 'fr'
    }else{
        this.$i18n.locale = 'en'
    }
  },
  methods: {
    async login() {
      this.loading = true;
      await this.axios.post(this.requestHttp + 'login', this.form).then(({ data }) => {
          localStorage.setItem('jwtToken', data.access_token.token)
          localStorage.setItem('userid', data.user.id) 
          if(localStorage.getItem('langue')){
            this.$router.push('/accueil');
          }else{
            localStorage.setItem('langue', 'all')
            this.$router.push('/accueil');
          }  
      })
      .catch((error) => {
        this.erreurs = error.response.data;
        this.loading = false;
        if(error.response.data.errors[0]){
          this.erreurs = error.response.data.errors[0]
          console.log(this.erreurs)
        }
      });
    },

    sendMailForget(){
      this.loadingSendMail = true
      this.formForget.code = Math.floor(Math.random()*889799) + 110200
      this.axios.post(this.requestHttp + 'send-email-password', this.formForget).then(response=>{ 
        this.userId = response.data.id
        this.hidEnterEmailCode = false
        this.hidEnterCode = true  
        this.loadingSendMail = false
        console.log(response.data)
      }).catch(error=> {
        this.emailNotFind= error.response.data[0].message;
        this.loadingSendMail=false
      })
    },
    confirmeCode(){
        if(this.code.length > 0){
            this.loadingcode = true
            console.log(this.formForget.code)
            if(this.code == this.formForget.code){
                this.hidEnterCode = false
                this.HidEnterChangePassword = true
            }else{
                this.errorcode = 'incorrect'
            }
        }else{
            this.loadingcode = false
            this.errorcode = ''
        } 
    },
    changePassword(){
      if(this.formNewPassword.password != this.formNewPassword.password_confirmation){
            this.errorpassword = 'password no match'
            this.erreurs = ''
        }else{
            if(this.formNewPassword.password.length < 8){
               this.errorpassword = 'password no 8'
               this.erreurs = ''
            }else{
                if(this.formNewPassword.password != (this.formNewPassword.password[0].toUpperCase() +  this.formNewPassword.password.slice(1))){
                    this.errorpassword = 'uppercase password'
                    this.erreurs = ''
                }else{
                    this.loadingChangePassword = true;
                    this.axios.put(this.requestHttp + 'resetpassword/' + this.userId ,this.formNewPassword).then(({data}) => {
                        this.hidForget = false;
                        this.HidEnterChangePassword = false;
                        this.messageMdpChange = true
                        console.log(data)
                    })
                    .catch(error=> {
                        this.errors= error.response.data.errors[0];
                        this.loading = false
                    })
                }
            }
        }
    },
    showHidPassword(){
      if(this.hidpassword == true){
        this.hidpassword = false
      }else{
        this.hidpassword = true
      }
    },
    newCode(){
      this.loadingnewcode = true
      this.formForget.code = Math.floor(Math.random()*889799) + 110200
      this.axios.post(this.requestHttp + 'send-email-password', this.formForget).then(response=>{ 
        this.userId = response.data.id
        this.valideSend = true
        setTimeout(() => {
              this.valideSend = false
              this.loadingnewcode = false
        }, 5000);
      }).catch()
    }
  },
  computed:{
    requestHttp(){
      return this.$store.state.api
    }
  }
};
</script>

<style>
</style>