import { createWebHistory, createRouter } from "vue-router";
import Login from '../components/LoginComponent.vue';
import Register from '../components/RegisterComponent.vue';
import NavBar from '../components/NavBarComponent.vue';
import Accueil from '../components/AccueilComponent.vue';
import Lecteur from '../components/VideoPlayerComponent.vue';
import Confirme from '../components/ConfirmeCodeComponent.vue';
import AllPaiement from '../components/AllpaiementComponent.vue';

const routes = [
    {
        path: '/',
        component: Login
    },
    {
        path: '/allpaiement',
        component: AllPaiement
    },
    {
        path: '/register',
        component: Register
    },
    {
        path: '/lecteur',
        component: Lecteur
    },
    {
        path: '/confirme',
        component: Confirme
    },
    {
        path: '/accueil',
        component: NavBar,
        redirect: {name: 'accueil'},
        children: [
            {
                path: '',
                name: 'accueil',
                components: {
                    default: NavBar,
                    two: Accueil,
                },
            },
        ]
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
});


export default router;