<template>
  <div>
    <div v-if="paidFor">
      <h1 class="text-green-500 ">{{ $t('valideabonnementpaypal') }}</h1>
    </div>
    <div class="text-white mt-3" ref="paypal"></div>
  </div>
</template>

<script>
// import image from "../assets/lamp.png"
export default {
  name: "HelloWorld",

  data: function() {
    return {
      loaded: false,
      paidFor: false,
      product: {
        price: 2.5,
        description: "achetez un abonnement mensuel, et profitez du meilleur cinéma africain en un clic.",
        img: "./assets/lamp.jpg"
      }
    };
  },
  mounted: function() {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AZPmm45eyRHMz4BT4wR-ZY_AvbabRXZdnCMT8WJ-1NlvwTTre75NSPVrxbx3kch_He8I-SBPL2-fexcX&disable-funding=credit,card";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    setLoaded: function() {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.product.description,
                  amount: {
                    currency_code: "USD",
                    value: this.product.price
                  }
                }
              ]
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            this.paidFor = true;
            console.log(order);
            this.SaveAbonnement();
          },
          onError: err => {
            console.log(err);
          }
        })
        .render(this.$refs.paypal);
    },
    SaveAbonnement(){
        this.axios.post(this.requestHttp + 'abonnement', '',{
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
        })
        .then(() => {
          localStorage.removeItem('hidpaiement')
          this.$router.push('/accueil')
        })
        .catch()
    }
  },
  computed:{
    requestHttp(){
      return this.$store.state.api
    }
  }
};
</script>