<template>
    <div class="w-full h-screen flex items-center ">
        <button @click="closeFavoris" class="fixed top-0 z-50">
            <img class="w-8 h-8 md:w-9 md:h-9 flex-none md:ml-2  md:mt-2  md:relative transform hover:scale-110 motion-reduce:transform-none hover:z-10 transition duration-1000 " src="/icons/delete_sign_red.png" alt="" srcset="">
        </button>
        <div class=" w-[95%] md:w-[73%] h-[95%] mx-auto mt-3 bg-[#181A20] border-[#292929] border-2 rounded-md fixed right-0 left-0 m-auto z-40">
            <!--Bar de favoris-->
            <div class="w-[92%] mx-auto py-6 h-8 text-white  flex items-center">
                <p class=" text-white text-2xl font-semibold">{{ $t('favoris') }}</p>
            </div>
            <!--listes-->
            <div v-if="favoris.length > 0" class="w-full h-[90%] overflow-hidden overflow-y-auto flex scrollbar-hide">
                <div  class=" w-full md:w-[92%] mx-auto h-auto flex flex-wrap md:px-2 justify-center  ">
                    <button v-for="favori in favoris" :key="favori.id" class="w-40 h-56  bg-[#292929] ml-2 mt-2 overflow-hidden transform hover:scale-110 motion-reduce:transform-none hover:z-10 transition duration-1000">
                        <img @click="openDetailPage(favori)" class="w-full h-full object-center" :src="favori.image" alt="" srcset="">
                    </button>
                </div>
            </div>
            <!--aucun resultat-->
            <div v-if="favoris.length == 0" class="w-full h-full flex items-center">
                <div class="mx-auto text-center mb-44">
                    <img class="h-[11rem] mx-auto" src="/icons/brokenheart_480px.png" alt="" srcset="">
                    <p class="text-white">{{ $t('aucunfavoris') }}</p>
                </div>
            </div>
        </div>
        <div @click="closeFavoris" class="w-full h-screen backdrop-blur-sm  z-30 flex fixed"></div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            favoris: [],
        }
    },
    watch: {
    getDataFavoris: {
        handler(newValue) {
            this.favoris = newValue
        },
        deep: true
        },
    },
    mounted(){
        this.favoris = this.getDataFavoris
    },
    methods:{
        closeFavoris(){
            window.emitter.emit("menuEventRecherche", false) 
        },
        openDetailPage(data){
            window.emitter.emit("EventDetailPage", data)
            localStorage.removeItem('detail')
        },
    },
    computed:{
        getDataFavoris(){
        return this.$store.state.dataFavoris
    },
    }
}
</script>

<style>
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
</style>