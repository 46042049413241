<template>
    <div class="w-full h-screen bg-black relative">
        <div class="w-full h-screen relative">
            <div class="w-[18%] md:w-[3%] md:h-[5%] h-[6%] bg-black absolute left-0 z-10 flex items-center">
                <button @click="backPage" class=" mx-auto"><img  class="h-8 mx-auto" src="/icons/return_512px.png" alt="" srcset=""></button>
            </div>
            <div v-if="contenu.rubrique != 'Film'" class="w-[18%] xl:hidden md:w-[5%] md:h-[5%] h-[6%] bg-black absolute right-0 z-50 flex items-center">
                <button @click="menuShowHid" class=" mx-auto"><img class="h-8" src="/icons/menu_96px.png" alt="" srcset=""></button>
            </div>
            <div class="w-full h-screen relative">
              <div v-if="menu" class=" absolute  top-0 w-full h-screen backdrop-blur-sm z-40">
                <div class="w-full h-full relative z-50">
                    <div v-if="allSaisons?.length > 0" class="w-[82%] md:w-[95%] h-[6%] md:h-[5%] absolute top-0 bg-[#181A20] z-50 flex items-center space-x-4 overflow-x-auto overflow-hidden  scrollbar-hide">
                      <div v-for="saison in allSaisons" :key="saison.id" >
                          <button class="text-[#FF0000] font-semibold  w-[8rem] md:text-lg text-md " v-if="saison.id == activeSaison.id" @click="findEpisode(saison)">{{ saison.nom }}</button>
                          <button class="text-gray-500 font-semibold  w-[8rem] md:text-lg text-md " v-if="saison.id != activeSaison.id" @click="findEpisode(saison)">{{ saison.nom }}</button>
                      </div>     
                    </div>
                    <div  class=" md:w-64 w-56 h-[94%] md:h-[95%] absolute bottom-0 right-0 bg-[#181A20]  px-1 z-50">
                        <div v-if="loadEpisode == true"  class="
                            rounded-lg
                            flex
                            items-center
                            justify-center
                            text-white
                            w-full
                            mx-auto
                            h-full
                            text-center">
                          <svg aria-hidden="true" class="inline w-6 h-6 mr-2 text-[#FF0000] animate-spin dark:text-white fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                          </svg>
                        </div>
                        <div v-if="allEpisodesFind?.length > 0 && loadEpisode == false" class="w-full h-full overflow-hidden space-y-4 justify-center overflow-y-auto scrollbar-hide">
                          <!--card 1-->
                          <div v-for="episode in allEpisodesFind" :key="episode.id" @click="newPlay(episode)" class="w-[w-full] md:h-[15rem] h-[14rem] relative rounded-md shadow-2xl overflow-hidden  mt-1">
                              <button v-if="episode.id == actuelEpisode.id" class="w-full h-full border-2 shadow-2xl border-[#FF0000]">
                                <div class=" w-full h-[75%] rounded overflow-hidden relative">
                                    <div class=" w-full h-full absolute flex items-center">
                                        <div class="relative w-full h-full">
                                        <div class="w-full h-full absolute flex items-center z-10">
                                            <img class="w-14 h-14 mx-auto" src="/icons/play.png" alt="" srcset="">
                                        </div>
                                        <div class=" w-full h-full bg-black opacity-30"></div>
                                        </div>
                                    </div>
                                    <div class=" w-full h-full ">
                                        <img class=" w-full h-full object-cover object-center " :src="contenu.image" alt="" srcset="">
                                    </div>
                                </div>
                                <div class="  h-[23%] mt-2 px-1 ">
                                    <div class="hidden xl:block">
                                      <p v-if="episode.titre.length <= 17" class=" text-white h-1/2 text-left">{{ episode.nom }}: {{ episode.titre }}</p>
                                      <p v-if="episode.titre.length > 17" class=" text-white h-1/2 text-left">{{ episode.nom }}: {{ episode.titre.substring(0,17) + '...' }}</p>
                                      <div class="w-full flex justify-between">
                                          <p class="text-xs text-white mt-1">Duree: <span class="text-[#FF0000]">{{ episode.duree }}</span></p>
                                          <p class=" text-white text-xs font-bold">{{ episode.qualite }}</p>
                                      </div>
                                    </div>
                                    <div class="xl:hidden">
                                        <p v-if="episode.titre.length <= 13" class=" text-white h-1/2 text-left">{{ episode.nom }}: {{ episode.titre }}</p>
                                        <p v-if="episode.titre.length > 13" class=" text-white h-1/2 text-left">{{ episode.nom }}: {{ episode.titre.substring(0,14) + '...' }}</p>
                                        <div class="w-full flex justify-between">
                                          <p class="text-xs text-white mt-1">Duree: <span class="text-[#FF0000]">{{ episode.duree }}</span></p>
                                          <p class=" text-white text-xs font-bold">{{ episode.qualite }}</p>
                                        </div>
                                    </div>
                                  </div>              
                              </button>
                              <button v-if="episode.id != actuelEpisode.id" class="w-full h-full shadow-2xl">
                                <div class=" w-full h-[75%] rounded overflow-hidden relative">
                                    <div class=" w-full h-full absolute flex items-center">
                                        <div class="relative w-full h-full">
                                        <div class="w-full h-full absolute flex items-center z-10">
                                            <img class="w-14 h-14 mx-auto" src="/icons/play.png" alt="" srcset="">
                                        </div>
                                        <div class=" w-full h-full bg-black opacity-30"></div>
                                        </div>
                                    </div>
                                    <div class=" w-full h-full ">
                                        <img class=" w-full h-full object-cover object-center " :src="contenu.image" alt="" srcset="">
                                    </div>
                                </div>
                                <div class="  h-[20%] mt-2 px-1 ">
                                    <div class="hidden xl:block">
                                      <p v-if="episode.titre.length <= 17" class=" text-white h-1/2 text-left">{{ episode.nom }}: {{ episode.titre }}</p>
                                      <p v-if="episode.titre.length > 17" class=" text-white h-1/2 text-left">{{ episode.nom }}: {{ episode.titre.substring(0,17) + '...' }}</p>
                                      <div class="w-full flex justify-between">
                                          <p class="text-xs text-white mt-1">Duree: <span class="text-[#FF0000]">{{ episode.duree }}</span></p>
                                          <p class=" text-white text-xs font-bold">{{ episode.qualite }}</p>
                                      </div>
                                    </div>
                                    <div class=" xl:hidden">
                                        <p v-if="episode.titre.length <= 13" class=" text-white h-1/2 text-left">{{ episode.nom }}: {{ episode.titre }}</p>
                                        <p v-if="episode.titre.length > 13" class=" text-white h-1/2 text-left">{{ episode.nom }}: {{ episode.titre.substring(0,13) + '...' }}</p>
                                        <div class="w-full flex justify-between">
                                            <p class="text-xs text-white mt-1">Duree: <span class="text-[#FF0000]">{{ episode.duree }}</span></p>
                                            <p class=" text-white text-xs font-bold">{{ episode.qualite }}</p>
                                        </div>
                                    </div>
                                </div>              
                              </button>          
                          </div>
                      </div>                  
                    </div>
                    <div @click="menuShowHid" class="w-full h-full"></div>
                </div>
              </div>
              <div class="w-[100%] h-screen flex items-center ">
                  <div  class="mx-auto md:w-[100%] w-full md:h-[55%]  xl:h-[100%] h-[50%] bg-contain z-0" ref="artRef"></div>
              </div>             
            </div>
        </div>
        
    </div>
</template>

<script>
import Artplayer from "artplayer";
 
export default {
   beforeRouteLeave() {
      if(localStorage.getItem('oldpage') == 'detail'){
        localStorage.setItem('openpage', 'detail')
      }else{
        localStorage.removeItem('detail')
      }
      clearInterval(this.intervalTime)
      if(this.art.playing){
        this.art.destroy();
      }
      this.form.duration_play = this.art.currentTime
      this.form.duration_full = this.art.duration
      this.form.episode_id = this.actuelEpisode.id
      this.form.nameepisode= this.actuelEpisode.nom
      this.form.saison_id = this.actuelSaison.id
      this.form.namesaison= this.actuelSaison.nom
      if(this.art.currentTime > 1){
        if(this.contenu.rubrique == "Film"){
          if(this.art.currentTime < this.art.duration * 90/100){
            this.axios.post(this.requestHttp + 'saverecent',this.form,{
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
              },
              }).then(() => {
              setTimeout(() => {
                  this.setDataRecent
              }, 10000);
            })
            .catch()
          }else{
            this.axios.delete(this.requestHttp + 'deleterecent/' + this.contenu.id,{
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
              },
              }).then(() => {
              setTimeout(() => {
                  this.setDataRecent
              }, 10000);
            })
            .catch()
          }
        }else{
          if(this.actuelSaison.id == this.allSaisons[this.allSaisons.length - 1].id && this.actuelEpisode.id == this.allEpisodes[this.allEpisodes.length - 1].id && this.art.currentTime >= (this.art.duration * 90)/100){
            this.axios.delete(this.requestHttp + 'deleterecent/' + this.contenu.id,{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
            }).then(() => {
            setTimeout(() => {
                this.setDataRecent
            }, 10000);
            })
            .catch()
          }else{
            this.axios.post(this.requestHttp + 'saverecent',this.form,{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
            }).then(() => {
            setTimeout(() => {
                this.setDataRecent
            }, 10000);
            this.art.destroy()
            })
            .catch()
          }
        } 
      }
  },

  data() {
    return {
      menu: false,
      instance: null,
      loadEpisode:false,
      activeSaison:'',
      allEpisodesFind:'',
      aspectRatioDefault:'16:9',
      color:'',
      intervalTime:'',
      contenu:[],
      actuelEpisode:{},
      allEpisodes:[],
      ActuelAllEpisodes:[],
      allSaisons:[],
      actuelSaison:{},
      detailPlay:[],
      qualites:[],
      listEpisodeLecteur:[],
      listSaisonLecteur:[],
      buttonPasseGeneriqueAdd: false,
      form:{
        film_id:'',
        saison_id:'',
        episode_id:'',
        duration_play:'',
        duration_full:'',
        namesaison:'',
        nameepisode:'',
      },
      art:'',
      foundDefaultQuality:false,
      option: {
            container: '.artplayer-app',
            url: "",
            poster:'/logo/gif.gif',
            volume: 1.0,
            isLive: false,
            muted: false,
            autoplay: true,
            fastForward: true,
            pip: true,
            hotkey: true,
            autoSize: false,
            autoMini: true,
            screenshot: false,
            setting: true,
            //loop: true,
            flip: true,
            playbackRate: true,
            aspectRatio: true,
            fullscreen: true,
            fullscreenWeb: false,
            subtitleOffset: true,
            miniProgressBar: true,
            mutex: true,
            backdrop: true,
            playsInline: true,
            autoPlayback: true,
            airplay: false,
            lang: 'fr',
            i18n: {},
              layers: [
                  {
                      name: 'Logo',
                      html: `<img style="width: 140px" src="/logo/safarilogowebfondnoir-removebg-preview.png">`,
                      tooltip: 'Logo',
                      style: {
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                      },
                      click: function (...args) {
                          console.info('click', args);
                      },
                      mounted: function () {
                      },
                  },
              ],
              // controls: [
              //       {
              //           name: 'your-button',
              //           index: 30,
              //           position: 'left',
              //           html: '<img style="width: 20px" src="/icons/bell.png">',
              //           tooltip: 'Your Button',
              //           style: {
              //             margin:"0px",
              //             color: 'red',
              //           },
              //           click: function (...args) {
              //               console.info('click', args);
              //           },
              //           mounted: function () {
              //               console.log('control')
              //           },
              //       },
              //   ],
              quality: [],
              // thumbnails: {
              //     url: '/assets/sample/thumbnails.png',
              //     number: 100,
              //     column: 10,
              // },  
            }, 
        };
    },
  
  watch: {
    
  },
  
  created() {
    this.intervalTime = setInterval(this.refrescurrentTime, 900);
    window.addEventListener("beforeunload", (e) => {
    var confirmationMessage = "o/";
    (e || window.event).returnValue = confirmationMessage;
    
 // this will be accessible now
    //localStorage.removeItem('detail')
    this.form.duration_play = this.art.currentTime
      this.form.duration_full = this.art.duration
      this.form.episode_id = this.actuelEpisode.id
      this.form.nameepisode= this.actuelEpisode.nom
      this.form.saison_id = this.actuelSaison.id
      this.form.namesaison= this.actuelSaison.nom
      
      if(this.art.currentTime > 1){
        if(this.contenu.rubrique == "Film"){
          if(this.art.currentTime < (this.art.duration * 90)/100){
            this.axios.post(this.requestHttp + 'saverecent',this.form,{
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                },
                }).then(() => {
              setTimeout(() => {
                  this.setDataRecent
              }, 10000);
              this.art.destroy()
            })
            .catch()
            }else{
              this.axios.delete(this.requestHttp + 'deleterecent/' + this.contenu.id,{
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                },
                }).then(() => {
                setTimeout(() => {
                    this.setDataRecent
                }, 10000);
              })
              .catch()
          }
          
        }else{
          if(this.actuelSaison.id == this.allSaisons[this.allSaisons.length - 1].id && this.actuelEpisode.id == this.allEpisodes[this.allEpisodes.length - 1].id && this.art.currentTime >= (this.art.duration * 90)/100){
            this.axios.delete(this.requestHttp + 'deleterecent/' + this.contenu.id,{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
            }).then(() => {
            setTimeout(() => {
                this.setDataRecent
            }, 10000);
            })
            .catch()
          }else{
            this.axios.post(this.requestHttp + 'saverecent',this.form,{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
            }).then(() => {
            setTimeout(() => {
                this.setDataRecent
            }, 10000);
            this.art.destroy()
            })
            .catch()
          }
        }
      }

    return confirmationMessage;
  });
  },

 async mounted(){
    if(localStorage.getItem('jwtToken') == null || localStorage.getItem('jwtToken') == '' || localStorage.getItem('jwtToken') == false){
      this.$router.push('/');
    }
    localStorage.setItem('currentTime', 0)
    this.detailPlay =  JSON.parse(localStorage.getItem('detail'))
    this.contenu = this.detailPlay[0]
    this.form.film_id = this.detailPlay[0].id
    
    if(this.detailPlay.length <= 2){
    await this.axios.get(this.requestHttp + 'saisonsepisodes' + '/' + this.detailPlay[1].film_id + '/' +  this.detailPlay[1].saison_id + '/' +  this.detailPlay[1].episode_id,{
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
      },
      }).then(({data}) => {       
        this.allSaisons = data[0]
        this.allEpisodes = data[1]
        this.allEpisodesFind = data[1]
        for(let i=0; i<= data[1].length - 1; i++){
            if(this.detailPlay[1].episode_id == data[1][i].id){
              this.actuelEpisode = data[1][i]
            }
        }
        for(let j=0; j<= data[0].length - 1; j++){
            if(this.detailPlay[1].saison_id == data[0][j].id){
              this.actuelSaison = data[0][j]
              this.activeSaison = data[0][j]
            }
        }
        this.detailPlay.push(this.actuelEpisode, data[0], this.actuelSaison , data[1]) 
        localStorage.removeItem('detail')
        localStorage.setItem('detail', JSON.stringify(this.detailPlay))
        //this.option.url = this.actuelEpisode.video 
        this.axios.get(this.requestHttp + 'allvideos/' + this.actuelEpisode.id,{
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` 
          },
          }).then((data) => {
            for(let i = 0; i <= data.data.length - 1; i++){
              if(data.data[i].qualite == localStorage.getItem('defaultqualite')){
                this.option.url =  data.data[i].video
                this.option.quality.push({
                  default: true,
                  html: data.data[i].qualite,
                    url: data.data[i].video
                  },
                )
                this.foundDefaultQuality = true 
              }else{
                this.option.quality.push({
                  html: data.data[i].qualite,
                  url: data.data[i].video
                  },
                ) 
              }
            }
            if(this.foundDefaultQuality == false){
              this.option.quality = []
              for(let i = 0; i <= data.data.length - 1; i++){
                if(i == Math.trunc((data.data.length)/2)){
                  this.option.url =  data.data[i].video
                  localStorage.setItem('defaultqualite', data.data[i].qualite)
                  this.option.quality.push({
                    default: true,
                    html: data.data[i].qualite,
                      url: data.data[i].video
                    },
                  )
                }else{
                  if(i != Math.trunc((data.data.length)/2)){
                    this.option.quality.push({
                      html: data.data[i].qualite,
                        url: data.data[i].video
                      },
                    )
                  }  
                }
              }
            }
            //this.option.url =  data.data[0].video
            this.instance = new Artplayer({
              ...this.option,
              container: this.$refs.artRef,
            });
            this.$nextTick(() => {
              this.getInstance(this.instance)
            });
          })
          .catch()
      })
      .catch()
        
    }else{
      if(this.detailPlay.length > 5){
        this.allEpisodes = this.detailPlay[5]
        console.log(this.detailPlay[5])
        this.allEpisodesFind = this.detailPlay[5]
        this.allSaisons = this.detailPlay[3]
      } 
        
      if(this.detailPlay[0].rubrique == 'Film'){
          for(let i = 0; i <= this.detailPlay[2].length - 1; i++){
              if(this.detailPlay[2][i].qualite == localStorage.getItem('defaultqualite')){
                this.option.url =  this.detailPlay[2][i].video
                this.option.quality.push({
                  default: true,
                  html: this.detailPlay[2][i].qualite,
                    url: this.detailPlay[2][i].video
                  },
                )
                this.foundDefaultQuality = true 
              }else{
                this.option.quality.push({
                  html: this.detailPlay[2][i].qualite,
                    url: this.detailPlay[2][i].video
                  },
                )
              }
            }
          if(this.foundDefaultQuality == false){
            this.option.quality = []
            for(let i = 0; i <= this.detailPlay[2].length - 1; i++){
              if(i == Math.trunc((this.detailPlay[2].length)/2)){
                this.option.url =  this.detailPlay[2][i].video
                localStorage.setItem('defaultqualite', this.detailPlay[2][i].qualite)
                this.option.quality.push({
                  default: true,
                  html: this.detailPlay[2][i].qualite,
                    url: this.detailPlay[2][i].video
                  },
                )
              }else{
                if(i != Math.trunc((this.detailPlay[2].length)/2)){
                  this.option.quality.push({
                    html: this.detailPlay[2][i].qualite,
                      url: this.detailPlay[2][i].video
                    },
                  )
                }  
              }
            }
          }
        }else{
          this.actuelEpisode = this.detailPlay[2]
          this.actuelSaison = this.detailPlay[4]
          this.activeSaison= this.detailPlay[4]
          this.axios.get(this.requestHttp + 'allvideos/' + this.detailPlay[2].id,{
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
          },
          }).then((data) => {
            for(let i = 0; i <= data.data.length - 1; i++){
              if(data.data[i].qualite == localStorage.getItem('defaultqualite')){
                this.option.url =  data.data[i].video
                this.option.quality.push({
                  default: true,
                  html: data.data[i].qualite,
                    url: data.data[i].video
                  },
                )
                this.foundDefaultQuality = true 
              }else{
                this.option.quality.push({
                  html: data.data[i].qualite,
                    url: data.data[i].video
                  },
                )
              }
            }
            if(this.foundDefaultQuality == false){
              this.option.quality = []
              for(let i = 0; i <= data.data.length - 1; i++){
                if(i == Math.trunc((data.data.length)/2)){
                  this.option.url =  data.data[i].video
                  localStorage.setItem('defaultqualite', data.data[i].qualite)
                  this.option.quality.push({
                    default: true,
                    html: data.data[i].qualite,
                      url: data.data[i].video
                    },
                  )
                }else{
                  this.option.quality.push({
                    html: data.data[i].qualite,
                      url: data.data[i].video
                    },
                  )
                }
              }
            } 
            
            //this.option.url =  data.data[0].video
            this.instance = new Artplayer({
              ...this.option,
              container: this.$refs.artRef,
            });

            this.$nextTick(() => {
              this.getInstance(this.instance)
            });
          })
          .catch()
          //this.option.url = this.detailPlay[2].video    
        }
    }      

    if(localStorage.getItem('langue') != 'en'){
            this.option.i18n = {
              'fr': {
                  'Video Info': 'Infos vidéo',
                  'Play': 'Lire',
                  'Close': 'Fermer',
                  'Video Load Failed': 'Échec du chargement de la vidéo',
                  'Volume': 'Volume',
                  'Pause': 'Pause',
                  'Rate': 'Taux',
                  'Mute': 'Muet',
                  'Video Flip': 'Flip vidéo',
                  'Horizontal': 'Horizontale',
                  'Vertical': 'Verticale',
                  'Reconnect': ' Reconnecter',
                  'Show Setting': 'Paramètres de l\'émission',
                  'Hide Setting': 'Cacher le paramètre',
                  'Screenshot': 'Capture d\'écran',
                  'Play Speed': 'Vitesse de lecture',
                  'Aspect Ratio': 'Rapport d\'aspect',
                  'Default': 'Par défaut',
                  'Normal': 'Normal',
                  'Open': 'Ouvrir',
                  'Switch Video': 'Vidéo de l\'interrupteur',
                  'Switch Subtitle': 'Changement de sous-titre',
                  'Fullscreen': 'Plein écran',
                  'Exit Fullscreen': 'Quitter le plein écran',
                  'Web Fullscreen': 'Web Plein écran',
                  'Exit Web Fullscreen': 'Quitter le Web plein écran',
                  'Mini Player': 'Mini lecteur',
                  'PIP Mode': 'Le mode PIP',
                  'Exit PIP Mode': 'Quitter le mode PIP',
                  'PIP Not Supported': 'PIP non pris en charge',
                  'Fullscreen Not Supported': 'Le plein écran n\'est pas pris en charge',
                  'Subtitle Offset': 'Décalage des sous-titres',
                  'Last Seen': 'Dernière vue',
                  'Jump Play': 'continuer là où vous vous êtes arrêté',
                  'AirPlay': 'AirPlay',
                  'AirPlay Not Available': 'AirPlay non disponible',
              }
          }
        } 

    
    if(this.detailPlay[0].rubrique == 'Film'){
      this.instance = new Artplayer({
        ...this.option,
        container: this.$refs.artRef,
      });
      this.$nextTick(() => {
        this.getInstance(this.instance)
      });
    }
  },


  beforeUnmount() {
    if (this.instance && this.instance.destroy) {
      this.instance.destroy(false);
    }
  },

  methods: {
    getInstance(art) {
      this.time = art.currentTime
      art.on('ready', () => {
        //art.fullscreenWeb = true; 
        art.controls.update({
                  name: 'quality',
                  index: 10,
                  position: 'right',
                  html: localStorage.getItem('defaultqualite'),
                  selector:this.option.quality, 
                  onSelect:(item)=> {
                    this.art.switchQuality(item.url, item.html);
                    localStorage.setItem('defaultqualite', item.html)
                    //args.url = item.url  
                  },
            });
          art.aspectRatio = '16:9'
          this.art = art
          art.play()
          
          art.title = this.detailPlay[0].titre;
          art.seek = this.detailPlay[1].duration_play;

          if(this.detailPlay[0].rubrique == 'Film'){          
            art.layers.add({
                name: 'titre',
                html: `<marquee scrollamount="3" >${this.detailPlay[0].titre}</marquee>`,
                tooltip: 'Potser Tip',
                style: {
                    position: 'absolute',
                    top: '14px',
                    left: '5px',
                    width: '25%',
                    'font-size':'20px',
                    'font-family':'cursive'
                },
                click: function (...args) {
                    console.info('click', args);
                },
                mounted: function (...args) {
                    console.info('mounted', args);
                },
            });
          }else{
            if(( window.innerWidth >= 800 ) && ( window.innerHeight >= 600 )){
              //liste saison sur le lecteur
              for(let i = 0; i <= this.allSaisons.length-1; i++){
                if(this.allSaisons[i]?.nom == this.actuelSaison.nom){
                  this.listSaisonLecteur.push({
                    default: true,
                    id: this.allSaisons[i]?.id,
                    nom:this.allSaisons[i]?.nom,
                    html: this.allSaisons[i]?.nom,
                    film_id: this.allSaisons[i]?.film_id,
                  })
                }else{
                  this.listSaisonLecteur.push({
                    id: this.allSaisons[i]?.id,
                    nom:this.allSaisons[i]?.nom,
                    html: this.allSaisons[i]?.nom,
                    film_id: this.allSaisons[i]?.film_id,
                  })
                }
                
              }
              art.controls.add({
                    name: 'listsaison',
                    index: 8,
                    position: 'right',
                    html: `<div style="margin: 10px">${this.actuelSaison.nom}<div>`,
                    selector:this.listSaisonLecteur,
                    onSelect:(item)=> {
                      this.findEpisode(item)
                      //args.url = item.url  
                    },
              });

              //liste episode sur le lecteur
              for(let i = 0; i <= this.allEpisodes.length - 1; i++){
                if(this.allEpisodes[i]?.nom == this.actuelEpisode.nom){
                  this.listEpisodeLecteur.push({
                    default: true,
                    id: this.allEpisodes[i]?.id,
                    nom:this.allEpisodes[i]?.nom,
                    html: this.allEpisodes[i]?.nom,
                    titre: this.allEpisodes[i]?.titre,
                    qualite: this.allEpisodes[i]?.qualite,
                    duree: this.allEpisodes[i]?.duree,
                    debut_generique: this.allEpisodes[i]?.debut_generique,
                    fin_generique: this.allEpisodes[i]?.fin_generique,
                    saison_id: this.allEpisodes[i]?.saison_id,
                  })
                }else{
                  this.listEpisodeLecteur.push({
                    id: this.allEpisodes[i]?.id,
                    nom:this.allEpisodes[i]?.nom,
                    html: this.allEpisodes[i]?.nom,
                    titre: this.allEpisodes[i]?.titre,
                    qualite: this.allEpisodes[i]?.qualite,
                    duree: this.allEpisodes[i]?.duree,
                    debut_generique: this.allEpisodes[i]?.debut_generique,
                    fin_generique: this.allEpisodes[i]?.fin_generique,
                    saison_id: this.allEpisodes[i]?.saison_id,
                  })
                }
                
              }
              art.controls.add({
                    name: 'listepisodes',
                    index: 9,
                    position: 'right',
                    html: this.actuelEpisode.nom,
                    selector:this.listEpisodeLecteur,
                    onSelect:(item)=> {
                      this.newPlay(item)
                      //args.url = item.url  
                    },
              });
            }
              art.layers.add({
                name: 'titre',
                html: `<marquee scrollamount="3" >${this.actuelSaison.nom} ${this.actuelEpisode.nom} : ${this.actuelEpisode.titre}</marquee>`,
                tooltip: 'Potser Tip',
                style: {
                    position: 'absolute',
                    top: '14px',
                    left: '5px',
                    width: '35%',
                    'font-size':'16px',
                    'font-family':'cursive'
                },
                click: function (...args) {
                    console.info('click', args);
                },
                mounted: function (...args) {
                    console.info('mounted', args);
                },
            });
          }
          //  setTimeout(() => {
          //       art.pause();
          //       console.info(art.currentTime)
          //   }, 10000);   
      });
    },

    findEpisode(saison){
      this.loadEpisode = true
      this.axios.get(this.requestHttp + 'allepisodes/' + saison.id, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
      },
      }).then(({data}) => {
        this.loadEpisode = false
        this.allEpisodesFind = data[0]
        this.activeSaison = data[1][0]
        if(( window.innerWidth >= 800 ) && ( window.innerHeight >= 600 )){
          //liste saison sur le lecteur
          this.listSaisonLecteur = []
          for(let i = 0; i <= this.allSaisons.length - 1; i++){
                if(this.allSaisons[i]?.nom == this.activeSaison.nom){
                  this.listSaisonLecteur.push({
                    default: true,
                    id: this.allSaisons[i]?.id,
                    nom:this.allSaisons[i]?.nom,
                    html: this.allSaisons[i]?.nom,
                    film_id: this.allSaisons[i]?.film_id,
                  })
                }else{
                  this.listSaisonLecteur.push({
                    id: this.allSaisons[i]?.id,
                    nom:this.allSaisons[i]?.nom,
                    html: this.allSaisons[i]?.nom,
                    film_id: this.allSaisons[i]?.film_id,
                  })
                }
                
              }
              this.art.controls.update({
                    name: 'listsaison',
                    index: 8,
                    position: 'right',
                    html: `<div style="margin: 10px">${this.activeSaison.nom}<div>`,
                    selector:this.listSaisonLecteur,
                    onSelect:(item)=> {
                      this.findEpisode(item)
                      //args.url = item.url  
                    },
            });

          this.listEpisodeLecteur = []
          for(let i = 0; i <= data[0].length - 1; i++){
            if(data[0][i]?.nom == data[0][0]?.nom){
              this.listEpisodeLecteur.push({
                default: true,
                id: data[0][i]?.id,
                nom:data[0][i]?.nom,
                html: data[0][i]?.nom,
                titre: data[0][i]?.titre,
                qualite: data[0][i]?.qualite,
                duree: data[0][i]?.duree,
                debut_generique: data[0][i]?.debut_generique,
                fin_generique: data[0][i]?.fin_generique,
                saison_id: data[0][i]?.saison_id,
              })
            }else{
              this.listEpisodeLecteur.push({
                id: data[0][i]?.id,
                nom:data[0][i]?.nom,
                html: data[0][i]?.nom,
                titre: data[0][i]?.titre,
                qualite: data[0][i]?.qualite,
                duree: data[0][i]?.duree,
                debut_generique: data[0][i]?.debut_generique,
                fin_generique: data[0][i]?.fin_generique,
                saison_id: data[0][i]?.saison_id,
              })
            }
          }
          this.art.controls.update({
                name: 'listepisodes',
                index: 9,
                position: 'right',
                html: data[0][0]?.nom,
                selector:this.listEpisodeLecteur,
                onSelect:(item)=> {
                  this.newPlay(item)
                  //args.url = item.url  
                },
          });
        }
        })
        
      .catch() 
    },

    menuShowHid(){
      if(this.menu){
        this.menu = false
      }else{
        this.menu = true
      }
    },
    backPage(){
      this.$router.go(-1)
    },
    connection(){
      var fileURL = "https://upload.wikimedia.org/wikipedia/commons/a/a6/Brandenburger_Tor_abends.jpg";

      var request = new XMLHttpRequest();
      var avoidCache = "?avoidcache=" + (new Date()).getTime();
      request.open('GET', fileURL + avoidCache, true);
      request.responseType = "application/*";
      var startTime = (new Date()).getTime();
      var endTime = startTime;
      request.onreadystatechange = function () {
          if (request.readyState == 2)
          {
              //ready state 2 is when the request is sent
              startTime = (new Date().getTime());
          }
          if (request.readyState == 4)
          {
              endTime = (new Date()).getTime();
              var downloadSize = request.responseText.length;
              var time = (endTime - startTime) / 1000;
              var sizeInBits = downloadSize * 8;
              var speed = ((sizeInBits / time) / (1024 * 1024)).toFixed(2);
              console.log(downloadSize, time, speed);
          }
      }

      request.send();

    },
    async newPlay(episode){
      //this.connection()
      if(this.allEpisodesFind){
        this.allEpisodes = this.allEpisodesFind
      }
      await clearInterval(this.intervalTime);
      this.detailPlay[2] = episode
      this.detailPlay[1].duration_play = 0
      this.actuelSaison = this.activeSaison
      this.detailPlay[5] = this.allEpisodes
      this.detailPlay[4] = this.actuelSaison
      localStorage.removeItem('detail')
      localStorage.setItem('detail', JSON.stringify(this.detailPlay))
      // this.option.url = episode.video
      
      this.actuelEpisode = episode
      if(this.buttonPasseGeneriqueAdd == false && this.art.layers['passegenerique']){ 
            this.art.layers.remove('passegenerique')
            this.buttonPasseGeneriqueAdd = false
        }
      //this.art.url = episode.video
      this.axios.get(this.requestHttp + 'allvideos/' + episode.id,{
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` 
          },
          }).then((data) => {
            this.qualites = []
            var defaultQuality = ''
            this.foundDefaultQuality = false
            for(let i = 0; i <= data.data.length - 1; i++){
              if(data.data[i].qualite == localStorage.getItem('defaultqualite')){
                this.art.url =  data.data[i].video
                defaultQuality = data.data[i].qualite
                this.qualites.push({
                  default: true,
                  html: data.data[i].qualite,
                    url: data.data[i].video
                  },
                )
                this.foundDefaultQuality = true 
              }else{
                this.qualites.push({
                  html: data.data[i].qualite,
                  url: data.data[i].video
                  },
                ) 
              }
            }
            if(this.foundDefaultQuality == false){
              this.qualites = []
              for(let i = 0; i <= data.data.length - 1; i++){
                if(i == Math.trunc((data.data.length)/2)){
                  this.art.url =  data.data[i].video
                  defaultQuality = data.data[i].qualite
                  this.qualites.push({
                    default: true,
                    html: data.data[i].qualite,
                      url: data.data[i].video
                    },
                  )
                }else{
                  if(i != Math.trunc((data.data.length)/2)){
                    this.qualites.push({
                      html: data.data[i].qualite,
                        url: data.data[i].video
                      },
                    )
                  }  
                }
              }
            }
            
            this.art.controls.update({
                  name: 'quality',
                  index: 10,
                  position: 'right',
                  html: defaultQuality,
                  selector:this.qualites,
                  onSelect:(item)=> {
                    this.art.switchQuality(item.url, item.html);
                    localStorage.setItem('defaultqualite', item.html)
                    //args.url = item.url  
                  },
            });
            this.art.currentTime = 0
          })
      .catch()
      if(( window.innerWidth >= 800 ) && ( window.innerHeight >= 600 )){
        this.listEpisodeLecteur = []
        for(let i = 0; i <= this.allEpisodes.length - 1; i++){
          if(this.allEpisodes[i]?.nom == this.actuelEpisode.nom){
            this.listEpisodeLecteur.push({
              default: true,
              id: this.allEpisodes[i]?.id,
              nom:this.allEpisodes[i]?.nom,
              html: this.allEpisodes[i]?.nom,
              titre: this.allEpisodes[i]?.titre,
              qualite: this.allEpisodes[i]?.qualite,
              duree: this.allEpisodes[i]?.duree,
              debut_generique: this.allEpisodes[i]?.debut_generique,
              fin_generique: this.allEpisodes[i]?.fin_generique,
              saison_id: this.allEpisodes[i]?.saison_id,
            })
          }else{
            this.listEpisodeLecteur.push({
              id: this.allEpisodes[i]?.id,
              nom:this.allEpisodes[i]?.nom,
              html: this.allEpisodes[i]?.nom,
              titre: this.allEpisodes[i]?.titre,
              qualite: this.allEpisodes[i]?.qualite,
              duree: this.allEpisodes[i]?.duree,
              debut_generique: this.allEpisodes[i]?.debut_generique,
              fin_generique: this.allEpisodes[i]?.fin_generique,
              saison_id: this.allEpisodes[i]?.saison_id,
            })
          }
          
        }
        this.art.controls.update({
              name: 'listepisodes',
              index: 9,
              position: 'right',
              html: this.actuelEpisode.nom,
              selector:this.listEpisodeLecteur,
              onSelect:(item)=> {
                this.newPlay(item)
                //args.url = item.url  
              },
        });
      }
      this.art.layers.titre.innerHTML = `<marquee scrollamount="3" >${this.actuelSaison.nom} ${this.actuelEpisode.nom} : ${this.actuelEpisode.titre}</marquee>`
    
      this.intervalTime = setInterval(this.refrescurrentTime, 900);
    },

    async refrescurrentTime(){
      // if(this.art.aspectRatio != this.aspectRatioDefault){
      //     this.art.aspectRatio = '16:9'
      // }
      if(this.buttonPasseGeneriqueAdd == false && this.actuelEpisode.debut_generique && this.actuelEpisode.fin_generique && this.art.currentTime >= this.actuelEpisode.debut_generique + 1 && this.art.currentTime < this.actuelEpisode.fin_generique){
        if(localStorage.getItem('langue') != 'en'){
          this.art.layers.add(
              {
                  name: 'passegenerique',
                  html: `<div style="height: 40px; color:white; align-items: center; display: flex; text-align: center; border-radius: 0.5rem; padding-left: 0.5rem; padding-right: 0.5rem; background-color: red">${this.$i18n.messages.fr.passergenerique}</div>`,
                  style: {
                      position: 'absolute',
                      bottom: '85px',
                      right: '5px',
                  },
                  click: ()=> {
                      this.art.seek = this.actuelEpisode.fin_generique
                  },
              },
          )
        }else{
          this.art.layers.add(
              {
                  name: 'passegenerique',
                  html: `<div style="height: 40px; color:white; align-items: center; display: flex; text-align: center; border-radius: 0.5rem; padding-left: 0.5rem; padding-right: 0.5rem; background-color: red">${this.$i18n.messages.en.passergenerique}</div>`,
                  style: {
                      position: 'absolute',
                      bottom: '85px',
                      right: '5px',
                  },
                  click: ()=> {
                      this.art.seek = this.actuelEpisode.fin_generique
                  },
              },
          )
        }
        this.buttonPasseGeneriqueAdd = true
      }else{
        if(this.buttonPasseGeneriqueAdd == true && this.art.layers['passegenerique'] && this.art.currentTime >= this.actuelEpisode.fin_generique){
            this.art.layers.remove('passegenerique')
            this.buttonPasseGeneriqueAdd = false
        }
      }
      if(this.art.currentTime >= this.art.duration && this.art.duration > 0){
        for( let i=0; i < this.allEpisodes.length; i++){
          if(this.actuelEpisode?.id == this.allEpisodes[i]?.id){
            if(this.allEpisodes[i + 1]){
              this.actuelEpisode = this.allEpisodes[i + 1]
              this.allEpisodesFind = this.allEpisodes
              this.activeSaison = this.actuelSaison
              //this.art.url = this.allEpisodes[i + 1]?.video
              this.axios.get(this.requestHttp + 'allvideos/' + this.allEpisodes[i + 1]?.id,{
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` 
              },
              }).then((data) => {
                this.qualites = []
                var defaultQuality = ''
                this.foundDefaultQuality = false
                for(let i = 0; i <= data.data.length - 1; i++){
                  if(data.data[i].qualite == localStorage.getItem('defaultqualite')){
                    this.art.url =  data.data[i].video
                    defaultQuality = data.data[i].qualite
                    this.qualites.push({
                      default: true,
                      html: data.data[i].qualite,
                        url: data.data[i].video
                      },
                    )
                    this.foundDefaultQuality = true 
                  }else{
                    this.qualites.push({
                      html: data.data[i].qualite,
                      url: data.data[i].video
                      },
                    ) 
                  }
                }
                if(this.foundDefaultQuality == false){
                  this.qualites = []
                  for(let i = 0; i <= data.data.length - 1; i++){
                    if(i == Math.trunc((data.data.length)/2)){
                      this.art.url =  data.data[i].video
                      defaultQuality = data.data[i].qualite
                      this.qualites.push({
                        default: true,
                        html: data.data[i].qualite,
                          url: data.data[i].video
                        },
                      )
                    }else{
                      if(i != Math.trunc((data.data.length)/2)){
                        this.qualites.push({
                          html: data.data[i].qualite,
                            url: data.data[i].video
                          },
                        )
                      }  
                    }
                  }
                }
                
                this.art.controls.update({
                      name: 'quality',
                      index: 10,
                      position: 'right',
                      html: defaultQuality,
                      selector:this.qualites,
                      onSelect:(item)=> {
                        this.art.switchQuality(item.url, item.html);
                        localStorage.setItem('defaultqualite', item.html)
                        //args.url = item.url  
                      },
                });
                //this.art.url =  data.data[0].video
                })
              .catch()
              if(( window.innerWidth >= 800 ) && ( window.innerHeight >= 600 )){
                this.listSaisonLecteur = []
                for(let i = 0; i <= this.allSaisons.length - 1 ; i++){
                  if(this.allSaisons[i]?.nom == this.actuelSaison.nom){
                    this.listSaisonLecteur.push({
                      default: true,
                      id: this.allSaisons[i]?.id,
                      nom:this.allSaisons[i]?.nom,
                      html: this.allSaisons[i]?.nom,
                      film_id: this.allSaisons[i]?.film_id,
                    })
                  }else{
                    this.listSaisonLecteur.push({
                      id: this.allSaisons[i]?.id,
                      nom:this.allSaisons[i]?.nom,
                      html: this.allSaisons[i]?.nom,
                      film_id: this.allSaisons[i]?.film_id,
                    })
                  }
                }

                this.listEpisodeLecteur = []
                for(let i = 0; i <= this.allEpisodes.length - 1; i++){
                  if(this.allEpisodes[i]?.nom == this.actuelEpisode.nom){
                    this.listEpisodeLecteur.push({
                      default: true,
                      id: this.allEpisodes[i]?.id,
                      nom:this.allEpisodes[i]?.nom,
                      html: this.allEpisodes[i]?.nom,
                      titre: this.allEpisodes[i]?.titre,
                      qualite: this.allEpisodes[i]?.qualite,
                      duree: this.allEpisodes[i]?.duree,
                      debut_generique: this.allEpisodes[i]?.debut_generique,
                      fin_generique: this.allEpisodes[i]?.fin_generique,
                      saison_id: this.allEpisodes[i]?.saison_id,
                    })
                  }else{
                    this.listEpisodeLecteur.push({
                      id: this.allEpisodes[i]?.id,
                      nom:this.allEpisodes[i]?.nom,
                      html: this.allEpisodes[i]?.nom,
                      titre: this.allEpisodes[i]?.titre,
                      qualite: this.allEpisodes[i]?.qualite,
                      duree: this.allEpisodes[i]?.duree,
                      debut_generique: this.allEpisodes[i]?.debut_generique,
                      fin_generique: this.allEpisodes[i]?.fin_generique,
                      saison_id: this.allEpisodes[i]?.saison_id,
                    })
                  }
                  
                }
                this.art.controls.update({
                      name: 'listepisodes',
                      index: 9,
                      position: 'right',
                      html: this.actuelEpisode.nom,
                      selector:this.listEpisodeLecteur,
                      onSelect:(item)=> {
                        this.newPlay(item)
                        //args.url = item.url  
                      },
                });
              }
              this.art.layers.titre.innerHTML = `<marquee scrollamount="3" >${this.actuelSaison.nom} ${this.allEpisodes[i + 1]?.nom} : ${this.allEpisodes[i + 1]?.titre}</marquee>`
              this.detailPlay[1].duration_play = 0
                //this.art.play()
              this.detailPlay[2] = this.allEpisodes[i + 1]
              localStorage.removeItem('detail')
              localStorage.setItem('detail', JSON.stringify(this.detailPlay))
              this.art.currentTime = 0
              break
            }else{
              for( let i=0; i < this.allSaisons.length; i++){
                if(this.actuelEpisode?.saison_id == this.allSaisons[i]?.id){
                  if(this.allSaisons[i + 1]){
                    //this.art.pause()
                    this.actuelSaison = this.allSaisons[i + 1]
                    this.activeSaison = this.allSaisons[i + 1]
                    if(( window.innerWidth >= 800 ) && ( window.innerHeight >= 600 )){
                        this.listSaisonLecteur = []
                        for(let i = 0; i <= this.allSaisons.length - 1 ; i++){
                          if(this.allSaisons[i]?.nom == this.activeSaison.nom){
                            this.listSaisonLecteur.push({
                              default: true,
                              id: this.allSaisons[i]?.id,
                              nom:this.allSaisons[i]?.nom,
                              html: this.allSaisons[i]?.nom,
                              film_id: this.allSaisons[i]?.film_id,
                            })
                          }else{
                            this.listSaisonLecteur.push({
                              id: this.allSaisons[i]?.id,
                              nom:this.allSaisons[i]?.nom,
                              html: this.allSaisons[i]?.nom,
                              film_id: this.allSaisons[i]?.film_id,
                            })
                          }
                          
                        }
                        this.art.controls.update({
                              name: 'listsaison',
                              index: 8,
                              position: 'right',
                              html: `<div style="margin: 10px">${this.activeSaison.nom}<div>`,
                              selector:this.listSaisonLecteur,
                              onSelect:(item)=> {
                                this.findEpisode(item)
                                //args.url = item.url  
                              },
                      });
                    }
                    await this.axios.get(this.requestHttp + 'allepisodes/' + this.allSaisons[i + 1].id, {
                    headers: {
                      'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                    },
                    }).then(({data}) => {
                      this.loadEpisode = false
                      if(data[0].length>0){
                        this.actuelEpisode = data[0][0]
                        this.allEpisodesFind = data[0]
                        //this.art.url = data[0][0].video
                        this.axios.get(this.requestHttp + 'allvideos/' + data[0][0].id,{
                          headers: {
                              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` 
                          },
                          }).then((data) => {
                            this.qualites = []
                            var defaultQuality = ''
                            this.foundDefaultQuality = false
                            for(let i = 0; i <= data.data.length - 1; i++){
                              if(data.data[i].qualite == localStorage.getItem('defaultqualite')){
                                this.art.url =  data.data[i].video
                                defaultQuality = data.data[i].qualite
                                this.qualites.push({
                                  default: true,
                                  html: data.data[i].qualite,
                                    url: data.data[i].video
                                  },
                                )
                                this.foundDefaultQuality = true 
                              }else{
                                this.qualites.push({
                                  html: data.data[i].qualite,
                                  url: data.data[i].video
                                  },
                                ) 
                              }
                            }
                            if(this.foundDefaultQuality == false){
                              this.qualites = []
                              for(let i = 0; i <= data.data.length - 1; i++){
                                if(i == Math.trunc((data.data.length)/2)){
                                  this.art.url =  data.data[i].video
                                  defaultQuality = data.data[i].qualite
                                  this.qualites.push({
                                    default: true,
                                    html: data.data[i].qualite,
                                      url: data.data[i].video
                                    },
                                  )
                                }else{
                                  if(i != Math.trunc((data.data.length)/2)){
                                    this.qualites.push({
                                      html: data.data[i].qualite,
                                        url: data.data[i].video
                                      },
                                    )
                                  }  
                                }
                              }
                            }
                            
                            this.art.controls.update({
                                  name: 'quality',
                                  index: 10,
                                  position: 'right',
                                  html: defaultQuality,
                                  selector:this.qualites,
                                  onSelect:(item)=> {
                                    this.art.switchQuality(item.url, item.html);
                                    localStorage.setItem('defaultqualite', item.html)
                                    //args.url = item.url  
                                  },
                            });
                        })
                      .catch()
                        if(( window.innerWidth >= 800 ) && ( window.innerHeight >= 600 )){
                          this.listEpisodeLecteur = []
                          for(let i = 0; i <= data[0].length - 1; i++){
                            if(data[0][i]?.nom == this.actuelEpisode.nom){
                              this.listEpisodeLecteur.push({
                                default: true,
                                id: this.allEpisodes[i]?.id,
                                nom:this.allEpisodes[i]?.nom,
                                html: this.allEpisodes[i]?.nom,
                                titre: this.allEpisodes[i]?.titre,
                                qualite: this.allEpisodes[i]?.qualite,
                                duree: this.allEpisodes[i]?.duree,
                                debut_generique: this.allEpisodes[i]?.debut_generique,
                                fin_generique: this.allEpisodes[i]?.fin_generique,
                                saison_id: this.allEpisodes[i]?.saison_id,
                              })
                            }else{
                              this.listEpisodeLecteur.push({
                                id: this.allEpisodes[i]?.id,
                                nom:this.allEpisodes[i]?.nom,
                                html: this.allEpisodes[i]?.nom,
                                titre: this.allEpisodes[i]?.titre,
                                qualite: this.allEpisodes[i]?.qualite,
                                duree: this.allEpisodes[i]?.duree,
                                debut_generique: this.allEpisodes[i]?.debut_generique,
                                fin_generique: this.allEpisodes[i]?.fin_generique,
                                saison_id: this.allEpisodes[i]?.saison_id,
                              })
                            }
                            
                          }
                          this.art.controls.update({
                                name: 'listepisodes',
                                index: 9,
                                position: 'right',
                                html: this.actuelEpisode.nom,
                                selector:this.listEpisodeLecteur,
                                onSelect:(item)=> {
                                  this.newPlay(item)
                                  //args.url = item.url  
                                },
                          });
                        }
                        this.art.layers.titre.innerHTML = `<marquee scrollamount="3" >${this.actuelSaison.nom} ${data[0][0].nom} : ${data[0][0].titre}</marquee>`
                        //this.art.play() 
                        this.allEpisodes = data[0]
                        this.detailPlay[5] = data[0]
                        this.detailPlay[2] = data[0][0]
                        this.detailPlay[4] = this.allSaisons[i + 1]
                        localStorage.removeItem('detail')
                        localStorage.setItem('detail', JSON.stringify(this.detailPlay))
                        this.art.currentTime = 0
                      }
                      clearInterval(this.intervalTime)
                    })
                    .catch()
                  }else{
                    console.log('saison non trouve')
                  }
                }
              }
            }       
          }   
        }
      }
    },

    // leaving(){
    //   clearInterval(this.intervalTime)
    //   this.form.duration_play = this.art.currentTime
    //   this.form.duration_full = this.art.duration
    //   this.form.episode_id = this.actuelEpisode.id
    //   this.form.nameepisode= this.actuelEpisode.nom
    //   this.form.saison_id = this.actuelSaison.id
    //   this.form.namesaison= this.actuelSaison.nom
      
    //   if(this.art.currentTime < (this.art.duration * 90)/100 || this.art.currentTime > 1){
    //     this.axios.post(this.requestHttp + 'saverecent',this.form,{
    //       headers: {
    //         'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
    //       },
    //       }).then(() => {
    //       setTimeout(() => {
    //           this.setDataRecent
    //       }, 10000);
    //       this.art.destroy()
    //     })
    //     .catch()
    //   }
    // }
  },

  computed:{
    getDataLecteurVideo(){
        return this.$store.state.dataLecteurVideo
    },
    setDataRecent(){
        return this.$store.dispatch('requestRecent')
    },
    requestHttp(){
      return this.$store.state.api
    },
    verifyCurrentTime(){
      return this.time
    },
  }
};
</script>
<style >
/*
.art-controls .art-controls-right .art-control-listepisodes .art-selector-list{
  display: block !important;
}

.art-controls .art-controls-right .art-control-listsaison .art-selector-list{
  display: block !important;
}
*/
.scrollbar-hide::-webkit-scrollbar {
    display: none;
    }

    /* For IE, Edge and Firefox */
    .scrollbar-hide {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
</style>