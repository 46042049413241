<template>
  <div class="w-full bg-[#181A20]">
      <div class="body h-[100vh] bg-[#181A20]">
        <swiper v-if="publicites.length > 0" style="--swiper-pagination-bullet-inactive-color: #FF0000; --swiper-pagination-color:white" :pagination="{clickable: true,}" :loop="true" :autoplay="true" :modules="modules" class="mySwiper h-[100vh] w-full bg-[#181A20]">
            <swiper-slide class="text-white" v-for="publicite in publicites" :key="publicite.id">
                <div class=" w-full h-[100%]">
                    <div class="h-full w-full xl:flex landscape:flex relative">
                        <div class=" xl:w-[45%] landscape:w-[45%]  w-full h-full relative">
                          <div class="w-full h-full bg-gradient-to-t from-[#181A20]  xl:bg-gradient-to-l landscape:bg-gradient-to-l  xl:from-[#181A20]  to-transparent  bottom-0 absolute">

                          </div>
                          <div class=" w-full h-full">
                              <img :src="publicite[0].pub" class="  object-center object-cover w-full h-full" :alt="publicite[0].titre" srcset="">
                          </div>
                        </div>
                        <div class=" xl:w-[60%] landscape:w-[60%] landscape:relative w-full xl:relative absolute top-0 h-full xl:bg-[#181A20] rounded-xl">
                          <div class="w-full h-[85%] relative  flex items-center rounded-xl">
                              <div class="space-y-12 absolute bottom-2 landscape:relative xl:relative mx-auto">
                                <div class="w-full px-4 space-y-6">
                                    <div class="w-full flex">
                                    <p class=" mx-auto text-white text-md">{{ $t('disponible') }}</p>
                                    </div>
                                    <div class="w-full flex">
                                      <!--telephone-->
                                      <p v-if="publicite[0].titre.length > 27" class=" mx-auto md:hidden text-white font-bold text-2xl text-center">{{ publicite[0].titre.substring(0,23) + '...' }}</p>
                                      <p v-if="publicite[0].titre.length <= 27" class=" mx-auto md:hidden text-white font-bold text-2xl text-center">{{ publicite[0].titre}}</p>
                                        <!--tablette-->
                                      <p v-if="publicite[0].titre.length > 36" class=" mx-auto hidden md:block xl:hidden text-white font-bold text-2xl text-center">{{ publicite[0].titre.substring(0,36) + '...' }}</p>
                                      <p v-if="publicite[0].titre.length <= 36" class=" mx-auto hidden md:block xl:hidden text-white font-bold text-2xl text-center">{{ publicite[0].titre}}</p>
                                      <!--pc-->
                                      <p v-if="publicite[0].titre.length > 36" class=" mx-auto hidden xl:block text-white font-bold text-2xl text-center">{{ publicite[0].titre.substring(0,36) + '...' }}</p>
                                      <p v-if="publicite[0].titre.length <= 36" class=" mx-auto hidden xl:block text-white font-bold text-2xl text-center">{{ publicite[0].titre}}</p>
                                    </div>
                                    <div class="w-full flex">
                                      <!--telephone-->
                                      <p v-if="publicite[0].description.length > 160" class=" md:hidden mx-auto text-white text-sm text-center">{{ publicite[0].description.substring(0,160) + '...' }}</p>
                                      <p v-if="publicite[0].description.length <= 160" class=" md:hidden mx-auto text-white text-sm text-center">{{ publicite[0].description }}</p>
                                      <!--tablette-->
                                      <p v-if="publicite[0].description.length > 250" class=" hidden md:block xl:hidden mx-auto text-white text-sm text-center">{{ publicite[0].description.substring(0,250) + '...' }}</p>
                                      <p v-if="publicite[0].description.length <= 250" class=" hidden md:block xl:hidden mx-auto text-white text-sm text-center">{{ publicite[0].description }}</p>
                                      <!--ordi-->
                                      <p v-if="publicite[0].description.length > 350" class=" hidden xl:block mx-auto text-white text-sm text-center">{{ publicite[0].description.substring(0,350) + '...' }}</p>
                                      <p v-if="publicite[0].description.length <= 350" class=" hidden xl:block mx-auto text-white text-sm text-center">{{ publicite[0].description }}</p>
                                    </div>
                                    <div class=" w-full flex">
                                    <div class="flex space-x-3 items-center mx-auto">
                                        <p class="text-sm font-semibold text-white">{{ publicite[1][0]?.nom }}</p>
                                        <img v-if="publicite[1][1]" class="w-4 h-4" src="/icons/sort_right_filled_100px.png" alt="cinéma africain" srcset="">
                                        <p class="text-sm font-semibold text-white">{{ publicite[1][1]?.nom }}</p>
                                        <img v-if="publicite[1][2]" class="w-4 h-4" src="/icons/sort_right_filled_100px.png" alt="cinéma africain" srcset="">
                                        <p class="text-sm font-semibold text-white">{{ publicite[1][2]?.nom }}</p>
                                    </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                          <div class="w-full h-[15%] relative">
                              <div class=" w-full px-4 space-y-8 absolute bottom-12">
                              <div class="w-full flex">
                                  <div class=" w-12 h-12 rounded-full bg-white flex items-center">
                                    <p class="text-xs mx-auto text-gray-500 font-semibold">+{{ publicite[0].age }}</p>
                                  </div>
                                  <button @click="openDetailPage(publicite)" class=" w-12 h-12 rounded-full bg-[#FF0000] absolute right-3 flex items-center">
                                    <img class="mx-auto h-4" src="/icons/visible_60px.png" alt="cinéma africain" srcset="">
                                  </button>
                              </div>
                              <!--
                              <div class=" w-full flex">
                                  <div class="mx-auto flex space-x-2">
                                  <div class="w-2 h-2 rounded-full bg-[#FF0000]"></div>
                                  <div class="w-2 h-2 rounded-full bg-[#FF0000]"></div>
                                  <div class="w-2 h-2 rounded-full bg-white"></div>
                                  <div class="w-2 h-2 rounded-full bg-[#FF0000]"></div>
                                  <div class="w-2 h-2 rounded-full bg-[#FF0000]"></div>
                                  </div>
                              </div>-->
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
      </div>
      <div class="w-full space-y-4">
        <recent-component></recent-component>
        <film-component></film-component>
        <serie-component></serie-component>
        <web-serie-component></web-serie-component>
        <novelas-component></novelas-component>
      </div>
  </div>
</template>
<script>

import recentComponent from '@/components/RecentComponent.vue';
import filmComponent from '@/components/FilmComponent.vue';
import serieComponent from '@/components/SerieComponent.vue';
import webSerieComponent from '@/components/WebSerieComponent.vue';
import novelasComponent from '@/components/SerieNovelasComponent.vue';


// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
    recentComponent,
    filmComponent,
    serieComponent,
    webSerieComponent,
    novelasComponent,
    
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
  
  data(){
    return{
        data:{},
        publicites:{},
        films:{},
        series:{},
        novelas:{},
        wbseries:{},
        avatar:{},
        recents:{}
    }
  },
 watch: {
    getDataAccueil: {
      handler(newValue) {
        this.data = newValue
        this.publicites= this.data[0]
        this.films= this.data[1]
        this.series= this.data[2]
        this.webseries= this.data[3]
        this.novelas= this.data[4]
        this.avatar= this.data[5]
        this.profil = newValue[0]
      },
      deep: true
    },
    getDataRecent: {
      handler(newValue) {
        this.recents= newValue
      },
      deep: true
    }
  },
  // created() {
    
  //   window.emitter.on("accueilEvent", VarGetDataAccueil => {
  //       this.data = this.getDataAccueil
  //       this.publicites= this.data[0]
  //       this.films= this.data[1]
  //       this.series= this.data[2]
  //       this.webseries= this.data[3]
  //       this.novelas= this.data[4]
  //       this.avatar= this.data[5]
  //       this.recents= this.data[6]
  //   })
  // },
  mounted(){
    if(this.getDataAccueil.length > 0){
        this.data = this.getDataAccueil
        this.publicites= this.data[0]
        this.films= this.data[1]
        this.series= this.data[2]
        this.webseries= this.data[3]
        this.novelas= this.data[4]
        this.avatar= this.data[5]
        if(this.getDataRecent.length > 0){
          this.recents= this.getDataRecent
        }else{
          this.recents= this.data[6]
        }   
    }else{
        this.axios.get(this.requestHttp + 'accueil/' + localStorage.getItem('langue'), {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            },
          }).then(({data}) => {
            this.data = data
            this.publicites= data[0]
            this.films= data[1]
            this.series= data[2]
            this.webseries= data[3]
            this.novelas= data[4]
            this.avatar= data[5]
            this.recents= data[6]   
            this.setDataAccueil   
            this.setDataFavoris 
            this.setDataSearch        
         })
      .catch() 
       }
    },
  methods:{
    openDetailPage(data){
            window.emitter.emit("EventDetailPage", data)
        },
  },
  computed:{
    requestHttp(){
      return this.$store.state.api
    },
    setDataAccueil(){
        return this.$store.dispatch('requestAccueil', this.data)
    },
    getDataAccueil(){
        return this.$store.state.dataAccueil
    },
    setDataFavoris(){
        return this.$store.dispatch('requestFavoris')
    },
    setDataSearch(){
        return this.$store.dispatch('requestSearch')
    },
    getDataRecent(){
        return this.$store.state.dataRecents
    },
  }
};
</script>

<style scoped>

.body {
    position: relative;
  }
  
  .body {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: rgb(153, 153, 153);
    margin: 0;
    padding: 0;
  }
.swiper {
  width: 100%;
  height: 100%;
}


.mySwiper{
    width: 100%;
    height: 100vh;
}

@media (min-width: 1280px) {
    .swiper >>> .swiper-pagination, .swiper-pagination-bullets, .swiper-pagination-horizontal{
      width: 58% !important;
      left: inherit !important;
      right: 0;
    }
}

@media (orientation: landscape) {
  .swiper >>> .swiper-pagination, .swiper-pagination-bullets, .swiper-pagination-horizontal{
    width: 58% !important;
    left: inherit !important;
    right: 0;
  }
}

.swiper-horizontal >>> .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
      opacity: 100% !important;
}


.swiper-pagination-bullet{
  opacity: 1% !important;
}

</style>
